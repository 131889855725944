<template>
  <div style="position: relative" @mouseenter="isExpend=true" @mouseleave="isExpend=false">
    <div class="optionitem" >
      <div class="subtitle">{{ preTitle }}：</div>
      <div  class="scon flexs">
        <div  :ref="'parentcon'+preIndex" class="pitem">
          <div class="flexs citem curpoint" :ref="'childcon'+preIndex">
            <div
              v-if="showAll"
              @click="selectOption = ''"
              :class="{
              suboptioned: selectOption == '',
              suboption: selectOption != '',
            }"
            >
              全部
            </div>
            <div
              @click="selectOption = item[attr]"
              :class="{
              suboptioned: selectOption == item[attr],
              suboption: selectOption != item[attr],
            }"
              v-for="item in list"
              :key="'post' + item[attr]"
            >
              {{ item[showAttrName] }}
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="optionitemAllzw" style="z-index: -1">
      <div class="subtitle">{{ preTitle }}：</div>
      <div  class="scon flexs" :ref="'tmpzw'+preIndex">
        <div class="flexs citem curpoint" ref="childcon">
          <div
            v-if="showAll"
            @click="selectOption = ''"
            :class="{
              suboptioned: selectOption == '',
              suboption: selectOption != '',
            }"
          >
            全部
          </div>
          <div
            @click="selectOption = item[attr]"
            :class="{
              suboptioned: selectOption == item[attr],
              suboption: selectOption != item[attr],
            }"
            v-for="item in list"
            :key="'post' + item[attr]"
          >
            {{ item[showAttrName] }}
          </div>
        </div>
      </div>
    </div>
    <div class="optionitemAll" v-if="isExpend">
      <div class="subtitle">{{ preTitle }}：</div>
      <div  class="scon flexs animate__animated pitems" :style="{height:targetHeight}">
        <div  class="pitems">
          <div class="flexs citem curpoint" >
            <div
              v-if="showAll"
              @click="selectOption = ''"
              :class="{
              suboptioned: selectOption == '',
              suboption: selectOption != '',
            }"
            >
              全部
            </div>
            <div
              @click="selectOption = item[attr]"
              :class="{
              suboptioned: selectOption == item[attr],
              suboption: selectOption != item[attr],
            }"
              v-for="item in list"
              :key="'post' + item[attr]"
            >
              {{ item[showAttrName] }}
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "toplabels",
  props:{
    preIndex:{
      type:[String,Number],
      value:''
    },
    defaultOption:{
      type:[String,Number],
      value:[]
    },
    showAll:{
      type:Boolean,
      value:true
    },
    attr:{
      type:String,
      value:'id'
    },
    showAttrName:{
      type:String,
      value:'name'
    },
    list:{
      type:Array,
      value:[]
    },
    preTitle:{
      type:String,
      value:''
    }
  },
  data(){
    return {
      selectOption:'',
      showdownup:false,
      isExpend:false,
      targetHeight:'0rem'
    }
  },
  watch:{
    selectOption(val){
      this.$emit('selectChange',val)
    }
  },
  mounted() {
    this.selectOption=this.defaultOption;
    this.$nextTick(()=>{
      let theight = this.$refs['childcon'+this.preIndex].clientHeight - this.$refs['parentcon'+this.preIndex].clientHeight;
      this.showdownup=theight>0;
      if(theight < 0){
        this.isExpend=true
      }
      this.targetHeight=this.$refs['tmpzw'+this.preIndex].clientHeight

    })
  }
};
</script>

<style scoped>
.optionitem {
  margin-top: 0.15rem;
  margin-left: 0.3rem;
  display: flex;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0);
  padding:0.15rem 0.15rem 0 0.15rem;
}
.optionitemAllzw{
  display: flex;
  background: #ffffff;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  padding:0.15rem 0.15rem 0 0.15rem;
  position: absolute;
  left:0.3rem;
  top:0;
  z-index: -1;
  width: 11rem;
  opacity: 0;
}
.optionitemAll{
  display: flex;
  background: #ffffff;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  padding:0.15rem 0.15rem 0 0.15rem;
  position: absolute;
  left:0.3rem;
  top:0;
  z-index: 10;
  width: 11rem;
}
.subtitle {
  padding: 0.03rem 0.05rem;
  font-size: 0.16rem;
  margin-right: 0.1rem;
  font-weight: bold;
  width: 0.9rem;
  text-align: right;
}
.scon{
  width: calc(100% - 1rem);
}
.pitem{
  width: 100%;
  max-height:0.9rem;
  overflow: hidden;
}
.pitems{
  width: 100%;
}
@-webkit-keyframes pitems {
  0% {
    width: 100%;
    max-height:0.9rem;
    overflow: hidden;
  }
  to {
    width: 100%;
  }
}
@keyframes pitems {
  0% {
    width: 100%;
    max-height:0.9rem;
    overflow: hidden;
  }
  to {
    width: 100%;
  }
}
.animate__pitems {
  -webkit-animation-name: pitems;
  animation-name: pitems;
  animation-duration: 1s;
}
.citem {
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
}
.suboption {
  padding: 0.05rem 0.1rem;
  border-radius: 0.05rem;
  margin-right: 0.05rem;
  margin-bottom: 0.15rem;
  font-size: 0.17rem;
  color: #666;
}
.suboptioned {
  background: rgba(19, 134, 240, 0.2);
  color: rgba(19, 134, 240, 1);
  padding: 0.05rem 0.1rem;
  border-radius: 0.05rem;
  margin-bottom: 0.15rem;
  margin-right: 0.05rem;
  font-size: 0.17rem;
}
.upicon{
  width: 0.2rem;
  position: relative;
}
.upicon .iconfont{
  position: absolute;
  bottom: 0.5rem;
  right: 0;
}
@media screen and (max-width: 1500px) {
  .optionitemAll {
    width: 7rem !important;
  }
}
</style>
