<template>
  <div class="right">
    <div class="mtitle">账号设置</div>
    <div class="sitem" style="margin-top:25px">
      <div class="flexs">
        <div class="slabel">手机号：</div>
        <div class="svalue">{{ userInfo.phone }}</div>
      </div>
    </div>
    <div class="sitem">
      <div class="flexs">
        <div class="slabel">微信号：</div>
        <div class="svalue">{{ userInfo.wxOpenId }}</div>
      </div>
    </div>
    <div class="sitem">
      <div class="flexs">
        <div class="slabel">邮箱：</div>
        <div class="svalue">{{ userInfo.email }}</div>
        <div class="colorblue fontweight curpoint" @click="email='';emailVisible=true">修改邮箱</div>
      </div>
    </div>
    <div class="sitem">
      <div class="flexs">
        <div class="slabel">密码：</div>
        <div class="colorblue fontweight curpoint" @click="password='';dialogVisible=true">修改密码</div>
      </div>
    </div>
    <el-dialog
      title="修改密码"
      :append-to-body="true"
      :visible.sync="dialogVisible"
      width="30%"
      v-loading="loading"
      center>
      <el-input placeholder="请输入密码" v-model="password" show-password></el-input>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="resetPass">确 定</el-button>
  </span>
    </el-dialog>
    <el-dialog
      title="修改邮箱"
      :append-to-body="true"
      :visible.sync="emailVisible"
      width="30%"
      v-loading="loading"
      center>
      <el-input placeholder="请输入邮箱" v-model="email" ></el-input>
      <span slot="footer" class="dialog-footer">
    <el-button @click="emailVisible = false">取 消</el-button>
    <el-button type="primary" @click="resetEmail">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>
<script>
import { postRequest } from "@/utils/common.js";
import { getRequest } from "@/utils/common.js";
import { removeToken } from "@/utils/auth";


export default {
  name: "setAccount",
  data(){
    return {
      dialogVisible:false,
      emailVisible:false,
      password:'',
      loading:false,
      email:'',
      userInfo:{},
      sexImg:''
    }
  },
  mounted() {
    this.getUserInfo()
  },
  components:{},
  methods:{
    async getUserInfo() {
      this.$scrollTop()
      let { data } = await getRequest("abcode/user/queryUserInfo", {});
      this.userInfo = data || {};
      this.sexImg=require(`../../assets/${ this.userInfo.sex==1?'icon_nv':'icon_nan'}.png`)
    },
    async resetPass(){
      if(!this.password){
        this.$message.warning('请输入密码')
        return
      }
      this.loading=true
      let {code,message} = await postRequest('abcode/user/updatePassword/'+this.password,{})
      this.loading=false
      if(code==20000){
        removeToken();
        this.$store.commit('setRefreshUserInfo',true)
        this.$router.push('/');
        this.$message.success(message || '操作失败')
        this.dialogVisible=false

      }else{
        this.$message.error(message || '操作失败')
      }
    },
    async resetEmail(){
      if(!this.email){
        this.$message.warning('请输入邮箱')
        return
      }
      this.loading=true
      let {code,message} = await postRequest('abcode/user/updateEmail/'+this.email,{})
      this.loading=false
      if(code==20000){
        this.$message.success(message || '操作失败')
        this.emailVisible=false
        this.getUserInfo()
      }else{
        this.$message.error(message || '操作失败')
      }
    }
  }
};
</script>
<style scoped>

.slabel{
  color: #808080;
  width: 1rem;
  text-align: right;
  margin-right: 0.1rem;
}
.svalue{
  font-weight: bold;
  margin-right: 0.2rem;
}

.avatar-con img{
  width: 0.88rem;
  height: 0.88rem;
}


.right{
  width: 8.9rem;
  height: 4.56rem;
  background: #FFFFFF;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  padding: 0.2rem 0.2rem 0 0.2rem;
  font-size: 0.18rem;
}
.mtitle{
  font-size: 0.16rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0.1rem;
}
.sitem{
  width: 100%;
  padding: 0.15rem 0;
}


</style>
