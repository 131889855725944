import { render, staticRenderFns } from "./brushBo.vue?vue&type=template&id=057e2004&scoped=true&"
import script from "./brushBo.vue?vue&type=script&lang=js&"
export * from "./brushBo.vue?vue&type=script&lang=js&"
import style0 from "./brushBo.vue?vue&type=style&index=0&id=057e2004&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "057e2004",
  null
  
)

export default component.exports