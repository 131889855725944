<template>
  <div class="right">
    <div class="mtitle">我的优惠券</div>
    <div class="nodata" v-if="list.length == 0">暂无数据</div>
    <div class="sitem flexs" v-for="item in list" :key="'coupon'+item.couponId">
      <img class="imgCoupon" src="../../assets/coupon.png"/>
      <div class="leftimg curpoint" >
        <div class="sprotitle"  >{{ item.couponTitle }}</div>
      </div>
      <div class="cinfo">
        <div class="bmnum flexs">
          <div>优惠券码：</div>
          <div> {{ item.couponCode }}</div>
          <img class="curpoint imgCopy copy-btn"
               data-clipboard-action="copy"
               :data-clipboard-text="item.couponCode"
               @click="clickCopy"
               src="../../assets/copy.png"/>
        </div>
        <div class="bmnum">
          适用课程：{{ item.courseName }}
        </div>
        <div class="bmnum">
          有效期限：{{ item.endTime }}
        </div>
        <div class="bmnum flexs">
          <div>使用状态：{{ item.status == 1?'已使用': judgeStatus(item)?'未使用':'已过期' }}</div>
          <div class="ybm flexcenter curpoint" @click="goDetail(item)" v-if="item.status == 0 && judgeStatus(item)">去使用</div>
        </div>
      </div>
    </div>
    <div class="flexcenter" style="width: 100%;margin:0.35rem 0">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="(e)=>{pageNum=e;getList()}"
        :page-size="pageSize"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getRequest } from "@/utils/common.js";
import Clipboard from 'clipboard'

export default {
  name: "myCoupon",
  data(){
    return {
      pageNum:1,
      pageSize:5,
      list:[],
      total:0,
    }
  },
  mounted() {
    this.getList()
  },
  methods:{
    judgeStatus(obj){
      return (new Date(obj.endTime).getTime()-new Date().getTime() > 0)
    },
    goDetail(obj){
      let url = '/courseDetail?courseId='+obj.courseId
      if(obj.courseType == 1 || obj.courseType==2){
        url = '/combatDetail?courseId='+obj.courseId+'&type='+obj.courseType
      }
     this.$router.push(url)
    },
    async getList(){
      this.$scrollTop()
      let {data} = await getRequest(`abcode/user/queryUserCouponList/${this.pageNum}/${this.pageSize}`,{})
      this.list=(data && data.items?data.items.records:[]).map(item=>{
        return {
          ...item
        }
      });
      this.total = data && data.items?data.items.total :0
    },
    clickCopy() {
      let _this = this;
      var clipboard = new Clipboard('.copy-btn')
      clipboard.on('success', function() {
        _this.$message.success("复制成功！快去使用吧~");
        // 释放内存
        clipboard.destroy()
      })
      clipboard.on('error', function() {
        _this.$message.info("复制失败！当前浏览器可能不支持，请手动复制~");
        // 释放内存
        clipboard.destroy()
      })
    }
  }
};
</script>
<style scoped>
.nodata{
  font-size: 0.18rem;
  width: 100%;
  text-align: center;
  margin-top:0.6rem
}

.avatar-con img{
  width: 0.88rem;
  height: 0.88rem;
}
.cinfo{
  z-index: 2;
  width: 3rem;
  height: 1.5rem;
  margin-left: 0.1rem;
}
.bmnum{
  color: #999;
  margin-top: 0.12rem;
  text-align: left;
  width:100%;
  color: #666;
}

.ybm{
  width: 0.8rem;
  height:0.32rem;
  border-radius: 0.15rem;
  background-image: linear-gradient(#23abee, #51cce8);
  margin-left: 0.4rem;
  color: #fff;
  font-size: 0.13rem;
}


.right{
  width: 8.9rem;
  min-height: 4.56rem;
  background: #FFFFFF;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  padding: 0.2rem 0.2rem 0 0.2rem;
}
.mtitle{
  font-size: 0.16rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0.1rem;
}
.sitem{
  width: 100%;
  padding:0.25rem 0;
  border-bottom: 0.01rem solid #dcdcdc;
}
.leftimg{
  width: 2.2rem;
  height: 1.58rem;
  position: relative;
  align-items: center;
  z-index: 2;
}
.leftimg img{
  width: 2.25rem;
  height: 1.58rem;
  border-radius: 0.16rem;
}
.sprotitle {
  width: 2rem;
  font-size: 0.18rem;
  color: #ffffff;
  line-height: 0.31rem;
  text-align: center;
  font-weight: bold;
  position: absolute;
  left: 50%;
  top:50%;
  transform: translate(-50%,-50%);
}
.imgCoupon{
  height: 1.58rem;
  position: absolute;
  z-index: 1;
}
.imgCopy{
  width: 0.2rem;
  margin-left: 0.1rem
}
</style>
