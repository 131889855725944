<template>
<div class="container">
  <div class="headezw"></div>
  <div class="ucon flexbetween">
    <img :src="info.tutorPhoto" class="avtarimg" />
    <div class="perinfo">
      <div class="flexs">
        <div class="ls flexs">{{ info.tutorName }}</div>
<!--        <img class="mimg" src="../assets/detail/jiangzhang.png" />-->
<!--        <img class="mimg" src="../assets/detail/guanzhu.png" />-->
<!--        <span class="lslevel">{{info.tutorTitle}}{{info.tutorLevel}}</span>-->
        <span class="lslevel" v-for="(sitem,sindex) in info.bestAreas" :key="'area'+sindex">{{ sitem }}</span>
      </div>
      <div class="center-count flexbetween">
        <div class="count-item">
          <div class="cnum">{{ info.chatCount || 0}}</div>
          <div class="ctip">咨询人数</div>
        </div>
        <div class="count-item">
          <div class="cnum">{{ info.chatCount || 0}}</div>
          <div class="ctip">回答人数</div>
        </div>
        <div class="count-item">
          <div class="cnum">{{ info.chatCount?Math.floor(info.chatCount*0.8):0}}</div>
          <div class="ctip">点赞人数</div>
        </div>
      </div>
      <div class="ljzx flexcenter" @click="goConsult">立即咨询</div>
    </div>
  </div>

  <div class="center1" v-if="info.tutorDesc">
    <div class="cnter1-ttile flexcenter">咨询介绍</div>
    <div class="intro">
      <div v-html="info.tutorDesc"></div>
    </div>
  </div>
<!--  <div class="center1" v-if="info.bestArea">-->
<!--    <div class="cnter1-ttile flexcenter">擅长领域</div>-->
<!--    <div class="intro">-->
<!--      <div v-html="info.bestArea"></div>-->
<!--    </div>-->
<!--  </div>-->
  <el-dialog
    title="扫描二维码咨询"
    :append-to-body="true"
    :visible.sync="dialogVisible"
    width="30%"
    center>
    <div class="dialog-con flexcenter">
      <img :src="info.weChatGroupImage" />
    </div>
    <span slot="footer" class="dialog-footer">

    <el-button type="primary" @click="dialogVisible = false">关  闭</el-button>
  </span>
  </el-dialog>
</div>
</template>

<script>
import { getRequest,postRequest } from "@/utils/common.js";

export default {
  name: "teacherDetail",
  data(){
    return {
      tutorId:'',
      info:{},
      dialogVisible:false
    }
  },
  mounted() {
    this.$scrollTop()
    this.tutorId=this.$route.query.tutorId || ''
    if(this.tutorId){
      this.getDetail()
    }
  },
  methods:{
    async getDetail(){
      let {data} = await getRequest('abcode/unneed/tutor/queryTutorInfo/'+this.tutorId,{tutorId:this.tutorId})
      let info = data && data.items?data.items:{}
      this.info=info;
      this.info.tutorPhoto= info.tutorPhoto?info.tutorPhoto.replace('/opt/nginx/ymadmin/',this.imgurl):'';
      this.info.weChatGroupImage=info.weChatGroupImage?info.weChatGroupImage.replace('/opt/nginx/ymadmin/',this.imgurl):'';
      this.info.bestAreas=info.bestArea?info.bestArea.split(','):[];
    },
    async goConsult(){
     this.dialogVisible=true
      let {data} = await postRequest('abcode/tutor/unneed/addTutorChatCount/'+this.tutorId,{})
      console.log(data)
    }
  }
};
</script>

<style scoped>
.container{
  width: 100%;
  padding-bottom: 0.9rem;
}
.count-item{
  font-size: 0.14rem;
  text-align: center;
}
.center-count{
  width: 2.3rem;
  margin-top:0.28rem;
}
.cnum{
  font-size: 0.24rem;
  font-weight: bold;
  color: #000;
  text-align: center;
}
.ctip{
  font-size: 0.14rem;
  color: #808080;
  margin-top:0.14rem;
  text-align: center;
}
.ljzx{
  width: 0.93rem;
  height: 0.3rem;
  background-image: linear-gradient(#1386F0, #21A9EF);
  border-radius: 0.15rem;
  color: #FFFFFF;
  font-size: 0.16rem;
  cursor: pointer;
  margin-top:0.28rem;
}
.perinfo{
  padding-left: 0.32rem;
  width: 8.58rem;
  border-left: 0.01rem solid #dcdcdc;
  color: #808080;
}

.mimg{
  width: 0.17rem;
  height: 0.21rem;
  margin-left: 0.13rem;
}
.ls{
  color: #0A0A0A;
  font-size: 0.2rem;
}
.lslevel{
  font-size: 0.14rem;
  background: #f5f5f5;
  padding: 0.02rem 0.05rem;
  border-radius: 0.03rem;
  color: #999;
  margin-left: 0.1rem;
}
.ucon{
  width: 10.9rem;
  background: #FFFFFF;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  margin: auto;
  margin-top:0.3rem;
  padding: 0.3rem;
}
.avtarimg{
  width: 1.7rem;
  height: 1.7rem;
  border-radius: 10%;
}
.center1{
  width: 12rem;
  margin:auto;
  margin-top:0.68rem
}
.cnter1-ttile{
  width: 2.18rem;
  height: 0.37rem;
  background: url("../assets/detail/pic_jieshao.png") no-repeat center;
  background-size: 100% 100%;
  color: #1386F0;
  font-size: 0.18rem;
  margin: auto;
}
.intro{
  width: 12rem;
  margin: auto;
  margin-top:0.35rem;
  font-size: 0.14rem;
  color: #4d4d4d;
  text-indent: 0.5rem;
  line-height: 0.19rem;
}
.dialog-con img{
  width: 3rem;
  border-radius: 0.16rem;
}
.el-dialog__header{
  text-align: center;
}
</style>
