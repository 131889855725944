<template>
  <div class="maincontent flexbetween">
    <div class="headezw"></div>
    <div class="activity">
      <div class="atitle flexbetween">
        <div class="flexs">{{ info.activityTitle }}<div class="astatus flexcenter">{{judgeStatus(info) ?'活动中':'已结束'}}</div></div>
        <div class="bmbtn flexcenter curpoint"  v-if="info.joinId">已报名</div>
        <div class="bmbtn flexcenter curpoint" @click="submit"
             v-else-if="judgeStatus(info)">立即报名</div>

      </div>
      <div class="atip">{{ info.joinCount }}人报名 {{ info.activityAddress }}   |  活动时间：{{ info.startTime }}至{{ info.endTime }}</div>
      <div v-html="info.activityContent"></div>
    </div>
  </div>
</template>

<script>
import { getRequest } from "@/utils/common.js";
import { getToken } from "@/utils/auth";

export default {
  name: "activityDetail",
  data(){
    return {
      activityId:'',
      info:{}
    }
  },
  mounted() {
    this.activityId=this.$route.query.activityId || ''
    this.getDetail()
  },
  methods:{
    judgeStatus(obj){
      return (new Date(obj.endTime).getTime()-new Date().getTime() > 0) && (obj.joinCount < obj.activityCount)
    },
    async getDetail(){
      this.$scrollTop()
      let {data} = await getRequest('abcode/unneed/activity/getActivityInfo/'+this.activityId,{})
      this.info=data && data.items?data.items:{}
    },
    showStatus(ndate){
      return new Date(ndate).getTime()-new Date().getTime()>0
    },
    async submit(){
      if(!getToken()){
        this.$store.commit('setShowLogin',true)
        return
      }
      let {data} = await getRequest(`abcode/unneed/activity/getActivityInfo/${this.activityId}`,{})
      let joinId=data && data.items?data.items.joinId:''
      if(!joinId){
        let { code,message }= await getRequest(`abcode/unneed/activity/activityJoin/${this.activityId}`,{})
        if(code == 20000){
          this.$message.success(message || '报名成功')
          this.getDetail()
        }else{
          this.$message.error(message || '报名失败')
        }
      }else{
        this.$message.info('您已经报名了')

      }

    },
  }
};
</script>

<style scoped>
.atip{
  color: #999;
  margin-top: 0.14rem;
}
.maincontent{
  width: 12rem;
  margin:auto;
  margin-top:0.2rem;
  margin-bottom: 0.2rem;
  flex-wrap: wrap;
}
.pagetitle{
  width: 100%;
  padding-bottom: 0.1rem;
  color: #808080;
  text-align: left;
}
.activity{
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 0.04rem 0.13rem 0px rgba(0, 0, 0, 0.16);
  border-radius: 0.16rem;
  margin-top:0.08rem;
  padding: 0.25rem 0.4rem;
}
.atitle{
 font-size: 0.24rem;
  font-weight: bold;
}
.astatus{
  width: 0.69rem;
  height: 0.24rem;
  background: #FFFFFF;
  border: 0.01rem solid #1386F0;
  border-radius: 0.12rem;
  margin-left: 0.14rem;
  color: #1386F0;
  font-size: 0.16rem;
  font-weight: 500;
}
.bmbtn{
  width: 0.87rem;
  height: 0.29rem;
  background: #1386F0;
  font-weight: 500;
  border: 0.01rem solid #1386F0;
  border-radius: 0.15rem;
  color: #fff;
  font-size: 0.16rem;
}
</style>
