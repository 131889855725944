<template>
  <div class="maincontent flexbetween">
    <div class="headezw"></div>
    <div class="sitems flexbetween">
      <div class="sitem curpoint" v-for="item in list" :key="'sinde'+item.tutorId" @click="$router.push('/teacherDetail?tutorId='+item.tutorId)">
        <div style="width: 100%" class="flexcenter">
          <div class="svatar flexcenter">
            <img :src="item.tutorPhoto" />
          </div>
        </div>

        <div class="sinfo">
          <div class="sname">{{ item.tutorName }}</div>
          <div class="role flexcenter">
            <span class="rolecon" v-for="(sitem,sindex) in item.bestAreas" :key="'area'+sindex">{{ sitem }}</span>
          </div>
          <div class="scount flexbetween">
            <div class="scitem">
              <div class="scnum">{{ item.tutorLevel }}</div>
<!--              <div class="sctip">导师等级</div>-->
              <div class="sctip">咨询等级</div>
            </div>
            <div class="scitem">
              <div class="scnum" >{{ item.chatCount }}</div>
              <div class="sctip">咨询人数</div>
            </div>
            <div class="scitem">
              <div class="scnum colorblue">{{getRate(item)  }}%</div>
              <div class="sctip">回答率</div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="flexcenter" style="width: 100%;margin:0.35rem 0">
      <el-pagination
          background
          layout="prev, pager, next"
          :current-page.sync="pageNum"
          @current-change="getList"
          :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getRequest } from "@/utils/common.js";


export default {
  name: "consult",
  data(){
    return {
      pageNum:1,
      pageSize:12,
      total:0,
      list:[],
    }
  },
  components:{},
  mounted() {
   this.getList()
  },
  methods:{
    getRate(obj){
      return obj.chatCount?parseInt(obj.answerCount/obj.chatCount*100):0
    },
    async getList(){
      this.$scrollTop()
      let { data } = await getRequest(`abcode/tutor/unneed/queryTutorList/${this.pageNum}/${this.pageSize}`, {});
      this.list = (data && data.rows ? data.rows : []).map(item=>{
        return {...item,tutorPhoto:item.tutorPhoto?item.tutorPhoto.replace('/opt/nginx/ymadmin/',this.imgurl):'',
          bestAreas:item.bestArea?item.bestArea.split(','):[]}
      });
      this.total = data.total || 0;
      console.log(this.total)

    }
  }
};
</script>

<style scoped>


.scnum{
  font-size: 0.24rem;font-weight: bold;
  text-align: center;
}
.sctip{
  color: #808080;margin-top:0.14rem
}
.maincontent{
  width: 12rem;
  margin:auto;
  margin-top:0.2rem;
  margin-bottom: 0.2rem;
  flex-wrap: wrap;
}

.sitems{
  width: 100%;
  flex-wrap: wrap;
}
.sitem{
  width: 2.62rem;
  margin-top:0.34rem
}
.sinfo{
  width: 2.62rem;
  padding-bottom: 0.4rem;
  background: #FFFFFF;
  box-shadow: 0px 0.1rem 0.29rem 0px rgba(33, 22, 19, 0.18);
  border-radius: 1.31rem 1.31rem 0.3rem 0.3rem;
  margin-top:-0.68rem
}
.sinfo:hover{
  box-shadow: 0px 0.1rem 0.29rem 0px rgba(33, 22, 19, 0.6);
  border-radius: 1.31rem 1.31rem 0.3rem 0.3rem;
}
.svatar{
  width: 1.35rem;
  height: 1.35rem;
  border-radius: 50%;
  background: #1486F1;
}
.svatar img{
  width: 1.35rem;
  height: 1.35rem;
  border-radius: 50%;
}
.sname{
  color: #2E2E2E;
  font-size: 0.17rem;
  font-weight: bold;
  width: 100%;
  text-align: center;
  padding-top: 0.85rem;
}
.role{

  display: flex;
  margin: auto;
  margin-top: 0.2rem;
  height: 0.24rem;

}
.rolecon{
  color: #808080;
  font-size: 0.13rem;
  background: #F4F4F4;
  border-radius: 0.1rem;
  padding: 0.03rem 0.05rem;
  margin-right: 0.05rem;
}
.scount{
  margin: auto;
  margin-top: 0.4rem;
  width: 2.05rem;
}
</style>
