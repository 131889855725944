<template>
  <div class="container">
    <div class="headezw"></div>
    <div class="maincontent">
      <div class="tophead">
        <div class="ctitle">Java课程基础编程</div>
        <div class="flexs" style="margin-top:0.32rem;">
          <div class="flexs mlft60">
            <i class="iconfont icon-renshu" style="font-size: 0.2rem;color: #fff"></i>
            <div class="dtip">学习人数 299</div>
          </div>
          <div class="flexs mlft60">
            <i class="iconfont icon-renshu" style="font-size: 0.2rem;color: #fff"></i>
            <div class="dtip">学习人数 299</div>
          </div>
          <div class="flexs mlft60">
            <i class="iconfont icon-renshu" style="font-size: 0.2rem;color: #fff"></i>
            <div class="dtip">学习人数 299</div>
          </div>

          <div class="startlearn flexcenter">开始学习</div>
        </div>
      </div>
      <div class="ucon flexbetween">
        <img src="../assets/detail/pic_jiantou.png" class="avtarimg" />
        <div class="perinfo">
          <div class="flexs">
            <div class="ls flexs">suth老师</div>
            <img class="mimg" src="../assets/detail/jiangzhang.png" />
            <img class="mimg" src="../assets/detail/guanzhu.png" />
          </div>
          <div class="intro">16年开发与架构设计经验，前京东金融开发经理，擅长领域建模。资深架构师将带领大家，探究 Dubbo 3 的底层实现原理，掌握源码分析技巧，助力大家学精学透 Dubbo 的同时，提升微服务架构能力，快速成为独当一面的顶级攻城狮！</div>
        </div>
      </div>
      <div class="snavs flexcenter">
        <div class="snav-item " style="margin-right: 0.55rem" @click="$router.push('/courseComment')">
          课程大纲

        </div>
        <div class="snav-item colorblue">课程评论<div class="navline"></div></div>
      </div>
      <div class="input-con">
        <div class="flexs">
          <div class="intitle">评论</div>
          <i class="iconfont  icon-shoucang"  style="font-size: 0.16rem;color: #FE5148" v-for="item in 4" :key="'start'+item"></i>
          <i class="iconfont  icon-shoucang"  style="font-size: 0.16rem;color: #E6E6E6" ></i>
        </div>
        <el-input
          type="textarea"
          :rows="4"  class="textarea"
          placeholder="请输入评论..."
          v-model="textarea">
        </el-input>
        <div class="fb-con">
          <div class="fbbtn flexcenter curpoint">发表</div>
        </div>

      </div>
      <div class="sitems">
        <div class="sitem" v-for="item in 5" :key="'comment'+item">
          <img src="../assets/detail/guanzhu.png" />
          <div class="sitem-right">
            <div class="flexbetween">
              <div class="flexs">
                <div class="user">qqqq</div>
                <i class="iconfont  icon-shoucang"  style="font-size: 0.16rem;color: #FE5148" v-for="item in 4" :key="'start'+item"></i>
                <i class="iconfont  icon-shoucang"  style="font-size: 0.16rem;color: #E6E6E6" ></i>
              </div>
              <div class="sitem-time">2021-9-18</div>
            </div>
            <div class="sitem-tip">最近看了此课程前三个阶段，感觉受益匪浅。老师对细节讲的非常细，以前觉得框架会用就行，看了之后才觉得会用和用好之间差距很大。非常期待
              后面的课程!老师对细节讲的非常细，以前觉得框架会用就行，看了之后才觉得会用和用好之间差距很大。
            </div>
          </div>
        </div>
      </div>
      <div class="bpages flexcenter" v-if="total">
        <div
          class="pagecz flexs"
          :class="{ cannot: pageNum == 1 }"
          @click="
          pageNum = 1;
          getList();
        "
        >
          首页
        </div>
        <div
          class="pagecz flexs"
          :class="{ cannot: pageNum == 1 }"
          @click="
          pageNum = pageNum - 1;
          getList();
        "
        >
          上一页
        </div>
        <div
          class="pagecon flexcenter"
          :class="{ paged: item == pageNum }"
          v-for="item in total"
          :key="'page' + item"
        >
          {{ item }}
        </div>
        <div
          class="pagecz flexs"
          :class="{ cannot: total <= pageNum }"
          @click="
          pageNum = pageNum + 1;
          getList();
        "
        >
          下一页
        </div>
        <div
          class="flexs"
          :class="{ cannot: total <= pageNum }"
          @click="
          pageNum = total;
          getList();
        "
        >
          尾页
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "courseComment",
  data(){
    return {
      pageSize: 10,
      pageNum: 1,
      total:3,
      textarea:''
    }
  },
  mounted() {
    this.getList();
  },
  methods:{
    getList(){}
  }
};
</script>

<style scoped>
.sitems{
  width: 11.4rem;
  padding: 0 0.3rem;
  background: #FFFFFF;
  box-shadow: 0px 0.03rem 0.13rem 0px rgba(0, 0, 0, 0.17);
  border-radius: 0.16rem;
  margin:0.6rem 3.575rem 0.3rem 3.575rem
}
.sitem{
  padding: 0.4rem 0;
  width: 100%;
  border-bottom: 0.01rem solid #E5E5E5;
  display: flex;
  justify-content: space-between;
}
.sitem-time{
  font-size: 0.16rem;
  color: #666;
}
.user{
  font-size: 0.16rem;
  font-weight: bold;
  margin-right: 0.2rem;
}
.sitem-tip{
  font-size: 0.16rem;
  color: #666;
  line-height: 0.26rem;
  margin-top: 0.23rem;
}
.sitem-right{
  width: 10.6rem;
}
.sitem img{
  width: 0.65rem;
  height: 0.65rem;
  border-radius: 50%;
}
.input-con{

  width: 12rem;
  margin: auto;
  margin-top:0.5rem;
}
.textarea{
  width: 100%;
  height: 0.92rem;
  margin-top: 0.1rem;
  font-size: 0.16rem;
}
.fbbtn{
  width: 1rem;
  height: 0.37rem;
  background: #1386F0;
  border-radius: 0.18rem;
  color: #FFFFFF;
  font-size: 0.18rem;

}
.fb-con{
  margin-top:0.4rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.intitle{
  color: #060606;
  font-size: 0.18rem;
  margin-right: 0.1rem;
}
.container{
  width: 100%;
  padding-bottom: 0.9rem;
}
.cannot {
  color: #999;
}
.pagecz {
  height: 0.3rem;
  margin-right: 0.2rem;
}
.pagecon {
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 0.2rem;
  margin-right: 0.2rem;
}
.paged {
  background: #262626;
  color: #fff;
}
.bpages {
  margin: auto;
  margin-top: 0.26rem;
}

.snavs{
  width: 100%;
  margin-top: 0.35rem;
}
.snav-item{
  color: #4D4D4D;
  font-size: 0.2rem;
  position: relative;
  padding-bottom: 0.08rem;
}
.navline{
  width: 0.36rem;
  height: 0.03rem;
  background: #1386F0;
  position: absolute;
  left:50%;
  bottom: 0;
  transform: translate(-50%,-50%);
  border-radius: 0.02rem;
}
.perinfo{
  padding-left: 0.32rem;
  width: 8.58rem;
  border-left: 0.01rem solid #dcdcdc;
  color: #808080;
}
.intro{
  color: #808080;
  font-size: 0.14rem;
  line-height: 0.2rem;
  margin-top:0.15rem
}
.mimg{
  width: 0.17rem;
  height: 0.21rem;
  margin-left: 0.13rem;
}
.ls{
  color: #0A0A0A;
  font-size: 0.2rem;
}
.ucon{
  width: 10.2rem;
  height: 0.83rem;
  background: #FFFFFF;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  margin: auto;
  margin-top:0.3rem;
  padding: 0.3rem 0.9rem;
}
.avtarimg{
  width: 0.74rem;
  height: 0.74rem;
  border-radius: 50%;
}
.mlft60{
  margin-right: 0.6rem;
}
.dtip{
  font-size: 0.18rem;
  color: #FFFFFF;
  margin-left: 0.07rem;
}
.startlearn{
  width: 1.22rem;
  height: 0.36rem;
  background: #FFFFFF;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.18rem;
  font-size: 0.2rem;
  color: #1386F0;
  cursor: pointer;
}
.maincontent{
  width: 100%;
  background: url("../assets/detail/pic_xuexi.jpg") no-repeat top;
  background-size: 100%;
  padding-top: 0.8rem;
}
.ctitle{
  font-size: 0.36rem;
  font-weight: bold;
}
.tophead{
  width: 12rem;
  margin:auto;
  color: #fff;
}
</style>
