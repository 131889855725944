<template>
  <div class="left defaultpoint">
    <div class="avatar-con" >
      <img v-if="userInfo.photo" :src="userInfo.photo" style="border-radius: 50%" />
      <img v-else src="../assets/favicons.png"/>
    </div>
    <div class="flexcenter hyinfo">
      <div style="margin-right: 0.1rem; font-size: 0.18rem">{{ userInfo.userNick || "" }}</div>
      <img  v-if="userInfo.level == 1" :src="v1">
      <img  v-if="userInfo.level == 2" :src="v2">
      <img  v-if="userInfo.level == 3" :src="v3">
      <img  v-if="userInfo.level == 4" :src="v4">
      <img  v-if="userInfo.level == 5" :src="v5">
      <img  v-if="userInfo.level == 6" :src="v6">
      <img  v-if="userInfo.level == 7" :src="v7">
      <img  v-if="userInfo.level == 8" :src="v8">
      <img  v-if="userInfo.level == 9" :src="v9">
      <img
        :src="sexImg"
        style="width: 0.2rem;margin-left: 0.1rem "
      />
    </div>

    <div class="sdate flexcenter" v-if="userInfo.vipEndTime">
      会员有效期：{{ userInfo.vipEndTime }}
    </div>
    <div class="sdate flexcenter" title="ABCode平台积分，10码力≈1元">
      <img style="width: 0.25rem;margin-right: 0.05rem" src="../assets/mali.png">
      {{ userInfo.codeForce }} 码力
    </div>
    <div class="sitems" style="margin-top: 0.3rem">
      <div class="flexbetween curpoint" :class="{sitem1:curMenu != item.id,sitem2:curMenu == item.id}" v-for="(item,index) in menus " :key="item.id"
           @click="goPath(item,index)">
        <div>{{ item.label }}</div>
        <i class="iconfont icon-arrow-right-filling" style="font-size: 0.15rem;" :style="{color:curMenu == item.id?'#fff':'#ddd'}"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { getRequest } from "@/utils/common.js";

export default {
  name: "infoLeft",
  data(){
    return {
      userInfo:{},
      sexImg:'',
      v1:require('../assets/detail/v1.png'),
      v2:require('../assets/detail/v2.png'),
      v3:require('../assets/detail/v3.png'),
      v4:require('../assets/detail/v4.png'),
      v5:require('../assets/detail/v5.png'),
      v6:require('../assets/detail/v6.png'),
      v7:require('../assets/detail/v7.png'),
      v8:require('../assets/detail/v8.png'),
      v9:require('../assets/detail/v9.png'),
      curMenu:'',
      menus:[
        {path:'/info',label:'个人信息',id:'info',title:'个人信息'},
        {path:'/setAccount',label:'账号设置',id:'account',title:'账号设置'},
        {path:'/myOrder',label:'我的订单',id:'myOrder',title:'我的订单'},
        {path:'/mycourse',label:'我的课程',id:'mycourse',title:'我的课程'},
        {path:'/myactivity',label:'我的活动',id:'myactivity',title:'我的活动'},
        {path:'/myCoupon',label:'我的优惠券',id:'myCoupon',title:'我的优惠券'}
      ],
    }
  },
  props:{
    isJump:{
      type:Boolean,
      value:false
    }
  },
  mounted() {
    this.getUserInfo()
    this.curMenu=this.menus[0].id;
  },
  computed:{
    refreshUserInfo(){
      return this.$store.state.refreshUserInfo;
    }
  },
  watch:{
    refreshUserInfo(val){
      if(val){
        this.getUserInfo()
      }
    }
  },
  methods:{
    goPath(obj){
      if(obj.id=='myOrder'){
        this.$emit('clickOrder',true)
      }
      if(obj.id == this.curMenu && !this.isJump)return
      // this.$router.push(obj.path)
      this.curMenu=obj.id
      this.$emit('selectMenu',obj)
    },
    async getUserInfo() {
      let { data } = await getRequest("abcode/user/queryUserInfo", {});
      this.userInfo=data || {}
      this.sexImg=require(`../assets/${ this.userInfo.sex==1?'icon_nv':'icon_nan'}.png`)
      this.$store.commit('setRefreshUserInfo',false)
    },
  }
};
</script>

<style scoped>
.sitems{
  width: 100%;
  margin-top:0.4rem;
}
.sitem1{
  margin: 0px 0.19rem;
  padding: 0.15rem 0;
  width:calc(100% - 0.38rem);
  border-bottom: 0.01rem solid #dcdcdc;
}
.sitem2{
  padding: 0.15rem 0.19rem;
  width:calc(100% - 0.38rem);
  background: #1386F0;
  color: #FFFFFF;
}
.hyinfo{
  font-size: 0.2rem;
  margin-top:0.19rem
}
.hyinfo img{
  width: 0.25rem;
}
.sdate{
  color: #808080;
  margin-top: 0.2rem;
  text-align: center;
}
.grade{
  position: absolute;
  bottom: 0;
  right: 0rem;
  width: 0.29rem !important;
  height: 0.29rem !important;
}
.avatar-con{
  width: 0.88rem;
  height: 0.88rem;
  position: relative;
  margin:auto;
  border-radius: 50%;
}
.avatar-con img{
  width: 0.88rem;
  height: 0.88rem;
}
.cinfo{
  width: 6.5rem;
  cursor: pointer;
}
.aname{
  line-height: 0.23rem;
  font-size: 0.18rem;
}
.bmnum{
  color: #999;
  font-size: 0.14rem;
  margin-top: 0.19rem;
  text-align: left;
}
.activity{
  color:red;
  font-size: 0.16rem;
}
.ybm{
  color: #1386F0;
  font-size: 0.13rem;
  width: 0.72rem;
  height:0.29rem;
  border-radius: 0.15rem;
  border: 0.01rem solid #1386F0;
  margin-left: 0.2rem;
}
ybmed{
  color: #fff;
  background: #1386F0;
  font-size: 0.13rem;
  width: 0.72rem;
  height:0.29rem;
  border-radius: 0.15rem;
  border: 0.01rem solid #1386F0;
  margin-left: 0.2rem;
}
.maincontent{
  width: 12rem;
  margin:auto;
  margin-top:0.2rem;
  margin-bottom: 10.5rem;
  flex-wrap: wrap;
}
.pagetitle{
  width: 100%;
  padding-bottom: 0.1rem;
  color: #808080;
  text-align: left;
}
.left{
  width: 2.58rem;
  background: #FFFFFF;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  padding: 0.35rem 0 0.2rem 0;
}
.right{
  width: 8.9rem;
  height: 4.56rem;
  background: #FFFFFF;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  padding: 0.2rem 0.2rem 0 0.2rem;
}
.mtitle{
  font-size: 0.16rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0.1rem;
}
.sitem{
  width: 100%;
  padding: 0.25rem 0;
  border-bottom: 0.01rem solid #dcdcdc;
}
.leftimg{
  width: 2.25rem;
  height: 1.58rem;
  position: relative;
}
.leftimg img{
  width: 2.25rem;
  height: 1.58rem;
}
.bstatus{
  position: absolute;
  right: 0.08rem;
  bottom: 0.08rem;
  color: #1386F0;
  border:0.01rem solid #1386F0;
  width: 0.56rem;
  height: 0.23rem;
  border-radius: 0.11rem;
}
</style>
