<template>
  <div class="agrement">
    <section class="content ">
      <div class="first">
        <p class="agrement-title">服务条款</p>
        <br />
        欢迎您来到ABCode （www.abcode.com）
        <br />
        ABCode是主要依靠网络技术，通过ABCode平台向您在线提供在线学习服务。请您在注册和使用ABCode学习平台前仔细阅读本协议。如您不同意本协议任何条款，请勿注册账号或使用本平台。
      </div>
      <p>
        <strong> 一.注册协议条款的确认和接受 </strong><br />
        1.
        本协议双方为约码科技有限公司旗下的ABCode（以下亦称“本网站”）和ABCode注册用户。本协议阐述之条款和条件适用于ABCode（所涉域名为：
        www.abcode.com）的全部学习服务，包括但不限于各种课程视频、学习内容、源代码、资料及相应服务。
        <br />
        2.
        ABCode同意按照本协议的规定及其不定时发布的操作规则提供基于互联网和移动网的相关服务(以下称“网络服务”)。为获得网络服务，申请人应当认真阅读、充分理解本《协议》中各条款,
        包括免除或者限制本网站责任的免责条款及对用户的权利限制条款。认真阅读并选择接受或不接受本《协议》(未成年人应在法定监护人陪同下阅读)。同意接受本协议的全部条款的，申请人应当按照页面上的提示完成全部的注册程序，并在注册程序过程中点击“同意”按钮，否则视为不接受本《协议》全部条款，申请人应当终止并退出申请。
        <br />
        3.
        注册用户在使用ABCode中的有关服务时，应承诺接受并遵守各项相关规则的规定。ABCode有权根据实际运营需要而不定时修改本协议或补充协议，如本协议有任何变更，将通过网站消息或其他方式通知用户。如注册用户不同意相关变更，则应立即终止账号使用，否则即视同用户同意并完全接受修订后的协议版本。经修订的协议一经公布于ABCode网站链接及页面，立即自动生效，亦成为本协议的一部分。注册用户登录且继续使用服务将被视为已接受修订后的协议。除书面另行声明外，任何扩大的服务范围及新增提供的内容均受本协议约束。
      </p>
      <p>
        <strong>二. 注册用户的权利和义务</strong><br />
        1.
        用户在注册时应按照注册提示提供准确的用户名、密码及真实的联系邮箱、手机号码、真实姓名等所要求的个人资料，并及时更新注册（报名）资料，符合及时、详尽、准确的要求，用户输入的所有个人信息将被视作用户的准确身份信息。
        <br />
        2.
        如果学员提供的资料包含有不正确或不良的信息，本网站保留结束学员使用网络服务资格的权利。
        <br />
        3.
        在支付课程或会员费用并经ABCode确认后，注册用户有权通过本人在ABCode的注册的账号享受相应服务。具体服务内容的时间、进度及期限以ABCode的具体课程及产品说明、公告及内容为准。
        <br />
        4.
        ABCode的注册账号只为注册用户本人所专有并仅限由其本人自己使用。一个注册账号同一时间，只能在一台终端机上登录并使用，且一个注册账号至多只能在注册用户本人所有的五台不同的终端机上登录使用。
        <br />
        5.
        未经ABCode许可，不得以任何形式向第三方转让、授权、出售ABCode课程、服务或授权第三方使用注册账号，不得以任何形式通过本站内容进行盈利活动，不得在商业环境下展映、传播本网站教学内容。
        <br />
        6.
        注册用户仅对其在ABCode上享有的服务及内容有使用权，并不对该内容拥有相关知识产权。未经ABCode或其他有权第三方的许可，用户不得对包括视频、学习软件、学习资料、音频内容等在内的任何内容进行翻录、复制、发行、破解、信息网络传播或其他违反知识产权相关法律、法规的行为，否则所导致的一切民事、行政或刑事责任，由用户自行承担。
        <br />
        7.
        对于用户在ABCode中所下载任何标有ABCode所有的资料，注册用户只得根据具体的使用协议进行使用，并不拥有该产品及产品中任何内容的一切知识产权。除非经相应的产品使用协议许可，注册用户不得自行或授权他人对软件或其中的任何一部分进行复制、反编译、倒序制造、反汇编、试图推导源代码、破译、修改或创作衍生作品，因此而造成ABCode或任何第三方的损失，由用户承担全部责任。ABCode对上述侵权或违约行为保留追索的权利。
        <br />
        8.
        对于注册用户在ABCode提交的问题与笔记，注册用户同意ABCode对此内容享有复制、发行及独家的出版权。
        <br />
        9.
        注册用户应对其账号的全部使用行为承担责任，应严格遵守本协议、相关法律法规、账号及课程使用规定。未经ABCode许可，禁止用户向任何第三方提供ABCode中的任何内容或资料。
        <br />
        10.
        注册用户应自行配备上网的所需设备，包括个人电脑、调制解调器或其他必备上网装置；用户应自行负担因使用这种接入方式而产生的上网电话费、上网信息费等费用。
        <br />
        11.
        ABCode的部分服务附期限，对于此类服务，用户应在服务截止日期前享受其购买的服务。因到期服务终止所导致的任何后果，ABCode不承担任何责任。
        <br />
        12.
        注册用户的ABCode支付（包括但不限于通过网上支付、邮局汇款、银行电汇、货到付款、上门购买并现金支付、自经销商处购买听课卡等各种购买方式），一经购买，除非双方有额外的协议规定，都不允许任何形式的退换和退费。
        <br />
        13. 用户在账号使用过程中不得制作、复制、发布、传播含有下列内容的信息：
        (1) 反对宪法所确定的基本原则的； (2)
        危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的； (3)
        损害国家荣誉和利益的； (4) 煽动民族仇恨、民族歧视，破坏民族团结的； (5)
        破坏国家宗教政策，宣扬邪教和封建迷信的； (6)
        散布谣言，扰乱社会秩序，破坏社会稳定的； (7)
        散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的； (8)
        侮辱或者诽谤他人，侵害他人合法权益的； (9)
        干扰或者侵犯ABCode的正常运行和秩序，影响其他用户正常使用的； (10)
        含有法律、行政法规禁止的其他信息内容的。
        <br />
        14. 注册用户将自行承担注册账号使用过程中可能发生的风险和损失。
        <br />
        15.
        用户对本协议或将来的修改版本有任何异议的，或对产品服务存有异议或不满的，可以通过客服微信（ABCode-2021）向ABCode有关部门进行反映沟通，不得通过煽动、诋毁及通过散布其他不良信息方式进行。
      </p>
      <p>
        <strong>三. 注册用户的违约责任</strong><br />
        1.
        注册用户如违反本协议第二条第四款、第五款、第六款、第七款的规定，ABCode将视其情况停止注册用户所享有的服务2天至60天不等，情况及影响恶劣者，ABCode有权立即终止向其提供服务，并删除其账户信息。
        <br />
        2.
        注册用户如违反本协议第二条第十三款规定，ABCode将立即终止向其提供的服务，同时保存有关记录，并向国家有关机关报告。
        <br />
        3.
        注册用户如违反本协议约定，应赔偿所造成的ABCode全部经济损失，包括但不限于律师费、公证费、赔偿金及行政处罚金等，并承担其他法律责任。
        <br />
        4.
        注册用户因违约而导致对任何第三方的侵权或任何索赔，均应由注册用户独自并完全承担，并且注册用户应赔偿ABCode为此所遭受的全部经济损失。如该等赔偿已由ABCode清付，注册用户应立即全数（含利息）偿还，并且，注册用户应赔偿ABCode为此所遭受的经济及商誉的损失。
      </p>
      <p>
        <strong>四. ABCode的权利和义务</strong><br />
        1.
        ABCode有义务通过现有技术维护ABCode的正常运行，并努力提升和改进服务使用户的学习活动得以顺利进行；由于不可抗力所导致服务的中止或终止，ABCode不对因此造成注册用户的任何损失承担责任。
        <br />
        2.
        用户在注册及使用ABCode过程中遇到的相关问题，ABCode将力争及时作出反馈。
        <br />
        3. 如因系统维护或升级而需暂停服务时，ABCode应提前告知用户。
        <br />
        4.
        ABCode不保证其服务一定能够满足用户的全部要求，也不担保其服务不会因各种客观原因中断。
      </p>
      <p>
        <strong>五. 免责条款</strong><br />
        1.
        当用户所享有的服务中显示其他第三方网站或内容时，由于ABCode无法控制及审核该网站或内容，因此并不对该网站或内容真实性、有效性、合法性等效力承担责任。对于用户任何因使用或信赖该网站或内容所导致的任何直接或间接损失，ABCode均不承担任何责任。
        <br />
        2.
        由于用户将个人注册账号信息告知他人或与他人共享注册帐号的，由此导致的任何风险或损失，ABCode不对此负任何责任。
        <br />
        3.
        任何由于黑客攻击、计算机病毒侵入或发作、政府管制、硬件故障、不可抗力等非ABCode故意或严重过失而造成的用户个人资料泄露、丢失、被盗用、被篡改或服务暂定或终止的，对注册用户所造成的风险或损失，ABCode均得免责。
        <br />
        4.
        若因线路及非ABCode控制范围外的或其它不可抗力而导致暂停服务暂定或终止，所造成的一切风险与损失，ABCode不负任何责任。
        <br />
        5.
        ABCode有权根据用户的实际需求进行服务调整的权利，并有权根据实际情况调整本网站内容的售价，
        由此造成的一切不便与损失，ABCode均得免责。
        <br />
        6. 有权利删减自己的课程。
      </p>
      <p>
        <strong>六. 隐私条款</strong><br />
        ABCode将严格履行用户个人隐私保密义务，承诺不公开、编辑或透露用户个人信息，
        但以下情况除外：
        <br />
        1. 经用户授权透露这些信息；
        <br />
        2. 应政府部门、法律及法规要求提供、披露相关用户个人资料；
        <br />
        3. 在特定情况下，为竭力维护用户个人、其他社会个体和公共安全需要。
      </p>
      <p>
        <strong
          >七.
          法律本协议根据现行中华人民共和国法律法规制定并解释。如发生协议条款与中华人民共和国法律法规相抵触时，则抵触内容将按法律规定重新解释，但不影响其它条款的效力。</strong
        >
      </p>
      <p>
        <strong
          >八. 解释权
          上述条款的解释权在法律允许的范围内归ABCode所有。相关参考法律法规：</strong
        ><br />
        1.《全国人大常委会关于维护互联网安全的决定》
        <br />
        2.《互联网文化管理暂行规定（2011修订）》
        <br />
        3.《互联网信息服务管理办法》
        <br />
        4.《互联网站禁止传播淫秽、色情等不良信息自律规范》
        <br />
        5.《中华人民共和国侵权责任法》
        <br />
        6.《中华人民共和国合同法》
        <br />
        7.《中华人民共和国著作权法》
        <br />
        8.《中华人民共和国计算机软件保护条例》
        <br />
        9.《信息网络传播权保护条例》
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: "agreement",
  mounted() {
    console.log(11111111);
  },
};
</script>

<style scoped>
.agrement .agrement-title {
  margin-bottom: 0.25rem;
  font-size: 0.24rem!important;
  line-height: 0.4rem;
  color: #3f3939;
  font-weight: 700;
  font-style: normal;
  text-align: center;
}
.agrement .content{
  max-width: 12rem;
  height: auto;
  margin: 0.25rem auto;
  padding: 0 0.25rem;
  line-height: 0.3rem;
  font-size: 0.2rem;
}
.agrement .content p{
  line-height: 0.36rem;
  font-size: 0.2rem;
}
.agrement .content strong{
  font-size: 0.24rem;
  line-height: 0.4rem;
}

</style>
