<template>
  <div class="maincontent flexbetween">
    <div class="headezw"></div>
    <img style="width: 100%" src="../assets/system/pic_banner.png">
    <div class="options">
      <div class="flexs optionitem defaultpoint" >
        <div class="suboptioned" >零基础就业</div>
        <div class="suboption" >零基础跟随体系课程学习—带你从入门到精通</div>
      </div>
    </div>

    <div class="sitem flexbetween defaultpoint"  v-for="item in list" :key="'courseId'+item.courseId" >
      <img v-if="judgeStatus(item)" class="rightimg" src="../assets/appointment.png">
      <div class="leftimg flexcenter curpoint" @click="startlearn(item)">
<!--      <div class="leftimg defaultpoint">-->
        <img :src="item.coursePhoto"/>
        <div  class="sprotitle">{{ item.courseName }}</div>
      </div>
      <div class="cinfo">
        <div class="aname curpoint" @click="startlearn(item)">{{ item.courseTitle }}</div>
<!--        <div class="aname defaultpoint">{{ item.courseName }}</div>-->
        <div class="bmnum defaultpoint" v-if="item.courseLabel">
          <span v-for="(sitem,sindex) in item.courseLabels" :key="'course'+sindex">{{ sitem }}</span>
        </div>
        <div class="flexs" style="margin-top: 0.2rem">
          <div class="activity flexcenter" style="margin-right: 0.4rem">
            <i class="iconfont icon-renshu" style="font-size: 0.2rem;color: #999;margin-right: 0.05rem"></i>
            学习人数 {{ item.studyCount }}人
          </div>
          <div class="ybm flexcenter" style="margin-right: 0.4rem">
            <i class="iconfont icon-shijian1" style="font-size: 0.2rem;color: #999;margin-right: 0.05rem"></i>
            课程时长 {{ item.courseTotalTime }}</div>
          <div class="ybm flexcenter">
            <i class="iconfont icon-zhangjiechuti" style="font-size: 0.2rem;color: #999;margin-right: 0.05rem"></i>
            章节 {{ item.totalChapter }}节</div>
        </div>
        <div class="flexs" style="margin-top:0.2rem">
          <div class="leftprice colorred">
            <span style="font-size: 0.22rem"  v-if="item.isPay==1">￥{{item.discountPrice}}</span>
            <span style="font-size: 0.2rem" class="colorgreen" v-else>免费&nbsp;</span>
            <span style="margin-left:0.1rem;color: #999;text-decoration: line-through">￥{{item.coursePrice}}</span>
          </div>
          <div class="registernow flexcenter" @click="startlearn(item)" v-if="judgeStatus(item)">立即报名</div>
          <div class="startlearn flexcenter" @click="startlearn(item)" v-else>开始学习</div>
<!--          <div class="staytuned flexcenter" >敬请期待</div>-->
<!--          <div class="ybm flexcenter" style="margin-left:0.3rem" v-if="judgeStatus(item)">-->
<!--            开课时间：{{ item.onLineTime }}-->
<!--          </div>-->
        </div>

      </div>
    </div>
    <div class="flexcenter" style="width: 100%;margin:0.35rem 0">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :current-page.sync="pageNum"
        @current-change="getList"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {getRequest} from '@/utils/common.js'


export default {
  name: "systemCourse",
  data(){
    return {
      pageSize:10,
      pageNum:1,
      courseType:1,
      list:[],
      total:0,
      posts:[]
    }
  },
  components:{},
  mounted() {
    this.courseType=this.$route.query.courseType || 1
    this.getList()
  },
  methods:{
    async startlearn(item){
      let { data } = await getRequest(`abcode/course/queryUserIsPayCourseAndPass/`+item.courseId,{})
      console.log(data,'dddddd')
      this.$router.push('/courseDetail?courseId='+item.courseId)

    },
    async getList(){
      this.$scrollTop()
      let params ={
        pageNum:this.pageNum,
        pageSize:this.pageSize,
        courseType:0
      }
        let { data } = await getRequest(`abcode/course/unneed/querySystemCourse`,params)
      this.total=data.total || 0
      this.list=(data && data.rows?data.rows:[]).map(item=>{
        return {...item,
          coursePhoto:item.coursePhoto?item.coursePhoto.replace('/opt/nginx/ymadmin/',this.imgurl):'',
          courseLabels:item.courseLabel?item.courseLabel.split(','):[]}
      })


    },
    async getAllPost(){

      let { data } = await getRequest(`abcode/unneed/postandlanguage/queryAllPost`, {});
      this.posts=data && data.items?data.items:[];
    },
    judgeStatus(obj){
      return new Date(obj.onLineTime).getTime()-new Date().getTime() > 0
    }
  }
};
</script>

<style scoped>
.startlearn{
  width: 1.3rem;
  height: 0.4rem;
  border-radius: 0.2rem;
  background-image: linear-gradient(#08ac62, #4bbc89e8);
  color: #FFFFFF;
  font-size: 0.2rem;
  cursor: pointer;
}
.registernow{
  width: 1.3rem;
  height: 0.4rem;
  background: #1386F0;
  border-radius: 0.2rem;
  background-image: linear-gradient(#1386F0, #21A9EF);
  color: #FFFFFF;
  font-size: 0.2rem;
  cursor: pointer;
}
.staytuned{
  width: 1.55rem;
  height: 0.4rem;
  background: #608698;
  border-radius: 0.2rem;
  color: #FFFFFF;
  font-size: 0.18rem;
  cursor: default;
}
.leftprice {
  font-size: 0.2rem;
  font-weight: bold;
  width: 2rem;
}
.sitem{
  width: 100%;
  padding: 0.2rem;
  border-bottom: 0.01rem solid #dcdcdc;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
}
.sitem:hover{
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.3);
}
.leftimg{
  width: 2.25rem;
  height: 1.58rem;
  position: relative;
}
.leftimg img{
  width: 2.25rem;
  height: 1.58rem;
  border-radius: 0.16rem;
}
.sprotitle{
  font-size: 0.22rem;
  color: #ffffff;
  line-height: 0.31rem;
  width: 2rem;
  text-align: center;
  font-weight: bold;
  position: absolute;
  left: 50%;
  top:50%;
  transform: translate(-50%,-50%);
}

.maincontent{
  width: 12rem;
  margin:auto;
  margin-top:0.2rem;
  margin-bottom: 0.2rem;
  flex-wrap: wrap;
}
.options{
  width: 100%;
  padding: 0.2rem 0.16rem;
  background: #FFFFFF;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  /*margin-top:0.1rem*/
}


.suboption{
  padding: 0.03rem 0.05rem;
  border-radius: 0.05rem;
  margin-right: 0.15rem;
  font-size: 0.16rem;
  color: #666;
}
.suboptioned{
  background: rgba(19, 134, 240, 0.2);
  color: rgba(19, 134, 240, 1);
  padding: 0.05rem 0.1rem;
  border-radius: 0.05rem;
  margin-right: 0.15rem;
  font-size: 0.16rem;
  cursor: default;
}
.optionitem{
  margin:0.15rem 0.3rem;
}

.sitem{
  width: 100%;
  padding: 0.2rem;
  border-bottom: 0.01rem solid #dcdcdc;
  margin-top:0.2rem;
  position: relative;
}


.cinfo{
  width:calc(100% - 2.5rem);
}
.aname{
  line-height: 0.23rem;
  font-size: 0.18rem;
}
.bmnum{
  margin-top: 0.15rem;
  text-align: left;
  display: block;
}
.bmnum span{
  padding: 0.02rem 0.05rem;
  border-radius: 0.04rem;
  background: #E0E9FA;
  color: #4f88de;
  font-size: 0.14rem;
  margin-right: 0.2rem;
}
.rightimg{
  width: 1.2rem;
  height: 1.2rem;
  position: absolute;
  top:-0.166rem;
  right: -0.165rem;
}
</style>
