<template>
  <div style="width: 100%">
    <div class="headezw"></div>
    <div class="topitem flexbetween" v-if="list[curNum]">
      <div class="itemleft">
        <div class="ctitle">
          <div class="leftt">{{ curNum+1 }}、{{ list[curNum].questionTitle }}</div>
          <div class="rigtht">{{curNum+1}}/{{total}}</div>
        </div>
        <div class="flexs" style="margin-top:0.2rem">
          <span class="labelcon">{{ list[curNum].questionLabel }}</span>
        </div>
        <div class="cbottom flexbetween">
          <div>入门    298人学习</div>
          <div class="flexs" v-if="false">
            <div class="flexs" style="margin-right: 0.3rem;font-size: 0.16rem;">
              <i class="iconfont  icon-shoucang"  style="font-size: 0.16rem;color: #999"></i>
              收藏
            </div>
            <div class="flexs" style="font-size: 0.16rem;">
              <i class="iconfont icon-fenxiang1"  style="font-size: 0.16rem;color: #999"></i>
              分享
            </div>
          </div>
        </div>
      </div>
      <div class="itemright flexbetween">
        <img :src="userInfo.photo" />
        <div class="pinfo">
          <div class="flexs">
            <div class="pname">{{userInfo.userNick}}</div>
            <img class="vipimg" v-if="userInfo.isVIP" src="../assets/shuati/pic_vip.png"/>
            <div class="stype" v-if="false">IT交流学习</div>
          </div>
          <div class="sttip">{{userInfo.motto}}</div>
          <div class="flexs" style="margin-top: 0.3rem;">
            <div style="margin-right: 0.3rem;">刷题数量:{{ userInfo.sumCount }}</div>
            <div>正确率:{{ (userInfo.avg*100) +'%'}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="questcon" v-if="list[curNum]">
<!--      <div class="progress"><div class="yigo" :style="{width:(curNum+1)/(total)*100+'%'}"></div></div>-->
      <div >
        <div class="flexbetween" style="margin-top: 0.3rem">
          <div class="question" v-html="list[curNum].questionContent"></div>
        </div>
        <div class="answers">
          <div class="flexbetween"  style="margin-top: 0.15rem;cursor: pointer" v-if="list[curNum].aoption">
            <i class="iconfont icon-Raidobox-xuanzhong" :style="{color:list[curNum].answer=='A'?'#1386F0':'#e8e8e8'}"
               style="font-size: 0.17rem;"></i>
            <div class="answer1" >A. {{ list[curNum].aoption }}</div>
          </div>
          <div class="flexbetween" v-if="list[curNum].boption" style="margin-top: 0.15rem;cursor: pointer">
            <i class="iconfont icon-Raidobox-xuanzhong"  :style="{color:list[curNum].answer=='B'?'#1386F0':'#e8e8e8'}"
               style="font-size: 0.17rem;"></i>
            <div class="answer1">B. {{ list[curNum].boption }}</div>
          </div>
          <div class="flexbetween" v-if="list[curNum].coption" style="margin-top: 0.15rem;cursor: pointer">
            <i class="iconfont icon-Raidobox-xuanzhong"  :style="{color:list[curNum].answer=='C'?'#1386F0':'#e8e8e8'}"
               style="font-size: 0.17rem;"></i>
            <div class="answer1">C. {{ list[curNum].coption }}</div>
          </div>
          <div class="flexbetween" v-if="list[curNum].doption" style="margin-top: 0.15rem;cursor: pointer">
            <i class="iconfont icon-Raidobox-xuanzhong"  :style="{color:list[curNum].answer=='D'?'#1386F0':'#e8e8e8'}"
               style="font-size: 0.17rem;"></i>
            <div class="answer1">D. {{ list[curNum].doption }}</div>
          </div>
          <div class="flexbetween" v-if="list[curNum].eoption" style="margin-top: 0.15rem;cursor: pointer">
            <i class="iconfont icon-Raidobox-xuanzhong"  :style="{color:list[curNum].answer=='E'?'#1386F0':'#e8e8e8'}"
               style="font-size: 0.17rem;"></i>
            <div class="answer1">E. {{list[curNum].eoption }}</div>
          </div>
        </div>

        <div class="jiedacon flexs">
          <div class="dtjx flexcenter">答题解析</div>
          <div class="jxcontent">
            <div class="flexs jxtop" >
              <span >正确答案：</span><span style="color: #1386F0;margin-right: 0.4rem">{{ list[curNum].answer }}</span>
<!--              <span >您的答案：</span><span style="color: #F13D3D">B</span>-->
            </div>
            <div class="jxtip" v-html="list[curNum].questionAnalysis"></div>
          </div>
        </div>
      </div>
      <div class="answernav" v-if="false">
        <div class="answernav-title">答题情况</div>
        <div class="flexs" style="flex-wrap: wrap">
          <div class="aswernav-item flexcenter">1</div>
          <div class="aswernav-item1 flexcenter">2</div>
          <div class="aswernav-item2 flexcenter">3</div>
          <div class="aswernav-item flexcenter">4</div>
          <div v-for="item in 24" :key="'snav'+item" class="aswernav-item flexcenter">{{ item }}</div>
        </div>
      </div>
      <div class="pandu flexs" v-if="false">
        <div class="dot"></div>
        <div style="margin-right: 0.4rem;font-weight: bold">正确</div>
        <div class="dot1"></div>
        <div style="margin-right: 0.4rem;font-weight: bold">错误</div>
        <div class="dot2"></div>
        <div style="font-weight: bold">未答题</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRequest } from "../utils/common";

export default {
  name: "answerAnalysis",
  data(){
    return {
      workTypeId:'',
      difficultyLevel:'',
      questionCount:5,
      list:[],
      curNum:0,
      total:0,
      userInfo:{},
      answers:{},
      dialogVisible:false,
      curType:'',sid:''
    }
  },
  mounted() {
    this.curType=this.$route.query.type || ''
    this.workTypeId=this.$route.query.workTypeId || '';
    this.difficultyLevel=this.$route.query.difficultyLevel || ''
    this.sid=this.$route.query.sid || ''
    this.questionCount=this.$route.query.questionCount || 5
    this.curNum=Number(this.$route.query.curNum || 0)
    this.$scrollTop()
    if(this.curType == 1){
      this.getExams()
    }else if(this.curType == 2){
      this.getSpecial()
    }else{
      this.getList()
    }
    this.getUserInfo()
  },
  methods:{
    setValue(val){
      this.answers[`questionId_${this.list[this.curNum].questionId}`]=val;
      console.log(this.answers,'ssssss')
    },
    async getUserInfo(){
      let {data} = await getRequest('abcode/questionLibrary/queryUserQuestionLibraryInfo',{})
      this.userInfo=data || {};
      this.userInfo.photo=this.userInfo.photo? this.userInfo.photo.replace("/opt/nginx/ymadmin/", this.imgurl) : ""
    },
    async getList(){
      let params = {
        questionCount:this.questionCount
      }
      if(this.workTypeId){
        params.workTypeId=this.workTypeId
      }
      if(this.difficultyLevel){
        params.difficultyLevel=this.difficultyLevel
      }
      let {data} = await getRequest('abcode/questionLibrary/queryRandomQuestionLibrary',params)
      this.list=data && data.items?data.items:[]
      this.total=this.list.length;
      this.answers={}
      for(let i=0;i<this.total;i++){
        this.answers[`questionId_${this.list[i].questionId}`]=''
      }
    },
    async getExams(){
      // let res = await postFormRequest('abcode/questionLibrary/addExamCount/'+this.$route.query.sid,{})
      // console.log(res)
      let {data} = await getRequest('abcode/questionLibrary/queryExamQuestionLibrary/'+this.$route.query.sid,{})
      console.log(data)
      this.list=data && data.items?data.items:[]
      this.total=this.list.length;
    },
    async getSpecial(){
      // let res = await postFormRequest('abcode/questionLibrary/addSpecialCount/'+this.$route.query.sid,{})
      // console.log(res)
      let {data} = await getRequest('abcode/questionLibrary/'+this.$route.query.sid,{})
      console.log(data)
      this.list=data && data.items?data.items:[]
      this.total=this.list.length;
    },
  }
};
</script>

<style scoped>
.jxtop{
  font-size: 0.18rem;
}
.jxtop span{
  font-size: 0.18rem;
  font-weight: bold;
}
.jxtip{
  font-size: 0.16rem;
  margin-top:0.26rem;
  color: #666;
  line-height: 0.23rem;
}
.dtjx{
  width: 0.19rem;
  height:100%;
  border-radius: 10px 0px 0px 10px;
  color: #FFFFFF;
  font-size: 0.18rem;
  flex-wrap: wrap;
  padding: 0 0.15rem;
}
.jxcontent{
  width: calc(100% - 0.6rem);
  padding: 0.2rem;
}
.jiedacon{
  width: calc(100% - 0.22rem);
  margin-top:0.17rem;
  background: url("../assets/shuati/pic_dtjx.png") no-repeat center;
  background-size: 100% 100%;
}
.pandu{
  justify-content: flex-end;
  margin-top: 0.2rem;
}
.dot{
  width: 0.09rem;
  height: 0.09rem;
  border-radius: 50%;
  background: #1386F0;
  margin-right: 0.1rem;
}
.dot1{
  width: 0.09rem;
  height: 0.09rem;
  border-radius: 50%;
  background: #F13D3D;
  margin-right: 0.1rem;
}
.dot2{
  width: 0.08rem;
  height: 0.08rem;
  border-radius: 50%;
  border: 0.01rem solid #1386F0;
  margin-right: 0.1rem;
}
.answernav{
  width: calc(100% - 0.48rem);
  background: #F6F6F6;
  border-radius: 0.1rem;
  margin-top:0.25rem;
  padding: 0.24rem;
}
.aswernav-item{
  width: 0.39rem;
  height: 0.39rem;
  margin:0.3rem 0.15rem 0 0;
  font-size: 0.18rem;
  color: #1386F0;
  border-radius: 50%;
  border:0.01rem solid #1386F0;
}
.aswernav-item1{
  width: 0.4rem;
  height: 0.4rem;
  background: #1386F0;
  margin:0.3rem 0.15rem 0 0;
  font-size: 0.18rem;
  color: #ffffff;
  border-radius: 50%;
}
.aswernav-item2{
  width: 0.4rem;
  height: 0.4rem;
  background: #F13D3D;
  margin:0.3rem 0.15rem 0 0;
  font-size: 0.18rem;
  color: #ffffff;
  border-radius: 50%;
}
.answernav-title{
  font-size: 0.18rem;

}
.answers{
  width: calc(100% - 1rem);
  margin-top:0.37rem
}
.answer1{
  font-size: 0.18rem;
  width: calc(100% - 0.27rem);
}
.qbtns{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top:0.67rem
}
.qbtn1{
  width: 0.98rem;
  height:0.37rem;
  background: #FFFFFF;
  border: 0.01rem solid #1386F0;
  border-radius: 0.18rem;
  color: #1386F0;
  font-size: 0.16rem;
}
.qbtn2{
  width: 0.98rem;
  height:0.37rem;
  background:#1386F0;
  border: 0.01rem solid #1386F0;
  border-radius: 0.18rem;
  color: #fff;
  font-size: 0.16rem;
}
.question{
  font-size: 0.24rem;
  width: 10.5rem;
}
.topitem{
  width: 12rem;
  margin:auto;
  margin-top:0.2rem;

}
.itemleft{
  width: 6.66rem;
  height: 1.4rem;
  background: #FFFFFF;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  padding: 0.25rem 0.2rem 0.2rem 0.2rem;
}
.pname{
  color: #0A0A0A;
  font-size: 0.2rem;
  margin-right: 0.2rem;
}
.ctitle{
 display: flex;
  align-items: center;
  justify-content: space-between;
}
.leftt{
  font-size: 0.2rem;
  width: 80%;
}
.rigtht{
  font-size: 0.2rem;
  color: #666;
}
.labelcon{
  color: #666666;
  font-size: 0.14rem;
  padding: 0.02rem 0.05rem;
  background: #F5F5F5;
  border-radius: 0.04rem;
}
.cbottom{
  width: 100%;

  color: #999;
  margin-top:0.27rem
}
.sttip{
  color: #808080;
  margin-top: 0.16rem;
}
.stype{
  padding: 0.02rem 0.05rem;
  background: #EBEBEB;
  border-radius: 0.11rem;
  color: #1386F0;
  font-size: 0.14rem;
  margin-left: 0.2rem;
}
.vipimg{
  width: 0.2rem !important;
  height: 0.18rem!important;
}
.itemright{
  width: 4.4rem;
  height: 1.4rem;
  background: #FFFFFF;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  padding: 0.25rem 0.2rem 0.2rem 0.2rem;
}
.itemright img{
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
}
.pinfo{
  width: 3.45rem;
}
.questcon{
  width: 11.5rem;
  background: #FFFFFF;
  box-shadow: 0px 0.03rem 0.23rem 0px rgba(0, 0, 0, 0.15);
  border-radius: 0.16rem;
  margin:auto;
  margin-top:0.3rem;
  padding: 0.2rem 0.25rem;
  margin-bottom: 0.3rem;
}
.progress{
  width: 100%;
  height: 0.06rem;
  background: #D8D8D8;
  border-radius: 0.03rem;
}
.yigo{
  width: 0.63rem;
  height: 0.06rem;
  background: linear-gradient(90deg, #60B3FF 0%, #037CEB 100%);
  border-radius:0.03rem 0px 0px 0.03rem;
}
</style>
