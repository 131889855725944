<template>
  <el-dialog
    title="请扫描微信二维码支付"
    style=" display: flex;align-items: center;"
    :visible.sync="dialogVisible"
    width="30%"
    :append-to-body="true"
    :close-on-click-modal="false"
    center>
    <div v-if="isUseCoupon==1">
      <el-input v-model="couponCode" :maxLength="14" :class="{errstyle:showErr}" placeholder="如有优惠券请输入，没有请直接点击下一步"></el-input>
      <div v-if="showErr" style="font-size: 0.14rem;color: red">{{ errTip }}</div>
      <div class="nextbtn"><el-button type="primary" size="small" @click="nextStep">下一步</el-button></div>
    </div>
    <div v-loading="loading" v-show="showEWM" style="margin-top: 0.2rem">
      <div class="sewm" ref="sewm" >
        <div id="qrcode1" ref="qrcode1"></div>
      </div>
      <div class="wechatlogo" >
        <img style=" width: 3rem; " src="../../assets/wechatpaylogo.png">
      </div>
    </div>

    <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
  </span>
  </el-dialog>


</template>

<script>
import QRCode from "qrcodejs2";
import { postFormRequest } from "@/utils/common.js";

export default {
  data() {
    return {
      content: "",
      dialogVisible:true,
      timers:null,
      couponCode:'',
      showErr:false,
      orderNo:'',
      showEWM:true,
      loading:false,
      qrcodes:null,
      errTip:'只能输入14位数字或者字母'
    };
  },
  mounted() {
    if(this.courseId && !this.isUseCoupon){
      this.showEWM=true
      this.getOrder()
    }else if(this.curorderNo){
      this.orderNo = this.curorderNo;
      this.showEWM=true
      this.getPayconfig()
    }else{
      this.showEWM=false
    }
  },

  watch:{
    dialogVisible(val){
      if(!val){
        this.$emit('close',true)
      }
    }
  },
  props: {
    keyWord: {
      type: String,
      value: ""
    },
    courseId:{
      type:[String,Number],
      value:''
    },
    isUseCoupon:{
      type:[String,Number],
      value:0
    },
    curorderNo:{
      type:[String,Number],
      value:''
    }
  },
  methods: {
    nextStep(){
      this.showErr=false
      if(this.couponCode){
        if(/^[\da-z]+$/.test(this.couponCode)){
          this.getOrder()
        }else{
          this.showErr=true
        }
      }else{
        this.getOrder()
      }
    },
    async getOrder(){
      this.loading=true
      let params = {courseId: this.courseId,}
      if(this.couponCode){
        params.couponCode=this.couponCode
      }
      let res = await postFormRequest("abcode/order/createOrder", params);

      if (res.code == 20000) {
        this.orderNo = res.data.orderNo;
        this.showEWM=true
        this.getPayconfig()
      }else{
        this.loading=false
        this.showErr=true
        this.errTip=res.message || '优惠券错误'
      }
    },
    async getPayconfig(){

      let {code,data} = await postFormRequest('abcode/pay/createWeiXinPayCode',{orderNo:this.orderNo})
      this.loading=false
      if(code == 20000){
        this.content=data.code_url

        this.qrcode();
      }
    },
    async getPayStatus(){
      let {code,message} = await postFormRequest('abcode/pay/queryOrderPayStatus',{orderNo:this.orderNo})
     if(code == 20000){
       this.$message.success(message || '支付成功')
       this.dialogVisible=false
     }
    },
    qrcode() {
      //二维码的生成
      let twidth = this.$refs.sewm.offsetWidth-10;
      if(this.qrcodes){
        this.qrcodes=null
        this.$refs.qrcode1.innerHTML=''
      }
      this.qrcodes=new QRCode("qrcode1", {
        width: twidth,
        height: twidth, // 高度
        text: this.content
      });

      if(this.timers){
        clearInterval(this.timers)
        this.timers=null
      }
      this.timers=setInterval(()=>{
        this.getPayStatus()
      },3000)
    }
  },
  beforeDestroy() {
    if(this.timers){
      clearInterval(this.timers)
      this.timers=null
    }
  }
};
</script>
<style scoped>
.errstyle{
  border:0.01rem solid #ee312c !important;
  border-radius: 0.06rem;
}
.nextbtn{
  width: 100%;
  text-align: right;
  margin-top: 0.2rem;
}
.sewm{
  width: 3rem;
  height: 3rem;
  margin:auto;
  border-radius: 0.16rem;
  background: #f4f4f4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wechatlogo{
  width: 3rem;
  margin:auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
