<template>
  <div>
    <div class="right" v-if="!showDetail">
      <div class="mtitle">我的订单</div>
      <div class="nodata" v-if="list.length == 0">暂无数据</div>
      <div class="sitem flexbetween" v-for="item in list" :key="'orderId'+item.orderNo" >
<!--        <div class="leftimg curpoint" @click="curId=item.orderNo;showDetail=true">-->
<!--          <img :src="item.coursePhoto">-->
<!--          &lt;!&ndash;          <div class="bstatus flexcenter">{{ item.status == 1?'已支付':'未支付' }}</div>&ndash;&gt;-->
<!--        </div>-->
        <div class="leftimg curpoint flexcenter" @click="curId=item.orderNo;showDetail=true" >
          <img :src="item.coursePhoto">
          <div class="sprotitle">{{ item.courseName }}</div>
        </div>
        <div class="cinfo">
          <div class="aname" @click="curId=item.orderNo;showDetail=true">{{ item.courseName }}</div>
          <div class="bmnum" @click="curId=item.orderNo;showDetail=true">创建时间：{{ item.createTime }}</div>
          <div class="flexs" style="margin-top: 0.3rem">
            <span class="activity flexcenter">￥{{item.orderPrice}}</span>
            <div class="startlearn flexcenter" v-if="item.status == 1" @click="$router.push('/courseDetail?courseId='+item.courseId)">开始学习</div>

            <div class="ybmed flexcenter" v-else @click="orderNo=item.orderNo;showCode=true">立即支付</div>
            <div class="ybm flexcenter" @click="curId=item.orderNo;showDetail=true">查看订单</div>
          </div>
        </div>
      </div>
      <qrcode-c v-if="showCode" :curorderNo="orderNo" @close="showCode = false;pageNum=1;getList();"></qrcode-c>
      <div class="flexcenter" style="width: 100%;height: 1rem">
        <el-pagination
          background
          layout="prev, pager, next"
          @current-change="(e)=>{pageNum=e;getList()}"
          :total="total">
        </el-pagination>
      </div>

    </div>
    <order-detail v-if="showDetail" :curId="curId"></order-detail>
  </div>

</template>

<script>
import { getRequest } from "@/utils/common.js";
import qrcodeC from '@/components/qrcodeC/index.vue'
import orderDetail from "./orderDetail";

export default {
  name: "myOrder",
  data(){
    return {
      pageNum:1,
      pageSize:10,
      list:[],
      total:0,
      userInfo:{},
      sexImg:'',
      orderNo:'',
      showCode:false,
      showDetail:false,
      curId:''
    }
  },
  mounted() {
    this.getList()
  },
  components:{qrcodeC,orderDetail},
  methods:{
    async getList(){
      this.$scrollTop()
      let {data} = await getRequest(`abcode/user/queryUserOrder/${this.pageNum}/${this.pageSize}`,{})
      this.list=(data && data.rows?data.rows:[]).map(item=>{
        return {...item,coursePhoto: item.coursePhoto
            ? item.coursePhoto.replace("/opt/nginx/ymadmin/", this.imgurl)
            : "",
          orderPrice:item.orderPrice
            ? item.orderPrice.toFixed(2)
            : "",
        }
      });
      this.total = data.total || 0;
    }
  }
};
</script>
<style scoped>
.nodata{
  font-size: 0.18rem;
  width: 100%;
  text-align: center;
  margin-top:0.6rem
}
.startlearn{
  width: 0.8rem;
  height:0.32rem;
  border-radius: 0.15rem;
  background-image: linear-gradient(#08ac62, #4bbc89e8);
  margin-left: 0.2rem;
  color: #fff;
  font-size: 0.13rem;
}
.avatar-con img{
  width: 0.88rem;
  height: 0.88rem;
}
.cinfo{
  width: 6.5rem;
  cursor: pointer;
}
.aname{
  line-height: 0.23rem;
  font-size: 0.18rem;
}
.bmnum{
  color: #999;
  font-size: 0.14rem;
  margin-top: 0.19rem;
  text-align: left;
}
.activity{
  color:red;
  font-size: 0.16rem;
  width: 0.6rem;
}
.ybm{
  color: #1386F0;
  font-size: 0.13rem;
  width: 0.72rem;
  height:0.29rem;
  border-radius: 0.15rem;
  border: 0.01rem solid #1386F0;
  margin-left: 0.2rem;
}
.ybmed{
  color: #fff;
  background-image: linear-gradient(#1386F0, #21A9EF);
  font-size: 0.13rem;
  width: 0.8rem;
  height:0.32rem;
  border-radius: 0.15rem;
  margin-left: 0.2rem;
  cursor: pointer;
}

.right{
  width: 8.9rem;
  min-height: 4.56rem;
  background: #FFFFFF;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  padding: 0.2rem 0.2rem 0 0.2rem;
}
.mtitle{
  font-size: 0.16rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0.1rem;
}
.sitem{
  width: 100%;
  padding: 0.25rem 0;
  border-bottom: 0.01rem solid #dcdcdc;
}
.leftimg{
  width: 2.25rem;
  height: 1.58rem;
  position: relative;
}
.leftimg img{
  width: 2.25rem;
  height: 1.58rem;
  border-radius: 0.16rem;
}
.sprotitle {
  width: 2rem;
  font-size: 0.22rem;
  color: #ffffff;
  line-height: 0.31rem;
  text-align: center;
  font-weight: bold;
  position: absolute;
  left: 50%;
  top:50%;
  transform: translate(-50%,-50%);
}
</style>
