<template>
  <div class="container">
    <div class="headezw"></div>
    <div class="maincontent">
      <div class="tophead">
        <div class="ctitle">
          {{ courseInfo.courseTitle }}
        </div>
        <div class="flexs" style="margin-top: 0.32rem">
          <div class="flexs mlft60">
            <i
              class="iconfont icon-renshu"
              style="font-size: 0.2rem; color: #fff"
            ></i>
            <div class="dtip">学习人数 {{ courseInfo.studyCount }}</div>
          </div>
          <div class="flexs mlft60">
            <i
              class="iconfont icon-shijian"
              style="font-size: 0.2rem; color: #fff"
            ></i>
            <div class="dtip">时长 {{ courseInfo.courseTotalTime }}</div>
          </div>
          <div class="flexs mlft60" v-if="judgeStatus()">
            <div class="dtip">开课时间： {{ courseInfo.onLineTime }}</div>
          </div>
<!--          <div class="price-con flexs" style="margin-right: 0.2rem">-->
<!--            <span style="font-size: 0.18rem; padding-bottom: 0.03rem;" > ￥</span>-->
<!--            <span style="font-size: 0.3rem;">1999</span>-->
<!--            <span-->
<!--              style="-->
<!--                margin-left: 0.1rem;-->
<!--                font-size: 0.2rem;-->
<!--                color: rgb(153, 153, 153);-->
<!--                text-decoration: line-through;-->
<!--              "-->
<!--            >￥1999</span-->
<!--            >-->
<!--          </div>-->
          <!--          <div class="flexs mlft60">-->
          <!--            <i class="iconfont icon-renshu" style="font-size: 0.2rem;color: #fff"></i>-->
          <!--            <div class="dtip">评分 299</div>-->
          <!--          </div>-->
          <div class="startlearn flexcenter colorgreen"
               style="margin-right: 0.1rem;font-weight: bold" v-if="showPrice && courseInfo.isPay==0" @click="goPay">免费</div>
          <div class="startlearn flexcenter colorred"
               style="margin-right: 0.2rem;font-weight: bold" v-if="showPrice && courseInfo.isPay==1" @click="goPay">￥{{courseInfo.discountPrice}}</div>
          <div class="startlearn flexcenter" style="margin-right: 0.1rem;font-weight: bold" @click="goPay">{{judgeStatus() ?'立即报名':'开始学习'}}</div>
        </div>
      </div>
      <div class="ucon flexbetween">
        <img :src="teacher.teacherPhoto" class="avtarimg" />
        <div class="perinfo">
          <div class="flexs">
            <div class="ls flexs">{{ teacher.teacherName }}</div>
            <!--            <img class="mimg" src="../assets/detail/jiangzhang.png" />-->
            <!--            <img class="mimg" src="../assets/detail/guanzhu.png" />-->
          </div>
          <div class="flexs" style="margin-top: 0.2rem">
            <span
              class="smalllabel"
              v-for="(item, index) in teacher.teacherLabels"
              :key="'teac' + index"
              >{{ item }}</span
            >
          </div>
          <div class="intro">
            <div v-html="teacher.shortDesc"></div>
          </div>
        </div>
      </div>
      <div class="snavs flexcenter">
        <div
          class="snav-item curpoint"
          @click="curTab = item.id"
          :class="{ colorblue: item.id == curTab }"
          v-for="item in tabs"
          :key="'tab' + item.id"
          :style="{ marginRight: item.id == 3 ? '0' : '0.55rem' }"
        >
          {{ item.label }}
          <div class="navline" v-if="item.id == curTab"></div>
        </div>
      </div>
      <div v-if="curTab == 1">
        <div class="center1">
          <div class="center1-title">课程简介</div>
          <div class="center1-tip">
            <div v-html="courseInfo.courseDesc"></div>
          </div>
        </div>
      </div>
      <div v-else-if="curTab == 2" style="margin-top: 0.2rem">
        <div class="center2 flexs" v-if="courseInfo.stageCount">
          <div
            v-for="item in courseInfo.stageCount * 2 - 1"
            :key="'stage' + item"
            class="flexs"
          >
            <div
              class="flexcenter"
              :class="{
                'center2-item': (item + 1) / 2 != stageNum,
                'center2-itemed': (item + 1) / 2 == stageNum,
              }"
              v-if="item % 2 == 1"
              @click="stageNum = (item + 1) / 2"
            >
              阶段{{ (item + 1) / 2 }}
            </div>
            <img
              src="../assets/detail/pic_jiantou.png"
              v-else
              class="arrowimg"
            />
          </div>
        </div>

        <div class="center4" v-if="chapterList.length > 0">
          <div
            class="chapter-tem flexbetween"
            :style="{
              'background-size':
                item.videoTime && item.videoTime > 0
                  ? ((item.progressNum || 0) / item.videoTime) * 100 + '% 100%'
                  : '0% 100%',
            }"
            style="margin-top: 0.25rem;align-items: self-start"
            v-for="item in chapterList"
            :key="'chapters' + item.chapterId"
          >
            <i
              class="iconfont icon-bofangqi-bofangxiaodianshi"
              style="font-size: 0.19rem; color: #1386f0;margin-top: 0.04rem"
            ></i>
            <div>
              <div class="item-center flexs">
                <span class="chapter-title"
                  >第{{ item.chapterNo }}集： {{ item.chapterName }}</span
                >
                <span
                  class="chapter-title colorblue curpoint"
                  style="text-decoration: underline"
                  @click="
                    curChapter == item.chapterId
                      ? (curChapter = '')
                      : (curChapter = item.chapterId)
                  "
                  >{{
                    curChapter == item.chapterId ? "隐藏" : "显示"
                  }}剧情介绍</span
                >
                <div
                  class="slearn flexcenter"
                  :style="{
                    background:
                      (item.progressNum || 0) > 0 ? '#01aa60' : '#1386f0',
                  }"
                  @click="goStudy(item)"
                >
                  {{ (item.progressNum || 0) > 0 ? "继续学习" : "开始学习" }}
                </div>
              </div>
              <div
                class="center4-tip animate__animated"
                v-if="curChapter == item.chapterId"
                :class="{
                  shrinkAll: item.chapterDesc && curChapter != item.chapterId,
                  expandAll: item.chapterDesc && curChapter == item.chapterId,
                }"
              >
                <div v-html="item.plotDesc"></div>
              </div>
            </div>
            <div class="flexs" style="width: 2.5rem">
              <div class="chap-time">
                {{ timeFormatter(item.progressNum || 0) }}/{{
                  timeFormatter(item.videoTime || 0)
                }}
              </div>

              <div
                class="learnstatus colorblue curpoint"
                v-if="!item.progressNum"
              >
                未学习
              </div>
              <div
                class="learnstatus colorgreen"
                v-else-if="
                  timeFormatter(item.progressNum || 0) !=
                    timeFormatter(item.videoTime || 0) && item.progressNum > 0
                "
              >
                未学完
              </div>
              <div class="learnstatus colorange" v-else>已学完</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="input-con">
          <div class="flexs">
            <div class="intitle">评论</div>
            <i
              class="iconfont icon-shoucang"
              :style="{ color: item <= star ? '#FE5148' : '#E6E6E6' }"
              style="font-size: 0.16rem; cursor: pointer"
              @click="star = item"
              v-for="item in 5"
              :key="'start' + item"
            ></i>
          </div>
          <el-input
            type="textarea"
            :rows="4"
            class="textarea"
            placeholder="请输入评价..."
            v-model="textarea"
          >
          </el-input>
          <div class="fb-con">
            <div class="fbbtn flexcenter curpoint" @click="goComment">发表</div>
          </div>
        </div>
        <div class="sitems">
          <div
            class="sitem"
            v-for="item in list"
            :key="'comment' + item.courseCommentId"
          >
            <img v-if="item.photo" :src="item.photo" />
            <img v-else src="../assets/favicons.png" />
            <div class="sitem-right">
              <div class="flexbetween">
                <div class="flexs">
                  <div class="user">{{ item.userNick }}</div>
                  <i
                    class="iconfont icon-shoucang"
                    :style="{
                      color: sitem <= item.starCount ? '#FE5148' : '#E6E6E6',
                    }"
                    style="font-size: 0.16rem; cursor: pointer"
                    v-for="sitem in 5"
                    :key="'starts' + sitem"
                  ></i>
                </div>
                <div class="sitem-time">{{ item.createTime }}</div>
              </div>
              <div class="sitem-tip">{{ item.comment }}</div>
              <div
                style="width: 100%; margin-top: 0.15rem"
                v-if="item.courseCommentReplyList.length > 0"
              >
                <div
                  class="sitem"
                  style="padding: 0.1rem 0; border-bottom: none"
                  v-for="sitem in item.courseCommentReplyList"
                  :key="'replay' + sitem.commentReplyId"
                >
                  <img
                    style="width: 0.5rem; height: 0.5rem"
                    src="../assets/favicons.png"
                  />
                  <div class="sitem-right" style="width: 9.8rem">
                    <div class="flexbetween">
                      <div class="flexs">
                        <div class="user">{{ sitem.userName }}</div>
                      </div>
                      <div class="sitem-time">{{ sitem.replyTime }}</div>
                    </div>
                    <div
                      class="sitem-tip"
                      style="margin-top: 0.1rem"
                      v-html="sitem.replyContent"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flexcenter" style="width: 100%; margin: 0.35rem 0">
          <el-pagination
            background
            layout="prev, pager, next"
            :current-page.sync="pageNum"
            @current-change="getList"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>

    <qrcodec
      v-if="showCode"
      :courseId="courseId"
      :isUseCoupon="courseInfo.isUseCoupon"
      @close="
        showCode = false;
        getDetail();
      "
    ></qrcodec>
  </div>
</template>

<script>
import { postRequest, getRequest } from "@/utils/common.js";
import qrcodec from "@/components/qrcodeC/index";

import { getToken } from "@/utils/auth";

export default {
  name: "courseDetail",
  data() {
    return {
      curChapter: "",
      tabs: [
        { id: 1, label: "课程简介" },
        { id: 2, label: "课程大纲" },
        { id: 3, label: "课程评论" },
      ],
      curTab: "",
      pageSize: 10,
      pageNum: 1,
      total: 3,
      textarea: "",
      star: 5,
      courseId: "",
      list: [],
      courseInfo: {},
      teacher: {},
      stageNum: 1,
      chapterList: [],
      showCode: false,
      code_url: "",
      timers: null,
      showPrice:true
    };
  },
  components: { qrcodec },
  mounted() {
    this.courseId = this.$route.query.courseId || "";
    this.getDetail();
  },
  watch: {
    curTab(val) {
      if (val == 3) {
        this.pageNum = 1;
        this.getList();
      } else if (val == 2) {
        this.getChapter();
      }
    },
    stageNum() {
      this.getChapter();
      this.getTeacher();
    },
  },
  methods: {
    showAllTime(stime) {
      let tmptime = Math.ceil(stime || 0);
      let tminute = Math.ceil(tmptime / 60);
      let surtime = Math.ceil(
        tmptime - tminute * 60 > 0 ? tmptime - tminute * 60 : 0
      ).toFixed(0);
      return `${tminute > 9 ? tminute : "0" + tminute}:${
        surtime > 9 ? surtime : "0" + surtime
      }`;
    },
    async goStudy(item) {
      if (!getToken()) {
        this.$store.commit("setShowLogin", true);
        this.$message.warning("开始之前请先进行登录操作");
        return;
      }

      if (this.courseInfo.isPay == 1) {
        let { code } = await getRequest(
          "abcode/course/queryUserIsPayCourseAndPass/" + this.courseId,
          {}
        );
        if (code == 20007) {
          this.showCode = true;
        } else if (code == 20000) {
          this.$router.push(
            "/videoLearning?courseId=" +
              this.courseId +
              "&chapterId=" +
              item.chapterId +
              "&stageNum=" +
              this.stageNum
          );
        }
      } else {
        this.$router.push(
          "/videoLearning?courseId=" +
            this.courseId +
            "&chapterId=" +
            item.chapterId +
            "&stageNum=" +
            this.stageNum
        );
      }
    },
    async goPay() {
      if (!getToken()) {
        this.$store.commit("setShowLogin", true);
        this.$message.warning("开始之前请先进行登录操作");
        return;
      }
      if (this.courseInfo.isPay == 1) {
        let { code } = await getRequest(
          "abcode/course/queryUserIsPayCourseAndPass/" + this.courseId,
          {}
        );
        if (code == 20007) {
          this.showCode = true;
        } else if (code == 20000) {
          if(this.judgeStatus()){
            this.$message.info("请加入课程简介中的会员群，关注开课消息~");
            this.curTab = 1;
          }else{
            this.$message.info("请在课程大纲中选择章节进行学习");
            this.curTab = 2;
          }
        }
      } else {
        if(this.judgeStatus()){
          this.$message.info("请加入课程简介中的会员群，关注开课消息~");
          this.curTab = 1;
        }else{
          this.$message.info("请在课程大纲中选择章节进行学习");
          this.curTab = 2;
        }
      }
    },

    async getChapter() {
      let { data } = await getRequest(
        `abcode/course/unneed/queryCourseChapterList/${this.courseId}/${this.stageNum}`,
        {}
      );
      this.chapterList = data && data.items ? data.items : [];
      this.curChapter =
        this.chapterList.length > 0 ? this.chapterList[0].chapterId : "";
    },
    //获取讲师信息
    async getTeacher() {
      let { data } = await getRequest(
        `abcode/course/unneed/queryCourseTeacherList/${this.courseId}/${this.stageNum}/${this.courseInfo.firstTeacherId}`,
        {}
      );
      let tmpobj = data && data.items ? data.items : {};
      tmpobj.teacherPhoto = tmpobj.teacherPhoto
        ? tmpobj.teacherPhoto.replace("/opt/nginx/ymadmin/", this.imgurl)
        : "";
      tmpobj.teacherLabels = tmpobj.teacherLabel
        ? tmpobj.teacherLabel.split(",")
        : [];
      this.teacher = tmpobj;
    },
    //获取详情
    async getDetail() {
      let { data } = await getRequest(
        `abcode/unneed/course/queryCourseInfo/${this.courseId}`,
        {}
      );
      let info = data && data.items ? data.items : {};
      this.courseInfo = info;
      this.curTab = 1;
      this.courseInfo.coursePhoto = info.coursePhoto
        ? info.coursePhoto.replace("/opt/nginx/ymadmin/", this.imgurl)
        : "";
      if (info && info.isPay == 1) {
        this.judgePay();
      }
      this.getTeacher();
    },
    async judgePay() {
      let { code } = await getRequest(
        `abcode/course/queryUserIsPayCourseAndPass/${this.courseId}`,
        {}
      );
      this.showPrice=code != 20000

    },
    async getList() {
      let { data } = await getRequest(
        `abcode/course/unneed/queryCourseCommentList/${this.courseId}/${this.pageNum}/${this.pageSize}`,
        {}
      );
      this.list = (data && data.items ? data.items.records : []).map((item) => {
        return {
          ...item,
          photo: item.photo
            ? item.photo.replace("/opt/nginx/ymadmin/", this.imgurl)
            : "",
        };
      });
      this.total = data && data.items ? data.items.total : 0;
    },
    async goComment() {
      if(!this.textarea){
        this.$message.warning('请输入课程评论内容')
        return
      }
      this.loading = true;
      let { code, message } = await postRequest("abcode/course/commentCourse", {
        comment: this.textarea,
        courseId: this.courseId,
        starCount: this.star,
      });
      this.loading = false;
      if (code == 20000) {
        this.$message.success(message || "评论成功");
        this.pageNum = 1;
        this.textarea = "";
        this.getList();
      }
      if(code == 20003 || code == 20002){
        this.$store.commit('setShowLogin',true)
      }
    },
    judgeStatus(){
      return new Date(this.courseInfo.onLineTime).getTime()-new Date().getTime() > 0
    }
  },
};
</script>

<style scoped>
.input-con {
  width: 12rem;
  margin: auto;
  margin-top: 0.5rem;
}
.smalllabel {
  padding: 0.02rem 0.05rem;
  border-radius: 0.04rem;
  background: #f4f4f4;
  color: #666;
  font-size: 0.14rem;
  margin-right: 0.2rem;
}
.textarea {
  width: 100%;
  height: 0.92rem;
  margin-top: 0.1rem;
  font-size: 0.16rem;
}
.container {
  width: 100%;
  padding-bottom: 0.9rem;
}
.chap-time {
  color: #010101;
  font-size: 0.14rem;
}
.colorgreen {
  color: #1bcd27;
}
.colorange {
  color: #fe971d;
}
.learnstatus {
  font-size: 0.14rem;
  margin-left: 0.2rem;
  width: 0.6rem;
}
.slearn {
  width: 0.8rem;
  height: 0.28rem;
  background: #1386f0;
  border-radius: 0.14rem;
  color: #ffffff;
  font-size: 0.14rem;
  cursor: pointer;
}
.item-center {
  width: 8rem;
}
.chapter-title {
  font-size: 0.14rem;
  margin-right: 0.3rem;
}
.chapter-tem {
  width: calc(100% - 0.36rem);
  padding: 0.1rem 0.18rem;
  background: url("../assets/detail/bluebac.png") no-repeat left;
  background-size: 50% 100%;
  border-radius: 0.04rem;
}

.center4-tip {
  font-size: 0.14rem;
  color: #808080;
  margin-top: 0.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 8rem;
}
.center4 {
  width: 11.5rem;
  background: #ffffff;
  box-shadow: 0px 0.02rem 0.13rem 0px rgba(0, 0, 0, 0.14);
  border-radius: 0.16rem;
  margin: auto;
  margin-top: 0.24rem;
  padding: 0.2rem 0.25rem;
}

.arrowimg {
  width: 0.22rem;
  height: 0.2rem;
  margin-right: 0.33rem;
}
.center2-item {
  margin-right: 0.33rem;
  width: 1.26rem;
  height: 0.59rem;
  background: #ffb534;
  border-radius: 0.1rem;
  background: url("../assets/detail/pic_wxzh_jieduan.png") no-repeat center;
  background-size: 100% 100%;
  color: #5b5b5b;
  font-size: 0.24rem;
  cursor: pointer;
}
.center2-itemed {
  width: 1.26rem;
  margin-right: 0.33rem;
  height: 0.59rem;
  background: #ffb534;
  border-radius: 0.1rem;
  background: url("../assets/detail/pic_xuanzhong_jieduan.png") no-repeat center;
  background-size: 100% 100%;
  color: #ffffff;
  font-size: 0.24rem;
}
.center2 {
  width: 12rem;
  padding: 0.3rem 0 0.2rem 0;
  margin: auto;
  background: #fbfbfb;
}
.center1-tip {
  margin-top: 0.25rem;
  line-height: 0.19rem;
  font-size: 0.14rem;
  color: #808080;
  text-indent: 0.2rem;
}
.center1-title {
  font-size: 0.24rem;
}
.center1 {
  width: 11.28rem;
  background: #fbfbfb;
  border-radius: 0.1rem 0.16rem 0.1rem 0.1rem;
  margin: auto;
  margin-top: 0.3rem;
  padding: 0.3rem 0.38rem;
}
.snavs {
  width: 100%;
  margin-top: 0.35rem;
}
.snav-item {
  color: #4d4d4d;
  font-size: 0.2rem;
  position: relative;
  padding-bottom: 0.08rem;
}
.navline {
  width: 0.36rem;
  height: 0.03rem;
  background: #1386f0;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -50%);
  border-radius: 0.02rem;
}
.perinfo {
  padding-left: 0.32rem;
  width: 8.58rem;
  border-left: 0.01rem solid #dcdcdc;
  color: #808080;
}
.intro {
  color: #808080;
  font-size: 0.14rem;
  line-height: 0.2rem;
  margin-top: 0.15rem;
  height: 1rem;
  overflow-y: scroll;
}

.ls {
  color: #0a0a0a;
  font-size: 0.2rem;
}
.ucon {
  width: 10.2rem;
  /*height: 0.83rem;*/
  background: #ffffff;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  margin: auto;
  margin-top: 0.3rem;
  padding: 0.3rem 0.9rem;
}
.avtarimg {
  width: 0.74rem;
  height: 0.74rem;
  border-radius: 50%;
}
.mlft60 {
  margin-right: 0.6rem;
}
.dtip {
  font-size: 0.18rem;
  color: #ffffff;
  margin-left: 0.07rem;
}
.startlearn {
  /*width: 1.22rem;*/
  /*height: 0.36rem;*/
  background: #ffffff;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.15rem;
  font-size: 0.18rem;
  padding: 0.05rem 0.1rem;
  color: #1386f0;
  cursor: pointer;
}
.maincontent {
  width: 100%;
  background: url("../assets/detail/pic_xuexi.jpg") no-repeat top;
  background-size: 100%;
  padding-top: 0.8rem;
}
@media screen and (max-width: 1500px) {
  .maincontent {
    padding-top: 0.3rem !important;
  }
  .ucon{
    margin-top: 0.2rem;
  }
  .sitems{
    width:calc(100% - 1.2rem)!important;
    margin: 0.3rem !important;
  }
}
.ctitle {
  font-size: 0.36rem;
  font-weight: bold;
}
.tophead {
  width: 12rem;
  margin: auto;
  color: #fff;
}

.fbbtn {
  width: 1rem;
  height: 0.37rem;
  background: #1386f0;
  border-radius: 0.18rem;
  color: #ffffff;
  font-size: 0.18rem;
}
.fb-con {
  margin-top: 0.4rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.intitle {
  color: #060606;
  font-size: 0.18rem;
  margin-right: 0.1rem;
}
.sitems {
  width: 11.4rem;
  padding: 0 0.3rem;
  background: #ffffff;
  box-shadow: 0px 0.03rem 0.13rem 0px rgba(0, 0, 0, 0.17);
  border-radius: 0.16rem;
  margin: 0.6rem 3.575rem 0.3rem 3.575rem;
}
.sitem {
  padding: 0.2rem 0;
  width: 100%;
  border-bottom: 0.01rem solid #e5e5e5;
  display: flex;
  justify-content: space-between;
}
.sitem-time {
  font-size: 0.16rem;
  color: #666;
}
.user {
  font-size: 0.16rem;
  font-weight: bold;
  margin-right: 0.2rem;
}
.sitem-tip {
  font-size: 0.16rem;
  color: #666;
  line-height: 0.26rem;
  margin-top: 0.23rem;
}
.sitem-right {
  width: 10.6rem;
}
.sitem img {
  width: 0.65rem;
  height: 0.65rem;
  border-radius: 50%;
}
</style>
