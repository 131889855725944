<template>
  <div class="maincontent flexbetween">
    <div class="headezw"></div>
    <div class="maintitla flexcenter">
      <div  :class="{tcon:type == 1,tconts:type == 2}">
        <div class="tcon-title">
          精品<span>{{ type == 1 ? "实战" : "提升" }}</span
          >项目
        </div>
        <div class="tintrduce">
          {{ type == 1 ? "大企业的项目视频，从0到上线" : "专题专项提升学习，不断超越自我" }}
          </div>
      </div>
    </div>
    <div class="options" >
      <div class="flexs">
        <img class="oimg" src="../assets/system/icon_qbkc.png" v-if="type == 1" />
        <img class="oimg" src="../assets/upgrade/icon_up.png" v-else />
        <div class="otip">{{ type == 1 ? "实战" : "提升" }}项目</div>
      </div>
      <labels :preTitle="'岗位'" :defaultOption.sync="workTypeId" :preIndex="'workTypeId'" ref="workTypeId" v-if="posts.length>0" :list="posts"  :showAll="true"
              :attr="'postOrLanguageId'" :showAttrName="'postName'"
              @selectChange="(e)=>{workTypeId=e}"></labels>
      <labels :preTitle="'语言'" :defaultOption.sync="languageId" :preIndex="'languageId'" ref="languageId" v-if="language.length>0" :list="language"  :showAll="true"
              :attr="'postOrLanguageId'" :showAttrName="'languageName'"
              @selectChange="(e)=>{languageId=e}"></labels>
      <labels :preTitle="'类别'" :defaultOption.sync="isPay" ref="isPay" :preIndex="'isPay'" :list="types"  :showAll="true"
              :attr="'id'" :showAttrName="'name'"
              @selectChange="(e)=>{isPay=e}"></labels>

    </div>

    <div class="sitems flexs" style="margin-top: 0.2rem">
<!--    <div class="sitems flexs" :style="{'pointer-events':type == 1? 'none':'','margin-top': '0.2rem'}">-->
      <div class="sproitem curpoint  "
           v-for="(item,index) in list" @mouseenter="curCourse=item.courseId" @mouseleave="curCourse=''"
           :key="'ds' + item.courseId"  :style="{marginRight:index%3==2?'0':'0.225rem'}">
        <div class="sprohead flexcenter animate__animated" :class="{'animate__bigContent':curCourse==item.courseId}"
             :style="{background:'url('+item.coursePhoto+') no-repeat center'}" @click="goDetail(item)">
          <div class="recommand flexcenter" v-if="item.isRecommend">大师推荐</div>
          <div class="sprotitle" @click="goDetail(item)">{{ item.courseName }}</div>
        </div>
<!--        <div class="sprocontent animate__animated" :class="{'animate__upContent':curCourse==item.courseId}"  @click="goDetail(item)"> 暂时屏蔽标题上移效果-->
        <div class="sprocontent"  @click="goDetail(item)">
          <div class="ctitle">{{ item.courseTitle }}</div>
          <div class="flexs" style="flex-wrap: wrap">
            <span class="label" v-for="(sitem,sindex) in item.courseLabels" :key="'label'+sindex">{{ sitem }}</span>
          </div>
          <div class="flexs" style="margin-top: 0.1rem">
            <div class="slabel">预计工时{{ item.courseTotalTime }}</div>
            <div class="slabel" :style="{'border-right':item.isInterview==1?'':'none'}">累计{{ item.studyCount }}人学习</div>
            <div class="slabel colorblue" style="border-right: none" v-if="item.isInterview==1">
              需要面试
            </div>
          </div>
<!--          <div class="flexs" style="margin-top: 0.1rem" v-if="type == 1 && judgeStatus(item)">-->
<!--            <div class="timelabel">开课时间：{{ item.onLineTime }}</div>-->
<!--          </div>-->
          <div class="flexbetween sitembottom" >
            <div class="leftprice colorred">
              <span style="font-size: 0.22rem"  v-if="item.isPay==1">￥{{item.discountPrice}}</span>
              <span style="font-size: 0.2rem" class="colorgreen" v-else>免费&nbsp;</span>
              <span style="margin-left:0.1rem;color: #999;text-decoration: line-through">￥{{item.coursePrice}}</span>
            </div>
<!--            <div class="rightbtn flexcenter" >立即{{type == 1 ? "实战" : "提升" }}</div>-->
            <div class="rightbtn flexcenter" v-if="type == 1 ">立即{{judgeStatus(item) ? "报名" : "实战" }}</div>
            <div class="rightbtn flexcenter" v-else>立即提升</div>
          </div>
        </div>
      </div>
    </div>
    <div class="flexcenter" style="width: 100%;margin:0.35rem 0">
      <el-pagination
        background
        layout="prev, pager, next"
        :page-size="pageSize"
        :current-page.sync="pageNum"
        @current-change="getList"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getRequest } from "@/utils/common.js";
import labels from "../components/toplabels";


export default {
  name: "actualCombat",
  data() {
    return {
      types:[
        {id: '0', name: '免费'},
        {id: '1', name: '付费'}],
      pageSize: 9,
      pageNum: 1,
      courseType: 1,
      list: [],
      total: 0,
      language: [],
      posts: [],
      type: 1,
      languageId: "",
      workTypeId: "",
      isPay: '',
      curCourse:''
    };
  },
  mounted() {
    this.type = this.$route.params.type;
    this.getLanguage();
    this.getAllPost();
    this.getList();
  },
  watch: {
    $route(to){
      this.type = to.params.type;
    },
    languageId() {
      this.pageNum = 1;
      this.getList();
    },
    workTypeId() {
      this.pageNum = 1;
      this.getList();
    },
    isPay() {
      this.pageNum = 1;
      this.getList();
    },
    type(val){
      if(val){
        this.languageId=''
        this.workTypeId=''
        this.isPay=''
        if(this.$refs.languageId){
          this.$refs.languageId.selectOption=''
        }
        if(this.$refs.workTypeId){
          this.$refs.workTypeId.selectOption=''
        }
        if(this.$refs.isPay){
          this.$refs.isPay.selectOption=''
        }
        this.pageNum = 1;
        this.getList();
      }

    }
  },
  components:{labels},
  methods: {
    judgeStatus(obj){
      return new Date(obj.onLineTime).getTime()-new Date().getTime() > 0;
    },
    goDetail(item){
      this.$router.push('/combatDetail?courseId='+item.courseId+'&type='+this.type)
    },
    async getAllPost() {
      let { data } = await getRequest(
        `abcode/unneed/postandlanguage/queryAllPost`,
        {}
      );
      this.posts = data && data.items ? data.items : [];
    },
    async getLanguage() {
      let { data } = await getRequest(
        `abcode/unneed/postandlanguage/queryAllLanguage`,
        {}
      );
      this.language = data && data.items ? data.items : [];
    },
    async getList() {
      this.$scrollTop()
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        courseType: this.type,
      };
      if (this.languageId) {
        params.languageId = this.languageId;
      }
      if (this.workTypeId) {
        params.workTypeId = this.workTypeId;
      }
      params.isPay = this.isPay;
      let { data } = await getRequest(
        `abcode/course/unneed/querySystemCourse`,
        params
      );
      this.list = (data && data.rows ? data.rows : []).map((item) => {
        return {
          ...item,
          coursePhoto: item.coursePhoto
            ? item.coursePhoto.replace("/opt/nginx/ymadmin/", this.imgurl)
            : "",
          courseLabels:item.courseLabel?item.courseLabel.split(','):[]
        };
      });
      this.total =data.total || 0;
    },
  },
};
</script>

<style scoped>
.maintitla {
  width: 100%;
  margin: auto;
  margin: 0.3rem 0 0.28rem 0;
}
.tcon {
  width: 4.06rem;
  background: url("../assets/shizhan/toptitle.png") no-repeat top;
  background-size: 100%;
  text-align: center;
  padding-top: 0.2rem;
}
.tconts {
  width: 4.06rem;
  background: url("../assets/shizhan/tisheng.png") no-repeat top;
  background-size: 100%;
  text-align: center;
  padding-top: 0.2rem;
}
.tintrduce {
  font-size: 0.24rem;
  color: #707070;
  text-align: center;
  margin-top: 0.13rem;
}
.tcon img {
  width: 2.84rem;
  height: 0.46rem;
}
.tcon-title {
  font-size: 0.48rem;
  color: #333;
  font-weight: bold;
  text-align: center;
}
.tcon-title span {
  font-size: 0.48rem;
  color: #1386f0;
  font-weight: bold;
}
.sproitem {
  width: 3.85rem;
  height: 3.8rem;
  background: #ffffff;
  border-radius: 0.16rem;
  margin-top:0.2rem;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}
.sproitem:hover{
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.3);
}
.sitembottom{
  position: absolute;
  width: calc(100% - 0.26rem);
  left:0.13rem;
  bottom: 0.2rem;
}
.rightbtn {
  width: 0.9rem;
  height: 0.31rem;
  background-image: linear-gradient(#1386F0, #21A9EF);
  border-radius: 0.13rem;
  color: #fff;
  font-size: 0.16rem;
}
.disablebtn {
  width: 0.9rem;
  height: 0.3rem;
  background: #608698;
  border-radius: 0.13rem;
  color: #fff;
  font-size: 0.16rem;
}
.leftprice {
  font-size: 0.2rem;
  font-weight: bold;
}
.ctitle {
  font-size: 0.18rem;
}
.label {
  background: #f5f5f5;
  color: #666666;
  font-size: 0.14rem;
  margin-right: 0.15rem;
  padding: 0.02rem 0.03rem;
  border-radius: 0.03rem;
  white-space: nowrap;
  margin-top:0.15rem
}
.slabel {
  color: #999;
  font-size: 0.14rem;
  padding-right: 0.15rem;
  margin-right: 0.15rem;
  border-right: 0.01rem solid #999;
}
.timelabel {
  color: #999;
  font-size: 0.14rem;
  padding-right: 0.15rem;
  margin-right: 0.15rem;
  border-right: 0.01rem;
}
.sprocontent {
  width: calc(100% - 0.26rem);
  /*margin-top:0.15rem;*/
  margin-top:0.1rem;
  padding: 0.13rem;
}
.sprotitle {
  font-size: 0.22rem;
  color: #ffffff;
  line-height: 0.31rem;
  width: 20.13rem;
  text-align: center;
  font-weight: bold;
}
.recommand {
  width: 0.68rem;
  height: 0.25rem;
  color: #ffffff;
  font-size: 0.13rem;
  position: absolute;
  right: 0;
  top: 0.21rem;
  background: url("../assets/shizhan/mark.png") no-repeat center;
  background-size: 100% 100%;
}
.sprohead {
  width: 3.85rem;
  height: 1.58rem;
  position: relative;
}
.sprohead img {
  width: 3.85rem;
  height: 1.58rem;
  border-radius: 0.16rem 0.16rem 0 0;
}

.maincontent {
  width: 12rem;
  margin: auto;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  flex-wrap: wrap;
}

.options {
  width: 100%;
  padding: 0.2rem 0.16rem;

  border-radius: 0.16rem;
  margin-top: 0.1rem;
}
.oimg {
  width: 0.35rem;
  height: 0.35rem;
  margin-right: 0.15rem;
  border-radius: 50%;
}
.otip {
  font-size: 0.24rem;
}
.subtitle {
  padding: 0.03rem 0.05rem;
  font-size: 0.16rem;
  margin-right: 0.1rem;
  font-weight: bold;
}

.optionitem {
  margin-top: 0.15rem;
  margin-left: 0.5rem;
  display: flex;
}
.citem {
  width: calc(100% - 1.5rem);
  flex-wrap: wrap;
}
.sitems {
  width: 12rem;
  flex-wrap: wrap;
}
@-webkit-keyframes bigContent {
  0%{
    transform: scale3d(1,1,1);
  }
  to {
    transform: scale3d(1.1,1.1,1.1);
  }
}
@keyframes bigContent {
  0%{
    transform: scale3d(1,1,1);
  }
  to {
    transform: scale3d(1.1,1.1,1.1);
  }
}
.animate__bigContent{
  -webkit-animation-name: bigContent;
  animation-name: bigContent;
  animation-duration: 1.5s;
}
@-webkit-keyframes upContent {
  0%{
    margin-top: 0.15rem;
  }
  to {
    margin-top: 0.08rem;
  }
}
@keyframes upContent {
  0%{
    margin-top: 0.15rem;
  }
  to {
    margin-top: 0.08rem;
  }
}
.animate__upContent{
  -webkit-animation-name: upContent;
  animation-name: upContent;
  animation-duration: 0.5s;
}
</style>
