<template>
  <div class="right">
    <div class="mtitle">订单详情</div>
    <div class="sitem flexbetween" >
      <div class="leftimg" >
        <img :src="orderInfo.coursePhoto">
        <div class="sprotitle">{{ orderInfo.courseName }}</div>
      </div>
      <div class="cinfo">
        <div class="aname" >{{ orderInfo.courseName }}</div>
        <div class="bmnum" >创建时间：{{ orderInfo.createTime }}</div>
      </div>
    </div>
    <div class="sitem">
      <div class="flexs">
        <div class="slabel">订单号：</div>
        <div class="svalue">{{ orderNo }}</div>
      </div>
    </div>
    <div class="sitem">
      <div class="flexs">
        <div class="slabel">订单金额：</div>
        <div class="svalue">{{ orderInfo.orderPrice }}</div>
      </div>
    </div>
    <div class="sitem">
      <div class="flexs">
        <div class="slabel">是否使用优惠券：</div>
        <div class="svalue">{{ orderInfo.isUseCoupon==1?'是':'否' }}</div>
      </div>
    </div>
    <div class="sitem">
      <div class="flexs">
        <div class="slabel">支付状态：</div>
        <div class="svalue">{{ orderInfo.status==1?'已支付':'未支付' }}</div>
      </div>
    </div>
    <div class="sitem">
      <div class="flexs">
        <div class="slabel">支付方式：</div>
        <div class="svalue">{{ orderInfo.orderPayType==0?'微信':'支付宝' }}</div>
      </div>
    </div>
    <div class="sitem">
      <div class="flexs">
        <div class="slabel">创建时间：</div>
        <div class="svalue">{{ orderInfo.createTime }}</div>
      </div>
    </div>
    <div class="sitem">
      <div class="flexs">
        <div class="slabel">支付时间：</div>
        <div class="svalue">{{ orderInfo.payTime }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { getRequest } from "@/utils/common.js";

export default {
  name: "orderDetail",
  data(){
    return {
      orderNo:'',
      userInfo:{},
      sexImg:'',
      orderInfo:{}
    }
  },
  mounted() {
    this.orderNo=this.curId
    if(this.orderNo){
      this.getDetail()
    }

  },
  props:{
    curId:{
      type:String,Number,
      value:''
    }
  },
  methods:{
    async getUserInfo() {
      let { data } = await getRequest("abcode/user/queryUserInfo", {});
      this.userInfo=data || {}
      this.sexImg=require(`../../assets/${ this.userInfo.sex==1?'icon_nv':'icon_nan'}.png`)
    },
    async getDetail(){
      this.$scrollTop()
      let { data } = await getRequest('abcode/order/queryCourseOrderInfo/'+this.orderNo,{orderNo:this.orderNo})
      this.orderInfo=data && data.items?data.items:{}
      this.orderInfo.coursePhoto= this.orderInfo.coursePhoto?this.orderInfo.coursePhoto.replace("/opt/nginx/ymadmin/", this.imgurl)
        : ""
    }
  }
};
</script>
<style scoped>
.cinfo{
  width: 6.5rem;
  cursor: pointer;
}
.aname{
  line-height: 0.23rem;
  font-size: 0.18rem;
}
.bmnum{
  color: #999;
  font-size: 0.14rem;
  margin-top: 0.19rem;
  text-align: left;
}

.leftimg{
  width: 2.25rem;
  height: 1.58rem;
  position: relative;
}
.leftimg img{
  width: 2.25rem;
  height: 1.58rem;
  border-radius: 0.16rem;
}

.sitem{
  width: 100%;
  padding: 0.15rem 0;
}
.slabel{
  color: #808080;
  width: 1.5rem;
  text-align: right;
  margin-right: 0.1rem;
}
.svalue{
  font-weight: bold;
  margin-right: 0.2rem;
}

.avatar-con img{
  width: 0.88rem;
  height: 0.88rem;
}

ybmed{
  color: #fff;
  background: #1386F0;
  font-size: 0.13rem;
  width: 0.72rem;
  height:0.29rem;
  border-radius: 0.15rem;
  border: 0.01rem solid #1386F0;
  margin-left: 0.2rem;
}

.right{
  width: 8.9rem;
  background: #FFFFFF;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  padding: 0.2rem 0.2rem 0 0.2rem;
}
.mtitle{
  font-size: 0.16rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0.1rem;
}

.sprotitle {
  width: 2rem;
  font-size: 0.22rem;
  color: #ffffff;
  line-height: 0.31rem;
  text-align: center;
  font-weight: bold;
  position: absolute;
  left: 50%;
  top:50%;
  transform: translate(-50%,-50%);
}

</style>
