<template>
  <div class="maincontent flexbetween">
    <div class="headezw"></div>
    <div class="options">
      <div class="flexs">
        <img class="oimg" src="../assets/icon_shizhan.png" />
        <div class="otip">全部资源</div>
      </div>

      <labels :preTitle="'语言'" :preIndex="'languageId'" :defaultOption="''" v-if="language.length>0" :list="language"  :showAll="true"
              :attr="'postOrLanguageId'" :showAttrName="'languageName'"
              @selectChange="(e)=>{languageId=e}"></labels>
    </div>

    <div class="sitems flexs">
      <div class="sitem" v-for="(item,index) in list" :key="'s'+item.fileDataId" :style="{'margin-right':index % 4==3?'0':'0.17rem'}">
       <div class="flexbetween" style="align-items: self-start">
         <img class="limg" :src="item.fileDataImage" />
         <div class="rightc">
           <div class="cname">{{ item.fileDataName }}</div>
           <div class="flexs" style="flex-wrap: wrap" v-if="item.fileDataLabel">
             <span class="role" v-for="(sitem,sindex) in item.fileDataLabels" :key="'slabel'+sindex">{{sitem}}</span>
           </div>
         </div>
       </div>

        <div class="btns flexbetween">
          <div class="flexs stime" style="margin-top:0.1rem;width: 1.8rem">
            <i class="iconfont icon-xiazai"  style="font-size: 0.16rem;color: #999;margin-right: 0.05rem"></i>
            <div> {{ item.downLoadCount }}</div>
<!--            <div>{{ item.viewCount }}</div>-->
          </div>
          <div class="dbtn flexcenter" @click="goDownload(item)">立即下载</div>
        </div>
      </div>
    </div>

    <div class="flexcenter" style="width: 100%;margin:0.35rem 0">
      <el-pagination
          background
          :page-size="pageSize"
          layout="prev, pager, next"
          :current-page.sync="pageNum"
          @current-change="getList"
          :total="total">
      </el-pagination>
    </div>
    <el-dialog
      title=""
      :append-to-body="true"
      :visible.sync="dialogVisible"
      width="30%"
      center>
      <div class="dialog-con flexcenter">
        <img :src="curImg" />
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import { getRequest } from "@/utils/common.js";

import { getToken } from "@/utils/auth";
import labels from "../components/toplabels";

export default {
  name: "download",
  data(){
    return {
      language: [],
      pageNum:1,
      pageSize:12,
      total:0,
      list:[],
      languageId:'',
      curImg:'',
      dialogVisible:false
    }
  },
  mounted() {
    this.getLanguage()
    this.getList()
  },
  watch:{
    languageId(){
      this.pageNum=1;
      this.getList()
    }
  },
  components:{labels},
  methods:{
    goDownload(item){
      if(getToken()){
        this.curImg=item.wxGroupImage;this.dialogVisible=true
      }else{
        this.$store.commit('setShowLogin',true)
      }
    },
    async getLanguage(){
      let { data } = await getRequest(`abcode/unneed/postandlanguage/queryAllLanguage`, {});
      this.language=data && data.items?data.items:[]
    },
    async getList(){
      let params ={
        pageNum:this.pageNum,
        pageSize:this.pageSize
      }
      if(this.languageId){
        params.languageId=this.languageId
      }

      let { data } = await getRequest(`abcode/fileData/unneed/queryFileDataList`, params);
      this.list=(data && data.rows?data.rows :[]).map(item=>{
        return {...item,
          fileDataImage:item.fileDataImage?item.fileDataImage.replace('/opt/nginx/ymadmin/',this.imgurl):'',
          wxGroupImage:item.wxGroupImage?item.wxGroupImage.replace('/opt/nginx/ymadmin/',this.imgurl):'',
          fileDataLabels:item.fileDataLabel?item.fileDataLabel.split(','):[]}
      });
      this.total=data && data.total?data.total:0
    }
  }
};
</script>

<style scoped>


.btns{
  justify-content: right;
  color: #1386F0;
  margin-top:0.1rem;
  width: calc(100% - 0.1rem);
}
.dbtn{
  font-size: 0.16rem;
  width: 0.9rem;
  height: 0.27rem;
  background-image: linear-gradient(#1386F0, #21A9EF);
  border-radius: 0.14rem;
  color: #ffffff;
  margin-left: 0.1rem;
}
.role{
  background: #EBEBEB;
  color: #808080;
  margin-top:0.1rem;
  padding: 0.02rem 0.05rem;
  border-radius: 0.1rem;
  margin-right: 0.1rem;
  font-size: 0.12rem;
}
.cname{
  font-size: 0.16rem;
}
.stime{
  width: 100%;
  font-size: 0.14rem;
  color: #999;
  margin-top: 0.25rem;
}
.maincontent{
  width: 12rem;
  margin:auto;
  margin-top:0.2rem;
  margin-bottom: 0.2rem;
  flex-wrap: wrap;
}

.options{
  width: 100%;
  padding: 0.2rem 0.16rem;
  background: #FFFFFF;
  /*box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);*/
  /*border-radius: 0.16rem;*/
  margin-top:0.1rem
}
.oimg{
  width: 0.35rem;
  height: 0.35rem;
  margin-right: 0.15rem;
  border-radius: 50%;
}
.otip{
  font-size: 0.24rem;
}
.subtitle{
  padding: 0.03rem 0.05rem;
  font-size: 0.16rem;
  margin-right: 0.1rem;
  font-weight: bold;
}

.optionitem{
  margin-top:0.15rem;
  margin-left: 0.5rem;
  display: flex;
}
.sitems{
  width: 100%;
  flex-wrap: wrap;

}
.sitem{
  width: 2.67rem;
  background: #FFFFFF;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  padding: 0.1rem;
  margin-top:0.2rem;
  cursor: pointer;
  height: 1.5rem;
  margin-right: 0.44rem;
}
.sitem:hover{
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.3);
}
.limg{
  width: 1.03rem;
  height: 1.03rem;
  border-radius: 0.16rem;
}
.rightc{
  width: 1.43rem;
}
.dialog-con img{
  width: 3rem;
  height: 3rem;
  border-radius: 0.16rem;
}
</style>
