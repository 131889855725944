<template>
  <div class="right" v-loading="loading">
    <div class="mtitle">个人信息</div>
    <el-upload
      class="avatar-uploader"
      :action="baseurl"
      :headers="headers"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
    >
      <div class="avatar-cons" @mouseenter="showUpdate=true" @mouseleave="showUpdate=false">
        <img class="avatar" v-if="photo" :src="photo" />
        <img class="avatar" v-else src="../../assets/favicons.png" />
        <div class="update flexcenter" v-if="showUpdate">修改头像</div>
      </div>
    </el-upload>

    <el-form
      ref="form"
      :model="form"
      label-width="1rem"
      style="margin-top: 0.5rem; width: 5rem"
    >
      <el-form-item label="昵称">
        <el-input v-model="form.userNick" :maxlength="10" placeholder="请输入昵称,最多10个字符"></el-input>
      </el-form-item>
      <el-form-item label="真实姓名">
        <el-input
          :maxlength="20"
          v-model="form.userName"
          placeholder="请输入真实姓名,最多20个字符"
        ></el-input>
      </el-form-item>
      <el-form-item label="座右铭">
        <el-input v-model="form.motto" :maxlength="50" placeholder="请输入座右铭"></el-input>
      </el-form-item>
      <el-form-item label="手机号"> {{ userInfo.phone }} </el-form-item>
      <el-form-item label="唯一编码"> {{ userInfo.eduUserId }} </el-form-item>
      <el-form-item label="性别">
        <el-radio v-model="form.sex" :label="0">男</el-radio>
        <el-radio v-model="form.sex" :label="1">女</el-radio>
      </el-form-item>
      <el-form-item label="出生日期">
        <el-date-picker
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择出生日期"
          v-model="form.birthDate"
          style="width: 100%"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="籍贯">
        <el-input v-model="form.homeTown" placeholder="请输入籍贯"></el-input>
      </el-form-item>

      <el-form-item label="省">
        <el-select v-model="province" placeholder="请选择">
          <el-option
            v-for="item in provinces"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="市">
        <el-select v-model="city" placeholder="请选择">
          <el-option
            v-for="item in citydata"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="区">
        <el-select v-model="district" placeholder="请选择">
          <el-option
            v-for="item in areadata"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="详细地址">
        <el-input v-model="form.address" :maxlength="100" placeholder="请输入街道小区楼号"></el-input>
        <!--          <el-input type="textarea" v-model="form.address"></el-input>-->
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getRequest, postFormRequest } from "@/utils/common.js";
import res from "@/config/index";
import { getToken } from "@/utils/auth";
import citydata from "@/data/city";
import areadata from '@/data/area'
import provincedata from "@/data/province";


export default {
  name: "personInfo",
  data() {
    return {
      loading: false,
      form: {
        userName: "",
        userNick: "",
        sex: 0,
        motto:'',
        birthDate: "",
        homeTown: "",
        address: "",
        province: "",
        city: "",
        district: "",

      },
      photo:'',
      showUpdate:false,
      province: "",
      city: "",
      district: "",
      userInfo: {},
      baseurl: res.baseurl + "abcode/user/uploadUserPhoto",
      headers: {},
      provinces: provincedata,
      areadata: [],
      citydata: [],
      sexImg:''
    };
  },
  components:{},
  mounted() {
    this.getUserInfo();
    this.headers = {
      token: getToken(),
    };

  },
  watch: {
    province(val) {
      this.citydata = citydata[val];
      this.city = citydata[val][0].code;

    },
    city(val){
      this.areadata = areadata[val];
      this.district=areadata[val][0].code;
    }
  },
  methods: {
    async submit() {

      if(this.province){
        let tval = '';
        this.provinces.forEach(item=>{
          if(item.code == this.province){
            tval=item.name
          }
        })
        this.form.province=tval
      }
      if(this.city){
        let tval = '';
        this.citydata.forEach(item=>{
          if(item.code == this.city){
            tval=item.name
          }
        })
        this.form.city=tval
      }
      if(this.district){
        let tval = '';
        this.areadata.forEach(item=>{
          if(item.code == this.district){
            tval=item.name
          }
        })
        this.form.district=tval
      }
      if(!this.form.userNick){
        this.$message.warning('昵称不能为空！')
        return
      }
      this.loading = true;
      let { code,message } = await postFormRequest("abcode/user/updateUserInfo", { ...this.form,photo:this.photo });
      this.loading = false;
      if(code == 20000){
        this.$message.success(message || '修改成功！')
        this.$store.commit('setRefreshUserInfo',true)
      }
    },
    handleAvatarSuccess({ data }) {
      this.photo = data.path
        ? data.path.replace("/opt/nginx/ymadmin/", this.imgurl)
        : "";
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是JPG格式 或者PNG格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    async getUserInfo() {
      this.$scrollTop()
      let { data } = await getRequest("abcode/user/queryUserInfo", {});
      this.userInfo = data || {};
      this.form = {
        userName: this.userInfo.userName || "",
        userNick: this.userInfo.userNick || "",
        sex: this.userInfo.sex || 0,
        birthDate: this.userInfo.birthDate || "",
        homeTown: this.userInfo.homeTown || "",
        address: this.userInfo.address || "",
        motto:this.userInfo.motto,
        province:this.userInfo.province,
        city:this.userInfo.city,
        district:this.userInfo.district
      };
      this.setCitys()
      this.photo=this.userInfo.photo;
      this.sexImg=require(`../../assets/${ this.userInfo.sex==1?'icon_nv':'icon_nan'}.png`)
    },
    setCitys(){
      let {province,city,district} = this.userInfo
      if(province){
        this.provinces.forEach(item=>{
          if(province==item.name){
            this.province=item.code
          }
        })
        if(this.province){
          this.citydata=citydata[this.province]
        }

      }
      if(city){
        this.citydata.forEach(item=>{
          if(city==item.name){
            this.city=item.code
          }
        })
        if(this.city){
          this.areadata = areadata[this.city];
        }

      }
      if(district){
        this.areadata.forEach(item=>{
          if(district==item.name){
            this.district=item.code
          }
        })
      }
    }
  },
};
</script>
<style scoped>

.avatar-cons {
  width: 0.88rem;
  height: 0.88rem;
  border-radius: 50%;
  position: relative;
  margin-left: 0.4rem;
  margin-top: 0.35rem;
  overflow: hidden;
}

.avatar {
  width: 0.88rem;
  height: 0.88rem;
  border-radius: 50%;
}
.update {
  width: 0.88rem;
  height: 0.88rem;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  font-size: 0.12rem;
  position: absolute;
  left: 0;
  top: 0;
  font-weight: bold;
}

.avatar-con img {
  width: 0.88rem;
  height: 0.88rem;
}



.right {
  width: 8.9rem;
  background: #ffffff;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  padding: 0.2rem 0.2rem 0 0.2rem;
}
.mtitle {
  font-size: 0.16rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0.1rem;
}
</style>
