<template>
  <div class="maincontent flexbetween">
    <div class="headezw"></div>
    <div class="options">
      <div class="flexs">
        <img class="oimg" src="../assets/blog/blog.png" />
        <div class="otip">全部博文</div>
      </div>
      <labels :preTitle="'文章分类'" :preIndex="'articleType'" :defaultOption="''" v-if="charterTypes.length>0" :list="charterTypes"  :showAll="true"
              :attr="'id'" :showAttrName="'name'"
              @selectChange="(e)=>{articleType=e}"></labels>
      <labels :preTitle="'语言'" :preIndex="'articleType'" :defaultOption="''" v-if="language.length>0" :list="language"  :showAll="true"
              :attr="'postOrLanguageId'" :showAttrName="'languageName'"
              @selectChange="(e)=>{languageId=e}"></labels>
    </div>

    <div
      class="sitem flexbetween curpoint"
      v-for="item in list"
      :key="'courseId' + item.articleId"
      @click="gourl(item)"
    >
     <img class="rightimg" v-if="item.isRecommend==1" src="../assets/recomment.png">
      <img class="rightimg" v-if="item.isTop" src="../assets/zhiding.png">
      <div class="leftimg">
        <img :src="item.articleCover" />
      </div>
      <div class="cinfo">
        <div class="aname">{{ item.articleName }}</div>
        <div class="bmnum" style="margin-top:0.1rem">
          <span v-for="(sitem,index) in item.articleLabels" :key="'slabel'+index">{{ sitem }}</span>
        </div>
        <div class="flexbetween">
          <div class="bmnum" style="margin-top:0.1rem;margin-right:0.1rem">
            <span>作者：{{item.articleAuthor}}</span>
            {{ item.articleSourceDesc }}
          </div>
        </div>

        <div class="flexbetween">

          <div class="flexs" style="margin-top: 0.1rem">
            <div class="stime flexcenter" >
              {{ item.sendTime }}
            </div>
            <div class="ybm"  style="margin-left: 0.8rem" >
              所属语言：{{item.languageName}}
            </div>
            <div class="ybm" style="margin-left: 0.3rem">
              类别：{{showText(item.articleType)}}
            </div>

            <div class="ybm" style="margin-left: 0.3rem">
              <i
                class="iconfont icon-chakan"
                style="font-size: 0.2rem; color: #999; margin-right: 0.05rem"
              ></i>
              {{ item.readCount }}
            </div>
<!--            <div class="ybm" >-->
<!--              作者：{{item.articleAuthor}}-->
<!--            </div>-->


          </div>

        </div>
      </div>
    </div>


    <div class="flexcenter" style="width: 100%;margin:0.35rem 0">
      <el-pagination
          background
          :page-size="pageSize"
          layout="prev, pager, next"
          :current-page.sync="pageNum"
          @current-change="getList"
          :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getRequest,postRequest } from "@/utils/common.js";
import labels from "../components/toplabels";

export default {
  name: "brushBo",
  data() {
    return {
      pageSize: 10,
      pageNum: 1,
      courseType: 1,
      list: [],
      total: 0,
      language: [],
      charterTypes: [
        { id: 1, name: "设计文章" },
        { id: 2, name: "开发文章" },
        { id: 3, name: "数据库文章" },
        { id: 4, name: "服务文章" },
        { id: 5, name: "测试文章" },
        { id: 6, name: "网络文章" },
        { id: 7, name: "产品文章" },
        { id: 8, name: "运维文章" },
        { id: 9, name: "其它" },
      ],
      articleType: "",
      languageId: "",
    };
  },
  components:{labels},
  mounted() {
    this.courseType = this.$route.query.courseType || 1;
    this.getLanguage();
    this.getList();
  },
  watch: {
    articleType() {
      this.pageNum = 1;
      this.getList();
    },
    languageId() {
      this.pageNum = 1;
      this.getList();
    },
  },
  methods: {
    showText(val){
      let test = '';
      this.charterTypes.forEach(item=>{
        if(item.id == val){
          test=item.name
        }

      })
      return test
    },
    async gourl(obj) {
      let {code}= await postRequest(`abcode/article/unneed/addArticleReadCount/${obj.articleId}`,{})
      if(code == 20000){
        window.open(obj.articleLink)
      }
    },
    async getLanguage() {
      let { data } = await getRequest(
        `abcode/unneed/postandlanguage/queryAllLanguage`,
        {}
      );
      this.language = data && data.items ? data.items : [];
    },
    async getList() {
      this.$scrollTop()
      let params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };
      if (this.languageId) {
        params.languageId = this.languageId;
      }
      if (this.articleType) {
        params.articleType = this.articleType;
      }
      let { data } = await getRequest(
        `abcode/article/unneed/queryArticleList`,
        params
      );
      this.total =data.total || 0;
      this.list = (data && data.rows ? data.rows.records : []).map((item) => {
        return {
          ...item,
          articleCover: item.articleCover
            ? item.articleCover.replace("/opt/nginx/ymadmin/", this.imgurl)
            : "",
          articleLabels:item.articleLabel?item.articleLabel.split(','):[]
        };
      });
    },
  },
};
</script>

<style scoped>
.bmnum span{
  padding: 0.02rem 0.05rem;
  border-radius: 0.04rem;
  background: #f4f4f4;
  color: #666;
  font-size: 0.14rem;
  margin-right: 0.2rem;
}
.stime {
  width: 1.5rem;
  height: 0.2rem;
  background: #f4f4f4;
  border-radius: 0.04rem;
  color: #666;
  font-size: 0.14rem;
}

.sitem {
  width: 100%;
  padding: 0.2rem;
  border-bottom: 0.01rem solid #dcdcdc;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  position: relative;
}
.sitem:hover {
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.3);
}
.rightimg{
  width: 0.9rem;
  height: 0.9rem;
  position: absolute;
  top:0;
  right: 0;
  transform: rotate(90deg);

  -ms-transform: rotate(90deg);
}
.leftimg {
  width: 2.25rem;
  height: 1.58rem;
  position: relative;
}
.leftimg img {
  width: 2.25rem;
  height: 1.58rem;
}

.maincontent {
  width: 12rem;
  margin: auto;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  flex-wrap: wrap;
}
.ybm{
  width: 1.5rem;
  display: flex;
  align-items: center;
}
.options {
  width: 100%;
  padding: 0.2rem 0.16rem;
  /*background: #ffffff;*/
  /*box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);*/
  border-radius: 0.16rem;
  margin-top: 0.1rem;
}
.oimg {
  width: 0.35rem;
  height: 0.35rem;
  margin-right: 0.15rem;
  border-radius: 50%;
}
.otip {
  font-size: 0.24rem;
}
.subtitle {
  padding: 0.03rem 0.05rem;
  font-size: 0.16rem;
  margin-right: 0.1rem;
  font-weight: bold;
}
.suboption {
  padding: 0.03rem 0.05rem;
  border-radius: 0.05rem;
  margin-right: 0.15rem;
  font-size: 0.16rem;
  margin-bottom: 0.15rem;
  color: #666;
}
.suboptioned {
  background: rgba(19, 134, 240, 0.2);
  color: rgba(19, 134, 240, 1);
  padding: 0.03rem 0.05rem;
  border-radius: 0.05rem;
  margin-right: 0.15rem;
  font-size: 0.16rem;
  margin-bottom: 0.15rem;
}
.optionitem {
  margin-top: 0.15rem;
  margin-left: 0.5rem;
  display: flex;
}

.sitem {
  width: 100%;
  padding: 0.2rem;
  border-bottom: 0.01rem solid #dcdcdc;
  margin-top: 0.2rem;
  position: relative;
}
.leftimg {
  width: 2.25rem;
  height: 1.58rem;
  position: relative;
}
.leftimg img {
  width: 2.25rem;
  height: 1.58rem;
}

.cinfo {
  width: calc(100% - 2.5rem);
}
.aname {
  line-height: 0.23rem;
  font-size: 0.18rem;
}
.bmnum {
  color: #808080;
  font-size: 0.14rem;
  margin-top: 0.05rem;
  text-align: left;
  display: block;
  line-height: 0.27rem;
}
</style>
