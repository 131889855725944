<template>
  <div class="right">
    <div class="mtitle">报名活动</div>
    <div class="nodata" v-if="list.length == 0">暂无数据</div>
    <div class="sitem flexbetween" v-for="item in list" :key="'activityId'+item.activityId" @click="$router.push('/activityDetail?activityId='+item.activityId)">
      <div class="leftimg">
        <img :src="item.activityCover">
<!--        <div class="bstatus flexcenter">已报名</div>-->
      </div>
      <div class="cinfo">
        <div class="aname">{{ item.activityTitle }}</div>
        <div class="bmnum">{{ item.joinCount }}人报名</div>
        <div class="flexs" style="margin-top: 0.15rem">
          <span class="activity flexcenter">活动时间{{item.startTime }}至{{ item.endTime }}</span>

        </div>
        <div class="flexs" style="margin-top: 0.15rem">
          <div class="rightbtn flexcenter curpoint"  style="border:none">{{judgeStatus(item)?'活动中':'已结束'}}</div>
          <div class="ybm flexcenter">已报名</div>
        </div>
      </div>
    </div>

    <div class="flexcenter" style="width: 100%;margin:0.35rem 0">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="(e)=>{pageNum=e;getList()}"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getRequest } from "@/utils/common.js";

export default {
  name: "mycourse",
  data(){
    return {
      pageNum:1,
      pageSize:10,
      list:[],
      total:0,
      userInfo:{},
      sexImg:''
    }
  },
  mounted() {
    this.getList()
  },
  methods:{
    judgeStatus(obj){
      return (new Date(obj.endTime).getTime()-new Date().getTime() > 0) && (obj.joinCount < obj.activityCount)
    },
    async getUserInfo() {
      let { data } = await getRequest("abcode/user/queryUserInfo", {});
      this.userInfo=data || {}
      this.sexImg=require(`../../assets/${ this.userInfo.sex==1?'icon_nv':'icon_nan'}.png`)
    },
    async getList(){
      this.$scrollTop()
      let {data} = await getRequest(`abcode/use/queryUserActivityList/${this.pageNum}/${this.pageSize}`,{})
      this.list=(data && data.items?data.items.records:[]).map(item=>{
        return {...item,activityCover: item.activityCover
            ? item.activityCover.replace("/opt/nginx/ymadmin/", this.imgurl)
            : "",}
      });
      this.total = data && data.items?data.items.total :0
    }
  }
};
</script>
<style scoped>
.rightbtn{
  width: 0.84rem;
  height: 0.27rem;
  background: #FFFFFF;
  border: 0.01rem solid #1386F0;
  border-radius: 0.13rem;
  color: #1386F0;
  font-size: 0.16rem;
}
.nodata{
  font-size: 0.18rem;
  width: 100%;
  text-align: center;
  margin-top:0.6rem
}
.avatar-con img{
  width: 0.88rem;
  height: 0.88rem;
}
.cinfo{
  width: 6.5rem;
  cursor: pointer;
}
.aname{
  line-height: 0.23rem;
  font-size: 0.18rem;
}
.bmnum{
  color: #999;
  font-size: 0.14rem;
  margin-top: 0.15rem;
  text-align: left;
}
.activity{
  padding: 0 0.1rem;
  height: 0.25rem;
  background: linear-gradient(270deg, #F64944 0%, #F68845 100%);
  border-radius: 0.06rem;
  color: #FFFFFF;
  margin-right: 0.3rem;
}
.ybm{
  color: #1386F0;
  font-size: 0.13rem;
  width: 0.72rem;
  height:0.29rem;
  border-radius: 0.15rem;
  border: 0.01rem solid #1386F0;
}

.right{
  width: 8.9rem;
  min-height: 5rem;
  background: #FFFFFF;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  padding: 0.2rem 0.2rem 0 0.2rem;
}
.mtitle{
  font-size: 0.16rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0.1rem;
}
.sitem{
  width: 100%;
  padding: 0.25rem 0;
  border-bottom: 0.01rem solid #dcdcdc;
}
.leftimg{
  width: 2.25rem;
  height: 1.58rem;
  position: relative;
}
.leftimg img{
  width: 2.25rem;
  height: 1.58rem;
  border-radius: 0.16rem;
}
.bstatus{
  position: absolute;
  right: 0.08rem;
  bottom: 0.08rem;
  color: #1386F0;
  border:0.01rem solid #1386F0;
  width: 0.56rem;
  height: 0.23rem;
  border-radius: 0.11rem;
}
</style>
