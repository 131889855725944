<template>
  <div class="maincontent">
    <div class="headezw"></div>
    <div class="pagetitle defaultpoint">个人中心 > {{ title }}</div>
    <info-left @selectMenu="(e)=>{curMenu=e.id;title=e.title}" @clickOrder="resetOrder"></info-left>
    <myorder ref="myorder" v-show="curMenu=='myOrder'"></myorder>
    <baseInfo v-show="curMenu=='info'"></baseInfo>
    <set-account v-show="curMenu=='account'"></set-account>
    <myactivity v-show="curMenu=='myactivity'"></myactivity>
    <mycourse v-show="curMenu=='mycourse'"></mycourse>
    <my-coupon v-show="curMenu=='myCoupon'"></my-coupon>
  </div>
</template>
<script>
import { getRequest } from "@/utils/common.js";
import setAccount from "@/components/personCenter/setAccount";
import infoLeft from '@/components/infoLeft.vue'
import myorder from "@/components/personCenter/myorder";
import baseInfo from "@/components/personCenter/baseInfo";
import myactivity from "@/components/personCenter/myactivity";
import mycourse from "@/components/personCenter/mycourse";
import myCoupon from "@/components/personCenter/myCoupon";

export default {
  name: "myOrder",
  data(){
    return {
      pageNum:1,
      pageSize:10,
      list:[],
      total:0,
      userInfo:{},
      sexImg:'',
      orderNo:'',
      showCode:false,
      curMenu:'info',
      title:'个人信息'
    }
  },
  components:{infoLeft,myorder,baseInfo,setAccount,myactivity,mycourse,myCoupon},
  methods:{
    resetOrder(){
      let curRef= this.$refs.myorder
      if(this.curMenu=='myOrder' && curRef.showDetail){
        curRef.showDetail=false
        curRef.curId=''
      }else{
        this.curMenu='myOrder'
      }
    },
    async getList(){
      this.$scrollTop()
      let {data} = await getRequest(`abcode/user/queryUserOrder/${this.pageNum}/${this.pageSize}`,{})
      this.list=(data && data.rows?data.rows:[]).map(item=>{
        return {...item,coursePhoto: item.coursePhoto
            ? item.coursePhoto.replace("/opt/nginx/ymadmin/", this.imgurl)
            : "",}
      });
      this.total = data.total || 0;
    }
  },
  watch:{
    curMenu(){
      this.$refs.myorder.showDetail=false
      this.$refs.myorder.curId=''
    }
  }
};
</script>
<style scoped>
.nodata{
  font-size: 0.18rem;
  width: 100%;
  text-align: center;
  margin-top:0.6rem
}
.startlearn{
  width: 0.72rem;
  height:0.29rem;
  border-radius: 0.15rem;
  background:  #01aa60 ;
  margin-left: 0.2rem;
  color: #fff;
}
.avatar-con img{
  width: 0.88rem;
  height: 0.88rem;
}
.cinfo{
  width: 6.5rem;
  cursor: pointer;
}
.aname{
  line-height: 0.23rem;
  font-size: 0.18rem;
}
.bmnum{
  color: #999;
  font-size: 0.14rem;
  margin-top: 0.19rem;
  text-align: left;
}
.activity{
  color:red;
  font-size: 0.16rem;
}
.ybm{
  color: #1386F0;
  font-size: 0.13rem;
  width: 0.72rem;
  height:0.29rem;
  border-radius: 0.15rem;
  border: 0.01rem solid #1386F0;
  margin-left: 0.2rem;
}
ybmed{
  color: #fff;
  background: #1386F0;
  font-size: 0.13rem;
  width: 0.72rem;
  height:0.29rem;
  border-radius: 0.15rem;
  border: 0.01rem solid #1386F0;
  margin-left: 0.2rem;
  cursor: pointer;
}
.maincontent{
  width: 12rem;
  margin:auto;
  margin-top:0.2rem;
  margin-bottom: 0.2rem;
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
}
.pagetitle{
  width: 100%;
  padding-bottom: 0.1rem;
  color: #808080;
  text-align: left;
}

.right{
  width: 8.9rem;
  min-height: 4.56rem;
  background: #FFFFFF;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  padding: 0.2rem 0.2rem 0 0.2rem;
}
.mtitle{
  font-size: 0.16rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0.1rem;
}
.sitem{
  width: 100%;
  padding: 0.25rem 0;
  border-bottom: 0.01rem solid #dcdcdc;
}
.leftimg{
  width: 2.25rem;
  height: 1.58rem;
  position: relative;
}
.leftimg img{
  width: 2.25rem;
  height: 1.58rem;
}

</style>
