<template>
  <div style="width: 100%">
    <div class="headezw"></div>
    <div class="allcontent" >
      <div class="maincontent flexbetween">

        <div class="options">
          <div class="flexs">
            <img class="oimg" src="../assets/qa/qa.png" />
            <div class="otip">问答</div>
          </div>
          <labels :preTitle="'分类'" :preIndex="'classId'" :defaultOption="''" v-if="posts.length>0" :list="posts"  :showAll="true"
                  :attr="'postOrLanguageId'" :showAttrName="'postName'"
                  @selectChange="(e)=>{workTypeId=e}"></labels>
          <labels :preTitle="'语言'" :preIndex="'postOrLanguageId'" :defaultOption="''" v-if="language.length>0" :list="language"  :showAll="true"
                  :attr="'postOrLanguageId'" :showAttrName="'languageName'"
                  @selectChange="(e)=>{languageId=e}"></labels>
          <div class="optionitem">
            <quill-editor ref="myTextEditor" style="width: 11rem;height: 5rem"
                          v-model="content"
                          :options="editorOption">
            </quill-editor>
          </div>

          <div class="sbtn-con" >
            <div class="startshua flexcenter curpoint" @click="goQuestion">立即提问</div>
          </div>
        </div>

      </div>
    </div>
    <el-dialog
      title="扫描二维码详情咨询"
      :visible.sync="dialogVisible"
      width="30%"
      :append-to-body="true"
      :modal-append-to-body="true"
      center>
      <div class="colorgreen questTip" >{{tips}}</div>
      <div class="sewm flexcenter">
        <img  style="width: 100%" :src="concatEwm" />
      </div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import { getRequest } from "@/utils/common.js";
import { postFormRequest } from "../utils/common";
import { getToken } from "@/utils/auth";
import labels from "../components/toplabels";
import { Notification} from 'element-ui'

export default {
  name: "questionAnswer",
  components:{labels},
  data() {
    return {
      dialogVisible:false,
      pageSize: 10,
      pageNum: 1,
      courseType: 1,
      list: [],
      total: 0,
      posts: [],
      steps: [
        { id: 0, name: "入门" },
        { id: 1, name: "初级" },
        { id: 2, name: "中级" },
        { id: 3, name: "高级" },
        { id: 4, name: "特难" },
      ],
      tips:'',
      editorOption: {
        placeholder: "请输入问题描述",
        modules: {}
      },
      language: [],
      nums:[
        { id: 5, name: "5" },
        { id: 10, name: "10" },
        { id: 15, name: "15" },
        { id: 20, name: "20" },
        { id: 30, name: "30" },
      ],
      questionCount:5,
      workTypeId:'',
      difficultyLevel:'',
      languageId: "",
      concatEwm: require("../assets/concat.png"),
      content:''
    };
  },
  mounted() {
    this.$scrollTop()
    this.getAllPost();
    // this.getList()
    this.getLanguage();
  },
  methods: {
    //去提问
    async goQuestion(){
      if(!getToken()){
        this.$store.commit('setShowLogin',true)
        return
      }
      let params ={
        content:this.content
      }
      if(this.languageId){
        params.languageId=this.languageId
      }
      if(this.workTypeId){
        params.postId=this.workTypeId
      }
      let {code,message} = await postFormRequest('abcode/question/saveQuestion',params)
     if(code == 20000){
       Notification.success({
         title: message || '操作错误'
       })
       this.dialogVisible=true;
       this.tips=message || '操作错误'
       this.content=''
     }
    },
    async getLanguage() {
      let { data } = await getRequest(
        `abcode/unneed/postandlanguage/queryAllLanguage`,
        {}
      );
      this.language = data && data.items ? data.items : [];
    },
    async getList(){
      let params ={}
      if(this.questionCount){
        params.questionCount=this.questionCount
      }
      if(this.workTypeId){
        params.workTypeId=this.workTypeId
      }
      if(this.difficultyLevel){
        params.difficultyLevel=this.difficultyLevel
      }
      let { data } = await getRequest(
        `abcode/questionLibrary/queryRandomQuestionLibrary`,
        params
      );
      this.list=(data && data.rows?data.rows :[]).map(item=>{
        return {...item,coursePhoto:item.coursePhoto?item.coursePhoto.replace('/opt/nginx/ymadmin/',this.imgurl):''}
      });
      this.total = data && data.total?Math.ceil(data.total/this.pageSize):0
    },
    async getAllPost() {
      let { data } = await getRequest(
        `abcode/unneed/postandlanguage/queryAllPost`,
        {}
      );
      this.posts = data && data.items ? data.items : [];
    },
  },
};
</script>
<style>
.el-notification.right{
  z-index: 10000 !important;
}
</style>
<style scoped>
.questTip{
  font-size: 0.18rem;
  margin-bottom: 0.2rem;
  text-align: center;
}
.sewm{
  width: 3rem;
  height: 3rem;
  margin:auto;
  border-radius: 0.16rem;
  background: #f4f4f4;
}
.sbtn-con{
  display: flex;
  padding: 0.5rem 0 0.3rem 0;
  padding-left: 0.5rem;
  margin-top:0.3rem
}
.startshua{
  width: 1.09rem;
  height: 0.33rem;
  background-image: linear-gradient(#1386F0, #21A9EF);
  border-radius: 0.16rem;
  font-size: 0.16rem;
  color: #fff;

}
.allcontent {
  width: 100%;
  background: url("../assets/shuati/pic_dise.jpg") no-repeat top;
  background-size: 100%;
}

.sprohead img {
  width: 2.86rem;
  height: 1.42rem;
  border-radius: 0.16rem 0.16rem 0 0;
}

.maincontent {
  width: 12rem;
  margin: auto;
  /*margin-top: 0.2rem;*/
  margin-bottom: 0.2rem;
  flex-wrap: wrap;
}

.options {
  width: 100%;
  padding: 0.2rem 0.16rem;
  background: #ffffff;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  margin-top: 0.3rem;
}

.oimg {
  width: 0.35rem;
  height: 0.35rem;
  margin-right: 0.15rem;
  border-radius: 50%;
}
.otip {
  font-size: 0.24rem;
}
.subtitle {
  padding: 0.03rem 0.05rem;
  font-size: 0.16rem;
  margin-right: 0.1rem;
  font-weight: bold;
}

.optionitem {
  margin-top: 0.15rem;
  margin-left: 0.5rem;
  display: flex;
  width: calc(100% - 1rem);
}

.ql-container .ql-editor {
  min-height: 10rem;
  padding-bottom: 1rem;
  max-height: 25rem;
}

</style>
