<template>
  <div style="width: 100%">
    <div class="headezw"></div>
    <div class="topitem flexbetween" v-if="list[curNum]">
      <div class="itemleft">
        <div class="ctitle">
          <div class="leftt">本次刷题报告</div>
        </div>
        <div class="subtitle" v-if="leftInfo.examName">
          {{ leftInfo.examName }}
        </div>
        <div class="flexs" style="margin-top:0.2rem">
          <span class="labelcon" v-if="leftInfo.post">{{ leftInfo.post }}</span>
          <span class="labelcon" >{{ steps[leftInfo.examLevel] }}</span>
        </div>
        <div class="cbottom flexbetween">
          <div class="flexs"><span>正确数量:</span><span style="margin-left: 0.1rem">{{ rightCount }}</span></div>
          <div class="flexs"><span>错误数量:</span><span style="margin-left: 0.1rem">{{ errorCount }}</span></div>
          <div class="flexs"><span>总数量:</span><span style="margin-left: 0.1rem">{{list.length }}</span></div>
          <div class="flexs"><span>正确率:</span><span style="margin-left: 0.1rem">{{ rate }}%</span></div>
        </div>

        <div class="cbottom flexbetween">

          <div class="flexs" v-if="false">
            <div class="flexs" style="margin-right: 0.3rem;font-size: 0.16rem;">
              <i class="iconfont  icon-shoucang"  style="font-size: 0.16rem;color: #999"></i>
              收藏
            </div>
            <div class="flexs" style="font-size: 0.16rem;">
              <i class="iconfont icon-fenxiang1"  style="font-size: 0.16rem;color: #999"></i>
              分享
            </div>
          </div>
        </div>
      </div>
      <div class="itemright flexbetween">
        <img v-if="userInfo.photo" :src="userInfo.photo" />
        <img v-else src="../assets/favicons.png" />
        <div class="pinfo">
          <div class="flexs">
            <div class="pname">{{userInfo.userNick}}</div>
            <img class="vipimg" v-if="userInfo.isVIP" src="../assets/shuati/pic_vip.png"/>
            <div class="stype" v-if="false">IT交流学习</div>
          </div>
          <div class="sttip">{{userInfo.motto}}</div>
          <div class="flexs" style="margin-top: 0.3rem;">
            <div style="margin-right: 0.3rem;">刷题数量:{{ userInfo.sumCount }}</div>
            <div>正确率:{{ (userInfo.avg*100) +'%'}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="questcon">
      <div class="answernav">
        <div class="answernav-title">答题情况</div>
        <div class="flexs" style="flex-wrap: wrap">
          <div v-for="(item,sindex) in list" :key="'snav'+item.questionId"
               :class="{'aswernav-item':!answers[sindex],'aswernav-item1':answers[sindex] && item.answer == answers[sindex],'aswernav-item2':answers[sindex] && item.answer != answers[sindex]}"
               class="flexcenter">{{ sindex+1 }}</div>
        </div>
      </div>
      <div class="pandu flexs">
        <div class="dot" ></div>
        <div style="margin-right: 0.4rem;font-weight: bold" >正确</div>
        <div class="dot1" ></div>
        <div style="margin-right: 0.4rem;font-weight: bold" >错误</div>
        <div class="dot2" ></div>
        <div style="font-weight: bold" >未答题</div>
      </div>
      <div v-for="(item,sindex) in list" :key="'quest'+item.questionId" >
        <div  style="margin-top: 0.3rem;display: flex">
          <div>{{sindex+1}}、</div><div class="question" v-html="item.questionContent"></div>
        </div>
        <div class="answers">
          <div class="flexbetween"  style="margin-top: 0.15rem;cursor: pointer" v-if="item.aoption">
            <i class="iconfont icon-Raidobox-xuanzhong" :style="{color:item.answer.indexOf('A') > -1?'#1386F0':'#e8e8e8'}"
               style="font-size: 0.17rem;"></i>
            <div class="answer1" >A. {{ item.aoption }}</div>
          </div>
          <div class="flexbetween" v-if="item.boption" style="margin-top: 0.15rem;cursor: pointer">
            <i class="iconfont icon-Raidobox-xuanzhong"  :style="{color:item.answer.indexOf('B') > -1?'#1386F0':'#e8e8e8'}"
               style="font-size: 0.17rem;"></i>
            <div class="answer1">B. {{ item.boption }}</div>
          </div>
          <div class="flexbetween" v-if="item.coption" style="margin-top: 0.15rem;cursor: pointer">
            <i class="iconfont icon-Raidobox-xuanzhong"  :style="{color:item.answer.indexOf('C') > -1?'#1386F0':'#e8e8e8'}"
               style="font-size: 0.17rem;"></i>
            <div class="answer1">C. {{ item.coption }}</div>
          </div>
          <div class="flexbetween" v-if="item.doption" style="margin-top: 0.15rem;cursor: pointer">
            <i class="iconfont icon-Raidobox-xuanzhong"  :style="{color:item.answer.indexOf('D') > -1?'#1386F0':'#e8e8e8'}"
               style="font-size: 0.17rem;"></i>
            <div class="answer1">D. {{ item.doption }}</div>
          </div>
          <div class="flexbetween" v-if="item.eoption" style="margin-top: 0.15rem;cursor: pointer">
            <i class="iconfont icon-Raidobox-xuanzhong"  :style="{color:item.answer.indexOf('E') > -1?'#1386F0':'#e8e8e8'}"
               style="font-size: 0.17rem;"></i>
            <div class="answer1">E. {{item.eoption }}</div>
          </div>

        </div>

        <div class="jiedacon flexs">
          <div class="dtjx flexcenter">答题解析</div>
          <div class="jxcontent">
            <div class="flexs jxtop" >
              <span >正确答案：</span><span style="color: #1386F0;margin-right: 0.4rem">{{ item.answer }}</span>
                            <span >您的答案：</span><span style="color: #F13D3D">{{ answers[sindex] || '未作答'}}</span>
            </div>
            <div class="jxtip" v-html="item.questionAnalysis"></div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { getRequest } from "../utils/common";

export default {
  name: "answerSummary",
  data(){
    return {
      workTypeId:'',
      difficultyLevel:'',
      questionCount:5,
      list:[],
      curNum:0,
      total:0,
      userInfo:{},
      answers:[],
      dialogVisible:false,
      curType:'',sid:'',
      leftInfo:{},
      rightCount:0,
      errorCount:0,
      rate:0,
      steps: {
        0:'入门',
        1:'初级',
        2:'中级',
        3:'高级',
        4:'特难'
      }
    }
  },
  mounted() {
    this.curType=this.$route.query.type || ''
    this.workTypeId=this.$route.query.workTypeId || '';
    this.difficultyLevel=this.$route.query.difficultyLevel || ''
    this.sid=this.$route.query.sid || ''
    this.questionCount=this.$route.query.questionCount || 5
    this.answers=JSON.parse(localStorage.getItem('answer'))
    this.list=JSON.parse(localStorage.getItem('questions'))
    this.$scrollTop()
    this.rightCount=this.list.filter((item,sindex)=>{
      return item.answer == this.answers[sindex]
    }).length;
   this.errorCount=this.list.filter((item,sindex)=>{
     return item.answer != this.answers[sindex]
   }).length;

    this.leftInfo=localStorage.getItem("curQuest")?JSON.parse(localStorage.getItem("curQuest")):{};
    this.rate=parseInt((this.rightCount/this.list.length)*100)
    // if(this.curType == 1){
    //   this.getExams()
    // }else if(this.curType == 2){
    //   this.getSpecial()
    // }else{
    //   this.getList()
    // }
    this.getUserInfo()
  },
  methods:{
    async getUserInfo(){
      let {data} = await getRequest('abcode/questionLibrary/queryUserQuestionLibraryInfo',{})
      this.userInfo=data || {};
      this.userInfo.photo=this.userInfo.photo? this.userInfo.photo.replace("/opt/nginx/ymadmin/", this.imgurl) : ""
    },
    async getList(){
      let params = {
        questionCount:this.questionCount
      }
      if(this.workTypeId){
        params.workTypeId=this.workTypeId
      }
      if(this.difficultyLevel){
        params.difficultyLevel=this.difficultyLevel
      }
      let {data} = await getRequest('abcode/questionLibrary/queryRandomQuestionLibrary',params)
      this.list=data && data.items?data.items:[]
      this.total=this.list.length;
    },
    async getExams(){
      // let res = await postFormRequest('abcode/questionLibrary/addExamCount/'+this.$route.query.sid,{})
      // console.log(res)
      let {data} = await getRequest('abcode/questionLibrary/queryExamQuestionLibrary/'+this.$route.query.sid,{})
      console.log(data)
      this.list=data && data.items?data.items:[]
      this.total=this.list.length;
    },
    async getSpecial(){
      // let res = await postFormRequest('abcode/questionLibrary/addSpecialCount/'+this.$route.query.sid,{})
      // console.log(res)
      let {data} = await getRequest('abcode/questionLibrary/'+this.$route.query.sid,{})
      console.log(data)
      this.list=data && data.items?data.items:[]
      this.total=this.list.length;
    },
  }
};
</script>

<style scoped>
.leftt{
  font-size: 0.2rem;
  width: 80%;
  font-weight: bold;
}
.subtitle{
  font-size: 0.16rem;
  margin-top:0.15rem
}
.jxtop{
  font-size: 0.18rem;
}
.jxtop span{
  font-size: 0.18rem;
  font-weight: bold;
}
.jxtip{
  font-size: 0.16rem;
  margin-top:0.26rem;
  color: #666;
  line-height: 0.23rem;
}
.dtjx{
  width: 0.19rem;
height: 100%;
  border-radius: 10px 0px 0px 10px;
  color: #FFFFFF;
  font-size: 0.18rem;
  flex-wrap: wrap;
  padding: 0 0.15rem;
}
.jxcontent{
  width: calc(100% - 0.6rem);
  padding: 0.2rem;
}
.jiedacon{
  width: calc(100% - 0.22rem);
  margin-top:0.17rem;
  background: url("../assets/shuati/pic_dtjx.png") no-repeat center;
  background-size: 100% 100%;
}
.pandu{
  justify-content: flex-end;
  margin-top: 0.2rem;
}
.dot{
  width: 0.09rem;
  height: 0.09rem;
  border-radius: 50%;
  background: #1386F0;
  margin-right: 0.1rem;
}
.dot1{
  width: 0.09rem;
  height: 0.09rem;
  border-radius: 50%;
  background: #F13D3D;
  margin-right: 0.1rem;
}
.dot2{
  width: 0.08rem;
  height: 0.08rem;
  border-radius: 50%;
  border: 0.01rem solid #1386F0;
  margin-right: 0.1rem;
}
.answernav{
  width: calc(100% - 0.48rem);
  background: #F6F6F6;
  border-radius: 0.1rem;
  margin-top:0.25rem;
  padding: 0.24rem;
}
.aswernav-item{
  width: 0.39rem;
  height: 0.39rem;
  margin:0.3rem 0.15rem 0 0;
  font-size: 0.18rem;
  color: #1386F0;
  border-radius: 50%;
  border:0.01rem solid #1386F0;
}
.aswernav-item1{
  width: 0.4rem;
  height: 0.4rem;
  background: #1386F0;
  margin:0.3rem 0.15rem 0 0;
  font-size: 0.18rem;
  color: #ffffff;
  border-radius: 50%;
}
.aswernav-item2{
  width: 0.4rem;
  height: 0.4rem;
  background: #F13D3D;
  margin:0.3rem 0.15rem 0 0;
  font-size: 0.18rem;
  color: #ffffff;
  border-radius: 50%;
}
.answernav-title{
  font-size: 0.18rem;

}
.answers{
  width: calc(100% - 1rem);
  margin-top:0.37rem
}
.answer1{
  font-size: 0.18rem;
  width: calc(100% - 0.27rem);
}
.qbtns{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top:0.67rem
}
.qbtn1{
  width: 0.98rem;
  height:0.37rem;
  background: #FFFFFF;
  border: 0.01rem solid #1386F0;
  border-radius: 0.18rem;
  color: #1386F0;
  font-size: 0.16rem;
}
.qbtn2{
  width: 0.98rem;
  height:0.37rem;
  background:#1386F0;
  border: 0.01rem solid #1386F0;
  border-radius: 0.18rem;
  color: #fff;
  font-size: 0.16rem;
}
.question{
  font-size: 0.24rem;
  width: 10.5rem;
}
.topitem{
  width: 12rem;
  margin:auto;
  margin-top:0.2rem;

}
.itemleft{
  width: 6.66rem;
  height: 1.4rem;
  background: #FFFFFF;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  padding: 0.25rem 0.2rem 0.2rem 0.2rem;
}
.pname{
  color: #0A0A0A;
  font-size: 0.2rem;
  margin-right: 0.2rem;
}
.ctitle{
  font-size: 0.2rem;
}
.labelcon{
  color: #666666;
  font-size: 0.14rem;
  padding: 0.02rem 0.05rem;
  background: #F5F5F5;
  border-radius: 0.04rem;
  margin-right: 0.15rem;
}
.cbottom{
  width: 100%;
  color: #999;
  margin-top:0.2rem
}
.sttip{
  color: #808080;
  margin-top: 0.16rem;
}
.stype{
  padding: 0.02rem 0.05rem;
  background: #EBEBEB;
  border-radius: 0.11rem;
  color: #1386F0;
  font-size: 0.14rem;
  margin-left: 0.2rem;
}
.vipimg{
  width: 0.2rem !important;
  height: 0.18rem!important;
}
.itemright{
  width: 4.4rem;
  height: 1.4rem;
  background: #FFFFFF;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  padding: 0.25rem 0.2rem 0.2rem 0.2rem;
}
.itemright img{
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
}
.pinfo{
  width: 3.45rem;
}
.questcon{
  width: 11.5rem;
  background: #FFFFFF;
  box-shadow: 0px 0.03rem 0.23rem 0px rgba(0, 0, 0, 0.15);
  border-radius: 0.16rem;
  margin:auto;
  margin-top:0.3rem;
  padding: 0.2rem 0.25rem;
  margin-bottom: 0.3rem;
}
.progress{
  width: 100%;
  height: 0.06rem;
  background: #D8D8D8;
  border-radius: 0.03rem;
}
.yigo{
  width: 0.63rem;
  height: 0.06rem;
  background: linear-gradient(90deg, #60B3FF 0%, #037CEB 100%);
  border-radius:0.03rem 0px 0px 0.03rem;
}
</style>
