<template>
<div style="width: 100%" v-loading="loading">
  <div class="headezw"></div>
  <div v-if="list[curNum]">
    <div class="topitem flexbetween">
      <div class="itemleft">
        <div class="ctitle" >
          <div style="width: 6rem;font-size: 0.2rem">{{ curNum+1 }}、{{ list[curNum].questionTitle }}</div>
          <span class="colorblue" style="font-size: 0.2rem">{{questionTypes[list[curNum].questionType]}}</span></div>
        <div class="flexs" style="margin-top:0.2rem">
          <span class="labelcon" v-for="(sitem,sindex) in list[curNum].questionLabels" :key="'sindexxx'+sindex">{{ sitem }}</span>
        </div>
        <div class="cbottom flexbetween">
          <div class="flexs"><span style="margin-right: 0.15rem">{{ steps[list[curNum].difficultyLevel] }}</span>
            {{ list[curNum].doCount || 0 }}人学习</div>
          <div class="flexs" v-if="false">
            <div class="flexs" style="margin-right: 0.3rem;font-size: 0.16rem;">
              <i class="iconfont  icon-shoucang"  style="font-size: 0.16rem;color: #999"></i>
              收藏
            </div>
            <div class="flexs" style="font-size: 0.16rem;">
              <i class="iconfont icon-fenxiang1"  style="font-size: 0.16rem;color: #999"></i>
              分享
            </div>
          </div>
        </div>
      </div>
      <div class="itemright flexbetween">
        <img v-if="userInfo.photo" :src="userInfo.photo" />
        <img v-else src="../assets/favicons.png" />
        <div class="pinfo">
          <div class="flexs">
            <div class="pname">{{userInfo.userNick}}</div>
            <img class="vipimg" v-if="userInfo.isVIP" src="../assets/shuati/pic_vip.png"/>
            <div class="stype" v-if="false">IT交流学习</div>
          </div>
          <div class="sttip" >{{userInfo.motto}}</div>
          <div class="flexs" style="margin-top: 0.3rem;">
            <div style="margin-right: 0.3rem;">刷题数量:{{ userInfo.sumCount }}</div>
            <div>正确率:{{ (userInfo.avg*100) +'%'}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="questcon" v-if="!showAnswer">
      <div class="progress"><div class="yigo" :style="{width:(curNum+1)/(total)*100+'%'}"></div></div>
      <div class="flexbetween" style="margin-top: 0.3rem;align-items: self-start">
        <div class="question" v-html="list[curNum].questionContent"> </div>

        <div class="flexs">
          <i class="iconfont icon-question"  style="font-size: 0.17rem;color: #1386F0"></i>
          <div class="colorblue curpoint" @click="feedVisible=true">试题反馈</div>
        </div>

      </div>
      <div class="answers">
        <div class="flexbetween" @click="setValue('A')" style="margin-top: 0.15rem;cursor: pointer" v-if="list[curNum].aoption">
          <i class="iconfont icon-Raidobox-xuanzhong" :style="{color:answers[curNum].indexOf('A') > -1?'#1386F0':'#e8e8e8'}"
             style="font-size: 0.17rem;"></i>
          <div class="answer1" >A. {{ list[curNum].aoption }}</div>
        </div>
        <div class="flexbetween" v-if="list[curNum].boption" style="margin-top: 0.15rem;cursor: pointer"
             @click="setValue('B')">
          <i class="iconfont icon-Raidobox-xuanzhong"  :style="{color:answers[curNum].indexOf('B')>-1?'#1386F0':'#e8e8e8'}"
             style="font-size: 0.17rem;"></i>
          <div class="answer1">B. {{ list[curNum].boption }}</div>
        </div>
        <div class="flexbetween" v-if="list[curNum].coption" style="margin-top: 0.15rem;cursor: pointer"
             @click="setValue('C')">
          <i class="iconfont icon-Raidobox-xuanzhong"  :style="{color:answers[curNum].indexOf('C')>-1?'#1386F0':'#e8e8e8'}"
             style="font-size: 0.17rem;"></i>
          <div class="answer1">C. {{ list[curNum].coption }}</div>
        </div>
        <div class="flexbetween" v-if="list[curNum].doption" style="margin-top: 0.15rem;cursor: pointer"
             @click="setValue('D')">
          <i class="iconfont icon-Raidobox-xuanzhong"  :style="{color:answers[curNum].indexOf('D')>-1?'#1386F0':'#e8e8e8'}"
             style="font-size: 0.17rem;"></i>
          <div class="answer1">D. {{ list[curNum].doption }}</div>
        </div>
        <div class="flexbetween" v-if="list[curNum].eoption" style="margin-top: 0.15rem;cursor: pointer"
             @click="setValue('E')">
          <i class="iconfont icon-Raidobox-xuanzhong"  :style="{color:answers[curNum].indexOf('E')>-1?'#1386F0':'#e8e8e8'}"
             style="font-size: 0.17rem;"></i>
          <div class="answer1">E. {{list[curNum].eoption }}</div>
        </div>

      </div>
      <div class="qbtns">
        <div class="qbtn1 flexcenter" style="margin-right: 0.2rem" v-if="curNum>0" @click="curNum=curNum-1">上一题</div>
        <div class="qbtn2 flexcenter" style="margin-right: 0.2rem" @click="curNum=curNum+1" v-if="curNum<(total-1)">下一题</div>
        <div class="qbtn2 flexcenter" style="margin-right: 0.2rem" @click="startSave" v-if="curNum==(total-1)">立即提交</div>
        <div class="qbtn2 flexcenter" @click="dialogVisible=true">答案解析</div>
      </div>
    </div>
    <div class="questcon" v-if="list[curNum] && showAnswer">
      <div >
        <div class="flexbetween" style="margin-top: 0.3rem">
          <div class="question" v-html="list[curNum].questionContent"></div>
        </div>
        <div class="answers">
          <div class="flexbetween"  style="margin-top: 0.15rem;cursor: pointer" v-if="list[curNum].aoption">
            <i class="iconfont icon-Raidobox-xuanzhong" :style="{color:list[curNum].answer=='A'?'#1386F0':'#e8e8e8'}"
               style="font-size: 0.17rem;"></i>
            <div class="answer1" >A. {{ list[curNum].aoption }}</div>
          </div>
          <div class="flexbetween" v-if="list[curNum].boption" style="margin-top: 0.15rem;cursor: pointer">
            <i class="iconfont icon-Raidobox-xuanzhong"  :style="{color:list[curNum].answer=='B'?'#1386F0':'#e8e8e8'}"
               style="font-size: 0.17rem;"></i>
            <div class="answer1">B. {{ list[curNum].boption }}</div>
          </div>
          <div class="flexbetween" v-if="list[curNum].coption" style="margin-top: 0.15rem;cursor: pointer">
            <i class="iconfont icon-Raidobox-xuanzhong"  :style="{color:list[curNum].answer=='C'?'#1386F0':'#e8e8e8'}"
               style="font-size: 0.17rem;"></i>
            <div class="answer1">C. {{ list[curNum].coption }}</div>
          </div>
          <div class="flexbetween" v-if="list[curNum].doption" style="margin-top: 0.15rem;cursor: pointer">
            <i class="iconfont icon-Raidobox-xuanzhong"  :style="{color:list[curNum].answer=='D'?'#1386F0':'#e8e8e8'}"
               style="font-size: 0.17rem;"></i>
            <div class="answer1">D. {{ list[curNum].doption }}</div>
          </div>
          <div class="flexbetween" v-if="list[curNum].eoption" style="margin-top: 0.15rem;cursor: pointer">
            <i class="iconfont icon-Raidobox-xuanzhong"  :style="{color:list[curNum].answer=='E'?'#1386F0':'#e8e8e8'}"
               style="font-size: 0.17rem;"></i>
            <div class="answer1">E. {{list[curNum].eoption }}</div>
          </div>
        </div>

        <div class="jiedacon flexs">
          <div class="dtjx flexcenter">答题解析</div>
          <div class="jxcontent">
            <div class="flexs jxtop" >
              <span >正确答案：</span><span style="color: #1386F0;margin-right: 0.4rem">{{ list[curNum].answer }}</span>
            </div>
            <div class="jxtip" v-html="list[curNum].questionAnalysis"></div>
          </div>
        </div>
        <div class="qbtns">
          <div class="qbtn2 flexcenter" style="margin-right: 0.2rem" @click="showAnswer=false;curNum=curNum-1" v-if="curNum != 0">上一题</div>
          <div class="qbtn2 flexcenter" style="margin-right: 0.2rem" @click="showAnswer=false;curNum=curNum+1" v-if="curNum<(total-1)">下一题</div>
          <div class="qbtn2 flexcenter" style="margin-right: 0.2rem" @click="startSave" v-if="curNum==(total-1)">立即提交</div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="noneFlag">
    <div class="maintitle">
          <span style="font-size: 0.36rem">抱歉！暂无相关试题，请</span>
          <span class="colorblue curpoint" style="font-size: 0.36rem" @click="$router.push('/brushQuestions')">
            返回重新选择
          </span>
    </div>
  </div>
  <el-dialog
    title="试题反馈"
    :visible.sync="feedVisible"
    width="30%"
    :append-to-body="true"
    center>
    <el-input
      type="textarea"
      :rows="2"
      placeholder="请输入反馈内容"
      v-model="feedContent">
    </el-input>
    <span slot="footer" class="dialog-footer">
    <el-button @click="feedVisible = false">取 消</el-button>
    <el-button type="primary" @click="goFeed">确 定</el-button>
  </span>
  </el-dialog>
  <el-dialog
    title="是否查看答案解析"
    :append-to-body="true"
    :visible.sync="dialogVisible"
    width="30%"
    center>
    <div style="margin-left: 0.6rem">
    <div>查看解析后：</div>
    <div>&nbsp;&nbsp;&nbsp;· 系统会自动将该题判错</div>
    <div>&nbsp;&nbsp;&nbsp;· 本次刷题，该题不可再次更改选项</div>
    <div>请确认是否查看。</div>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false;showAnswer=true;setAnalysis()">确 定</el-button>
  </span>
  </el-dialog>
</div>
</template>

<script>
import { getRequest,postFormRequest } from "../utils/common";

export default {
  name: "answer",
  data(){
    return {
      showAnswer:false,
      feedVisible:false,
      loading:false,
      noneFlag:false,
      workTypeId:'',
      languageId:'',
      difficultyLevel:'',
      questionCount:5,
      feedContent:'',
      list:[],
      curNum:0,
      total:0,
      userInfo:{},
      answers:[],
      dialogVisible:false,
      curType:'',
      startTime:0,
      sid:'',
      steps: {
        0:'入门',
        1:'初级',
        2:'中级',
        3:'高级',
        4:'特难'
      },
      questionTypes:{
        0:'单选题',
        1:'多选题',
        2:'判断题'
      },
      checkAnalysis:[]
    }
  },

  mounted() {
    this.curType=this.$route.query.type || ''
    this.workTypeId=this.$route.query.workTypeId || '';
    this.languageId=this.$route.query.languageId || '';
    this.difficultyLevel=this.$route.query.difficultyLevel || ''
    this.questionCount=this.$route.query.questionCount || 5;
    this.sid=this.$route.query.sid || ''
    this.startTime=new Date().getTime()
    localStorage.setItem('answer','')
    localStorage.setItem('questions','')
    this.$scrollTop()
    if(this.curType == 1){
      this.getExams()
    }else if(this.curType == 2){
      this.getSpecial()
    }else{
      this.getList()
    }

    this.getUserInfo()
  },

  methods:{
    setAnalysis(){
      if(this.checkAnalysis.indexOf(this.list[this.curNum].questionId)==-1){
        this.checkAnalysis.push(this.list[this.curNum].questionId)
        this.$set(this.answers,this.curNum,'')
      }
    },
    goAnly(){
      let path='/answerAnalysis?'
      if(this.curType){
        path=path+'type='+this.curType
      }
      if(this.workTypeId){
        path=path+'&workTypeId='+this.workTypeId
      }
      if(this.languageId){
        path=path+'&languageId='+this.languageId
      }
      if(this.difficultyLevel){
        path=path+'&difficultyLevel='+this.difficultyLevel
      }
      if(this.questionCount){
        path=path+'&questionCount='+this.questionCount
      }
      if(this.$route.query.sid){
        path=path+'&sid='+this.$route.query.sid
      }
      localStorage.setItem('answer',JSON.stringify(this.answers))
      this.$router.push(path+'&curNum='+this.curNum)
    },
    //去反馈
    async goFeed(){
      if(!this.feedContent){
        this.$message.warning('请输入反馈内容')
      }
      let {code,msg} = await postFormRequest('abcode/feedback/unneed/commitFeedBack',{
        feedContent:'面试题ID：'+this.list[this.curNum].questionId+'-'+this.feedContent,
        feedType:1
      })
      if(code == 20000){
        this.$message.success(msg || '操作成功')
        this.feedVisible=false
      }
    },
    async getExams(){
      let res = await postFormRequest('abcode/questionLibrary/addExamCount/'+this.$route.query.sid,{})
      console.log(res)
      let {data} = await getRequest('abcode/questionLibrary/queryExamQuestionLibrary/'+this.$route.query.sid,{})
      this.list=data && data.items?data.items:[]
      this.total=this.list.length;
      let tanswers = localStorage.getItem('answer')
      if(tanswers){
        this.answers=JSON.parse(tanswers)
      }else{
        this.answers=[]
        for(let i=0;i<this.total;i++){
          this.answers.push('')
        }
      }
    },
    async getSpecial(){
      let res = await postFormRequest('abcode/questionLibrary/addSpecialCount/'+this.$route.query.sid,{})
      console.log(res)
      let {data} = await getRequest('abcode/questionLibrary/'+this.$route.query.sid,{})
      console.log(data)
      this.list=data && data.items?data.items:[]
      this.total=this.list.length;
      let tanswers = localStorage.getItem('answer')
      if(tanswers){
        this.answers=JSON.parse(tanswers)
      }else{
        this.answers=[]
        for(let i=0;i<this.total;i++){
          this.answers.push('')
        }
      }
    },
    startSave(){
      if(this.curType == 1){
        this.saveExam()
      }else if(this.curType == 2){
        this.saveSpecial()
      }else{
        this.save()
      }
    },
    async saveExam(){
      this.loading=true;
      let params ={
        correctCount:this.list.filter((item,index)=>{
          return item.answer == this.answers[index]
        }).length,
        errorCount:this.list.filter((item,index)=>{
          return item.answer != this.answers[index]
        }).length,
        examId:this.sid,
        useTime:new Date().getTime()-this.startTime
      }
      let {code,message} = await postFormRequest('abcode/questionLibrary/commitExamReport',params)
      this.loading=false;
      if(code == 20000){
        this.$message.success(message || '提交成功')
        this.goSummary()
      }
    },
    goSummary(){
      let path='/answerSummary?'
      if(this.curType){
        path=path+'type='+this.curType
      }
      if(this.workTypeId){
        path=path+'&workTypeId='+this.workTypeId
      }
      if(this.languageId){
        path=path+'&languageId='+this.languageId
      }
      if(this.difficultyLevel){
        path=path+'&difficultyLevel='+this.difficultyLevel
      }
      if(this.questionCount){
        path=path+'&questionCount='+this.questionCount
      }
      if(this.$route.query.sid){
        path=path+'&sid='+this.$route.query.sid
      }
      localStorage.setItem('answer',JSON.stringify(this.answers))
      localStorage.setItem('questions',JSON.stringify(this.list))
      this.$router.push(path)
    },
    async saveSpecial(){
      this.loading=true;
      let params ={
        correctCount:this.list.filter((item,index)=>{
          return item.answer == this.answers[index]
        }).length,
        errorCount:this.list.filter((item,index)=>{
          return item.answer != this.answers[index]
        }).length,
        specialId:this.sid,
        useTime:new Date().getTime()-this.startTime
      }
      let {code,message} = await postFormRequest('abcode/questionLibrary/commitSpecialReport',params)
      this.loading=false;
      if(code == 20000){
        this.$message.success(message || '提交成功')
        this.goSummary()
      }
    },
    async save(){
      this.loading=true;
      let params ={
        correctCount:this.list.filter((item,index)=>{
          return item.answer == this.answers[index]
        }).length,
        errorCount:this.list.filter((item,index)=>{
          return item.answer != this.answers[index]
        }).length,
        questionIds:this.list.map(item=>{return item.questionId}).join(','),
        useTime:new Date().getTime()-this.startTime
      }
      let {code,message} = await postFormRequest('abcode/questionLibrary/commitRandomReport',params)
      this.loading=false;
      if(code == 20000){
        this.$message.success(message || '提交成功')
        this.goSummary()
      }

    },
    setValue(val){
      let tval = this.answers[this.curNum] || ''
      if(this.checkAnalysis.indexOf(this.list[this.curNum].questionId) > -1){
        tval=''
      }else{
        if(this.list[this.curNum].questionType==1){
          if(tval){
            let tmparr = tval.split(',')
            if(tmparr.indexOf(val)>-1){
              tmparr.splice(tmparr.indexOf(val),1)
            }else{
              tmparr.push(val)
            }
            tval=tmparr.sort().join(',')
          }else{
            tval=val
          }
        }else{
          tval=val
        }
      }
      this.$set(this.answers,this.curNum,tval)
    },
    async getUserInfo(){
      let {data} = await getRequest('abcode/questionLibrary/queryUserQuestionLibraryInfo',{})
      this.userInfo=data || {};
      this.userInfo.photo=this.userInfo.photo? this.userInfo.photo.replace("/opt/nginx/ymadmin/", this.imgurl) : ""
    },
    async getList(){
      let params = {
        questionCount:this.questionCount
      }
      if(this.workTypeId){
        params.workTypeId=this.workTypeId
      }
      if(this.languageId){
        params.languageId=this.languageId
      }
      if(this.difficultyLevel){
        params.difficultyLevel=this.difficultyLevel
      }
      let {data} = await getRequest('abcode/questionLibrary/queryRandomQuestionLibrary',params)
      this.list=(data && data.items?data.items:[]).map(item=>{
        return {...item,questionLabels:item.questionLabel?item.questionLabel.split(','):[]}
      })
      this.total=this.list.length;
      this.noneFlag=this.total==0;
      let tanswers = localStorage.getItem('answer')
      if(tanswers){
        this.answers=JSON.parse(tanswers)
      }else{
        this.answers=[]
        for(let i=0;i<this.total;i++){
          this.answers.push('')
        }
      }

    }
  }
};
</script>

<style scoped>
.pandu{
  justify-content: flex-end;
  margin-top: 0.2rem;
}
.dot{
  width: 0.09rem;
  height: 0.09rem;
  border-radius: 50%;
  background: #1386F0;
  margin-right: 0.1rem;
}
.dot1{
  width: 0.09rem;
  height: 0.09rem;
  border-radius: 50%;
  background: #F13D3D;
  margin-right: 0.1rem;
}
.dot2{
  width: 0.08rem;
  height: 0.08rem;
  border-radius: 50%;
  border: 0.01rem solid #1386F0;
  margin-right: 0.1rem;
}
.answernav{
  width: calc(100% - 0.48rem);
  background: #F6F6F6;
  border-radius: 0.1rem;
  margin-top:0.25rem;
  padding: 0.24rem;
}
.aswernav-item{
  width: 0.39rem;
  height: 0.39rem;
  margin:0.3rem 0.15rem 0 0;
  font-size: 0.18rem;
  color: #1386F0;
  border-radius: 50%;
  border:0.01rem solid #1386F0;
}
.aswernav-item1{
  width: 0.4rem;
  height: 0.4rem;
  background: #1386F0;
  margin:0.3rem 0.15rem 0 0;
  font-size: 0.18rem;
  color: #ffffff;
  border-radius: 50%;
}
.aswernav-item2{
  width: 0.4rem;
  height: 0.4rem;
  background: #F13D3D;
  margin:0.3rem 0.15rem 0 0;
  font-size: 0.18rem;
  color: #ffffff;
  border-radius: 50%;
}
.answernav-title{
  font-size: 0.18rem;

}
.answers{
  width: calc(100% - 1rem);
  margin-top:0.37rem
}
.answer1{
  font-size: 0.18rem;
  width: calc(100% - 0.27rem);
}
.qbtns{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top:0.67rem;
  cursor: pointer;
}
.qbtn1{
  width: 0.98rem;
  height:0.37rem;
  background: #FFFFFF;
  border: 0.01rem solid #1386F0;
  border-radius: 0.18rem;
  color: #1386F0;
  font-size: 0.16rem;
  cursor: pointer;
}
.qbtn2{
  width: 0.98rem;
  height:0.37rem;
  background:#1386F0;
  border: 0.01rem solid #1386F0;
  border-radius: 0.18rem;
  color: #fff;
  font-size: 0.16rem;
  cursor: pointer;
}
.question{
  font-size: 0.24rem;
  width: 10.5rem;
}
.topitem{
  width: 12rem;
  margin:auto;
  margin-top:0.2rem;

}
.itemleft{
  width: 6.66rem;
  height: 1.4rem;
  background: #FFFFFF;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  padding: 0.25rem 0.2rem 0.2rem 0.2rem;
}
.pname{
  color: #0A0A0A;
  font-size: 0.2rem;
  margin-right: 0.2rem;
}
.ctitle{
  font-size: 0.2rem;
  display: flex;
  justify-content: space-between;
}
.labelcon{
color: #666666;
  font-size: 0.14rem;
  padding: 0.02rem 0.05rem;
  background: #F5F5F5;
  border-radius: 0.04rem;
  margin-right: 0.1rem;
}
.cbottom{
  width: 100%;

  color: #999;
  margin-top:0.27rem
}
.sttip{
  color: #808080;
  margin-top: 0.16rem;
}
.stype{
  padding: 0.02rem 0.05rem;
  background: #EBEBEB;
  border-radius: 0.11rem;
  color: #1386F0;
  font-size: 0.14rem;
  margin-left: 0.2rem;
}
.vipimg{
  width: 0.2rem !important;
  height: 0.18rem!important;
}
.itemright{
  width: 4.4rem;
  height: 1.4rem;
  background: #FFFFFF;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  padding: 0.25rem 0.2rem 0.2rem 0.2rem;
}
.itemright img{
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
}
.pinfo{
width: 3.45rem;
}
.questcon{
  width: 11.5rem;
  background: #FFFFFF;
  box-shadow: 0px 0.03rem 0.23rem 0px rgba(0, 0, 0, 0.15);
  border-radius: 0.16rem;
  margin:auto;
  margin-top:0.3rem;
  padding: 0.2rem 0.25rem;
  margin-bottom: 0.3rem;
}
.progress{
  width: 100%;
  height: 0.06rem;
  background: #D8D8D8;
  border-radius: 0.03rem;
}
.yigo{
  width: 0.63rem;
  height: 0.06rem;
  background: linear-gradient(90deg, #60B3FF 0%, #037CEB 100%);
  border-radius:0.03rem 0px 0px 0.03rem;
}
</style>
<style scoped>
.jxtop{
  font-size: 0.18rem;
}
.jxtop span{
  font-size: 0.18rem;
  font-weight: bold;
}
.jxtip{
  font-size: 0.16rem;
  margin-top:0.26rem;
  color: #666;
  line-height: 0.23rem;
}
.dtjx{
  width: 0.19rem;
  height:100%;
  border-radius: 10px 0px 0px 10px;
  color: #FFFFFF;
  font-size: 0.18rem;
  flex-wrap: wrap;
  padding: 0 0.15rem;
}
.jxcontent{
  width: calc(100% - 0.6rem);
  padding: 0.2rem;
}
.jiedacon{
  width: calc(100% - 0.22rem);
  margin-top:0.17rem;
  background: url("../assets/shuati/pic_dtjx.png") no-repeat center;
  background-size: 100% 100%;
}

.answers{
  width: calc(100% - 1rem);
  margin-top:0.37rem
}
.answer1{
  font-size: 0.18rem;
  width: calc(100% - 0.27rem);
}
.qbtns{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top:0.67rem
}
.qbtn1{
  width: 0.98rem;
  height:0.37rem;
  background: #FFFFFF;
  border: 0.01rem solid #1386F0;
  border-radius: 0.18rem;
  color: #1386F0;
  font-size: 0.16rem;
}

.question{
  font-size: 0.24rem;
  width: 10.5rem;
}
.topitem{
  width: 12rem;
  margin:auto;
  margin-top:0.2rem;

}
.itemleft{
  width: 6.66rem;
  height: 1.4rem;
  background: #FFFFFF;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  padding: 0.25rem 0.2rem 0.2rem 0.2rem;
}
.pname{
  color: #0A0A0A;
  font-size: 0.2rem;
  margin-right: 0.2rem;
}
.ctitle{
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.cbottom{
  width: 100%;

  color: #999;
  margin-top:0.27rem
}
.sttip{
  color: #808080;
  margin-top: 0.16rem;
}
.stype{
  padding: 0.02rem 0.05rem;
  background: #EBEBEB;
  border-radius: 0.11rem;
  color: #1386F0;
  font-size: 0.14rem;
  margin-left: 0.2rem;
}
.vipimg{
  width: 0.2rem !important;
  height: 0.18rem!important;
}
.itemright{
  width: 4.4rem;
  height: 1.4rem;
  background: #FFFFFF;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  padding: 0.25rem 0.2rem 0.2rem 0.2rem;
}
.itemright img{
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
}
.pinfo{
  width: 3.45rem;
}
.questcon{
  width: 11.5rem;
  background: #FFFFFF;
  box-shadow: 0px 0.03rem 0.23rem 0px rgba(0, 0, 0, 0.15);
  border-radius: 0.16rem;
  margin:auto;
  margin-top:0.3rem;
  padding: 0.2rem 0.25rem;
  margin-bottom: 0.3rem;
}
.progress{
  width: 100%;
  height: 0.06rem;
  background: #D8D8D8;
  border-radius: 0.03rem;
}
.yigo{
  width: 0.63rem;
  height: 0.06rem;
  background: linear-gradient(90deg, #60B3FF 0%, #037CEB 100%);
  border-radius:0.03rem 0px 0px 0.03rem;
}

.maintitle {
  width: 100%;
  height:3rem;
  text-align: center;
  margin-top: 2rem;
  font-size: 0.36rem;
  font-weight: bold;
}
</style>
