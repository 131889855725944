import { render, staticRenderFns } from "./activityDetail.vue?vue&type=template&id=13d626fd&scoped=true&"
import script from "./activityDetail.vue?vue&type=script&lang=js&"
export * from "./activityDetail.vue?vue&type=script&lang=js&"
import style0 from "./activityDetail.vue?vue&type=style&index=0&id=13d626fd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13d626fd",
  null
  
)

export default component.exports