<template>
  <div style="width: 100%">
    <div class="headezw"></div>
    <div class="allcontent">

      <div class="leftcontent">
        <div class="lefttitle flexs">
            <img class="rankimg" src="../assets/shuati/rank/rank.png" />
            <div class="ranktip defaultpoint">排行榜</div>
            <div class="ranktype defaultpoint">{{ this.rankIndex == 0 ? "正确率" : "总数量" }}</div>
            <img class="changeimg curpoint"  @click="changeRank()" src="../assets/shuati/rank/change.png" />
        </div>
        <div class="timeitems flexs">
          <div
            class="timeitem curpoint"
            :class="{ colorblack: timeIndex == item.index }"
            v-for="item in timeType"
            :key="'time_' + item.index"
            @click="timeIndex = item.index"
          >
            {{ item.name }}
            <div class="timeline" v-if="timeIndex == item.index"></div>
          </div>
        </div>
        <div class="rankitems flexs">
          <div
            class="rankitem defaultpoint flexbetween"
            v-for="(item,index) in rankList"
            :key="'rank' + item.userId"
          >
            <div v-if="index == 0" class="top1">1</div>
            <div v-else-if="index == 1" class="top2">2</div>
            <div v-else-if="index == 2" class="top3">3</div>
            <div v-else class="topother">{{index+1}}</div>
            <img class="pphoto" v-if="item.photo" :src="item.photo" />
            <img class="pphoto" v-else src="../assets/favicons.png" />
            <img v-if="index == 0" class="topimg" src="../assets/shuati/rank/top1.png">
            <img v-if="index == 1" class="topimg" src="../assets/shuati/rank/top2.png">
            <img v-if="index == 2" class="topimg" src="../assets/shuati/rank/top3.png">
            <div class="pinfo">
              <div class="flexs">
                <img class="levelimg" v-if="item.level == 1" :src="v1">
                <img class="levelimg" v-if="item.level == 2" :src="v2">
                <img class="levelimg" v-if="item.level == 3" :src="v3">
                <img class="levelimg" v-if="item.level == 4" :src="v4">
                <img class="levelimg" v-if="item.level == 5" :src="v5">
                <img class="levelimg" v-if="item.level == 6" :src="v6">
                <img class="levelimg" v-if="item.level == 7" :src="v7">
                <img class="levelimg" v-if="item.level == 8" :src="v8">
                <img class="levelimg" v-if="item.level == 9" :src="v9">
                <div class="pname">{{ item.userNick }}</div>
              </div>
              <div class="flexs" style="margin-top: 0.1rem;">
                <div style="width: 1.15rem">正确率:{{ item.rate + "%" }}</div>
                <div>总数量:{{ item.totalCount }}</div>
              </div>
            </div>
          </div>
        </div>
<!--        <div class="flexs" style=" text-align:center;margin-top: 0.1rem;">*仅展示前20位上榜用户</div>-->
      </div>

      <div class="maincontent flexbetween">
        <div class="options">
          <div class="flexs">
            <img class="oimg" src="../assets/shuati/icon_sjst.png" />
            <div class="otip">随机面试题</div>
          </div>
          <labels
            :preTitle="'岗位'"
            :preIndex="'workTypeId'"
            :defaultOption="''"
            v-if="posts.length > 0"
            :list="posts"
            :showAll="true"
            :attr="'postOrLanguageId'"
            :showAttrName="'postName'"
            @selectChange="
                (e) => {
                  workTypeId = e;
                }
              "
          ></labels>
          <labels
            :preTitle="'语言'"
            :preIndex="'languageId'"
            :defaultOption.sync="languageId"
            ref="languageId"
            v-if="language.length>0"
            :list="language"
            :showAll="true"
            :attr="'postOrLanguageId'"
            :showAttrName="'languageName'"
            @selectChange="
                (e)=>{
                  languageId=e
                }
              "
          ></labels>
          <labels
            :preTitle="'难度'"
            :preIndex="'levelId'"
            :defaultOption="''"
            v-if="steps.length > 0"
            :list="steps"
            :showAll="true"
            :attr="'id'"
            :showAttrName="'name'"
            @selectChange="
                (e) => {
                  difficultyLevel = e;
                }
              "
          ></labels>
          <labels
            :preTitle="'题目数量'"
            :preIndex="'questId'"
            :defaultOption="5"
            v-if="nums.length > 0"
            :list="nums"
            :showAll="false"
            :attr="'id'"
            :showAttrName="'name'"
            @selectChange="
                (e) => {
                  questionCount = e;
                }
              "
          ></labels>
          <div class="sbtn-con" style="border-top: 0.01rem solid #eee">
            <div
              class="startshua flexcenter curpoint"
              @click="
                goAnswer(
                  {
                    examCount: questionCount,
                    examId: '',
                    examLevel: difficultyLevel,
                    examName: '',
                    post:workType,
                    language:language
                  },
                  '/answer?questionCount=' +
                    questionCount +
                    '&workTypeId=' +
                    workTypeId +
                    '&languageId=' +
                    languageId +
                    '&difficultyLevel=' +
                    difficultyLevel
                )
              "
            >
              开始刷题
            </div>
          </div>
        </div>
        <div class="label-con">
          <div class="navitems">
            <div class="nav-item flexs">
              <div
                class="nav-item curpoint"
                :class="{ colorblue: curIndex == item.index }"
                v-for="item in navs"
                :key="'nav_' + item.index"
                @click="curIndex = item.index"
              >
                {{ item.name }}
                <div class="sline" v-if="curIndex == item.index"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="sitems flexs">
          <div
            class="sproitem curpoint"
            v-for="(item,index) in list"
            :style="{'margin-right':index % 4==3?'0':'0.17rem'}"
            :key="'mianshi' + (item.examId || item.specialId)"
            @click="
              goAnswer(
                item,
                '/answer?type=' +
                  curIndex +
                  '&sid=' +
                  (item.examId || item.specialId)
              )
            "
          >
            <div class="sprohead">
              <img :src="item.examCover || item.specialCover" />
<!--              <div class="recommand flexcenter">-->
<!--                {{ item.isPay == 1 ? "付费" : "免费" }}-->
<!--              </div>-->
              <div class="sprotitle">
                {{ item.examName || item.specialName }}
              </div>
            </div>
            <div class="sprocontent">
              <div class="ctitle">{{ item.examName || item.specialName }}</div>
              <div class="flexbetween" style="margin-top: 0.1rem">
                <div class="flexs">
                  <div class="slabel" v-if="curIndex == 1">
                    {{ showLevel(item.examLevel) || "" }}
                  </div>
                  <div class="slabel">
                    {{ item.examCount || item.specialCount || 0 }}人学习
                  </div>
                  <div class="slabel" style="border-right: none">
                    免费
                  </div>
                </div>
              </div>
              <div class="flexbetween" style="margin-top: 0.1rem;justify-content: flex-end">
                <div class="rightbtn flexcenter" >立即刷题</div>
              </div>

            </div>
          </div>
        </div>

        <div class="flexcenter" style="width: 100%;margin:0.35rem 0">
          <el-pagination
              background
              :page-size="pageSize"
              layout="prev, pager, next"
              :current-page.sync="pageNum"
              @current-change="judgeList"
              :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRequest } from "@/utils/common.js";

import { getToken } from "@/utils/auth";
import labels from "../components/toplabels";

export default {
  name: "brushQuestions",
  data() {
    return {
      navs: [
        { name: "大厂面试", index: 1 },
        { name: "专题面试", index: 2 },
      ],
      workType:'',
      language:'',
      curIndex: "",
      pageSize: 12,
      pageNum: 1,
      courseType: 1,
      list: [],
      total: 0,
      posts: [],
      languages: [],
      steps: [
        { id: "0", name: "入门" },
        { id: "1", name: "初级" },
        { id: "2", name: "中级" },
        { id: "3", name: "高级" },
        { id: "4", name: "特难" },
      ],
      nums: [
        { id: 5, name: "5" },
        { id: 10, name: "10" },
        { id: 15, name: "15" },
        { id: 20, name: "20" },
        { id: 30, name: "30" },
      ],
      questionCount: 5,
      workTypeId: "",
      languageId: "",
      difficultyLevel: "",
      specList: [],

      rankList:[],
      rankType: [
        { name: "正确率", index: 0 },
        { name: "总数量", index: 1 },
      ],
      timeType: [
        { name: "今日", index: 0 },
        { name: "历史", index: 1 },
      ],
      rankIndex: 0,
      timeIndex: 0,
      v1:require('../assets/detail/v1.png'),
      v2:require('../assets/detail/v2.png'),
      v3:require('../assets/detail/v3.png'),
      v4:require('../assets/detail/v4.png'),
      v5:require('../assets/detail/v5.png'),
      v6:require('../assets/detail/v6.png'),
      v7:require('../assets/detail/v7.png'),
      v8:require('../assets/detail/v8.png'),
      v9:require('../assets/detail/v9.png'),
    };
  },
  components: { labels },
  mounted() {
    localStorage.setItem("curQuest", "");
    this.getAllPost();
    this.getLanguage();
    this.curIndex = 1;
    localStorage.setItem("answers", "");

    this.rankIndex = 0;
    this.timeIndex = 1;
  },
  watch: {
    workTypeId(val){
      this.posts.forEach(item=>{
        if(val==item.postOrLanguageId){
          this.workType=item.postName
        }
      })
    },
    languageId(val){
      this.languages.forEach(item=>{
        if(val==item.postOrLanguageId){
          this.language=item.languageName
        }
      })
    },
    curIndex(val) {
      this.pageNum = 1;
      if (val == 1) {
        this.getList();
      } else {
        this.getSpecList();
      }
    },
    timeIndex(){
      this.getRank();
    }
  },
  methods: {
    judgeList(){
      if (this.curIndex == 1) {
        this.getList();
      } else {
        this.getSpecList();
      }
    },
    showLevel(level){
      let text = ''
      this.steps.forEach(item=>{
        if(item.id == level){
          text=item.name
        }
      })
      return text
    },
    goAnswer(obj, url) {
      if (getToken()) {
        localStorage.setItem("curQuest", JSON.stringify(obj));
        this.$router.push(url);
      } else {
        this.$store.commit("setShowLogin", true);
      }
    },
    //专题面试
    async getSpecList() {
      let { data } = await getRequest(
        "abcode/unneed/questionLibrarySpecial/querySpecialList",
        { pageNum: this.pageNum, pageSize: this.pageSize }
      );
      this.list = (data && data.rows ? data.rows : []).map((item) => {
        return {
          ...item,
          specialCover: item.specialCover
            ? item.specialCover.replace("/opt/nginx/ymadmin/", this.imgurl)
            : "",
          courseLabels: item.courseLabel ? item.courseLabel.split(",") : [],
        };
      });
      this.total =data.total || 0;
    },
    async getList() {
      this.$scrollTop();
      let { data } = await getRequest(
        `abcode/unneed/questionLibraryExam/queryExamList`,
        { pageNum: this.pageNum, pageSize: this.pageSize }
      );
      this.list = (data && data.rows ? data.rows : []).map((item) => {
        return {
          ...item,
          examCover: item.examCover
            ? item.examCover.replace("/opt/nginx/ymadmin/", this.imgurl)
            : "",
          courseLabels: item.courseLabel ? item.courseLabel.split(",") : [],
        };
      });
      this.total =data.total || 0;
    },
    async getAllPost() {
      let { data } = await getRequest(
        `abcode/unneed/postandlanguage/queryAllPost`,
        {}
      );
      this.posts = data && data.items ? data.items : [];
    },
    async getLanguage() {
      let { data } = await getRequest(
        `abcode/unneed/postandlanguage/queryAllLanguage`,
        {}
      );
      this.language = data && data.items ? data.items : [];
    },
    changeRank(){
      if (this.rankIndex == 0) {
        this.rankIndex = 1;
      } else {
        this.rankIndex = 0;
      }
      this.getRank();
    },
    async getRank() {
      this.$scrollTop();
      let { data } = await getRequest(
        `abcode/unneed/queryQuestionLibraryTodayBank/${this.timeIndex}/${this.rankIndex}`,{}
      );
      this.rankList = (data && data.items ? data.items: []);
    },
  },
};
</script>

<style scoped>
.rightbtn {
  width: 0.9rem;
  height: 0.31rem;
  background-image: linear-gradient(#1386F0, #21A9EF);
  border-radius: 0.13rem;
  color: #fff;
  font-size: 0.16rem;
}
.navitems {
  width: 100%;
  font-size: 0.16rem;
}
.nav-item {
  padding-bottom: 0.1rem;
  position: relative;
  font-weight: bold;
  margin-right: 0.3rem;
  font-size: 0.16rem;
}
.sline {
  width: 0.24rem;
  height: 0.03rem;
  background: #1386f0;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
.sbtn-con {
  display: flex;
  justify-content: flex-end;
  padding: 0.14rem 0;
  border-top: 0.01rem solid #eee;
}
.startshua {
  width: 1.09rem;
  height: 0.33rem;
  background-image: linear-gradient(#1386F0, #21A9EF);
  border-radius: 0.16rem;
  font-size: 0.16rem;
  color: #fff;
  margin-top: 0.15rem;
  margin-right: 0.15rem;
}
.allcontent {
  width: 100%;
  background: url("../assets/shuati/pic_dise.jpg") no-repeat top;
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items:flex-start;
}

.sproitem {
  width: 2.86rem;
  background: #ffffff;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  margin-bottom: 0.3rem;
}
.sproitem:hover{
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.3);
}

.ctitle {
  font-size: 0.16rem;
  height: 0.4rem;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.slabel {
  color: #999;
  font-size: 0.14rem;
  padding-right: 0.15rem;
  margin-right: 0.15rem;
  border-right: 0.01rem solid #999;
}
.sprocontent {
  width: calc(100% - 0.18rem);
  height: 1.15rem;
  padding: 0.09rem;
}
.sprotitle {
  position: absolute;
  transform: translate(-50%, -50%);
  font-size: 0.2rem;
  color: #ffffff;
  line-height: 0.31rem;
  width: 2.13rem;
  left: 50%;
  top: 50%;
  text-align: center;
  font-weight: bold;
}
.recommand {
  width: 0.6rem;
  height: 0.25rem;
  color: #ffffff;
  font-size: 0.13rem;
  position: absolute;
  right: 0;
  top: 0;
  background: url("../assets/shizhan/mark.png") no-repeat center;
  background-size: 100% 100%;
  border-radius: 0rem 0.16rem 0 0;
}
.sprohead {
  width: 2.86rem;
  height: 1.42rem;
  position: relative;
}
.sprohead img {
  width: 2.86rem;
  height: 1.42rem;
  border-radius: 0.16rem 0.16rem 0 0;
}


.maincontent {
  width: 12rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
  flex-wrap: wrap;
  min-width: 8rem;
  margin-left: 0.3rem;
}
.leftcontent {
  width: 3.4rem;
  padding: 0.16rem 0.1rem;
  background: #ffffff;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  margin-top: 0.3rem;
  margin-bottom: 0.2rem;
  flex-wrap: wrap;
}
.lefttitle{
  width: 100%;
  justify-content: flex-start;
}
.rankimg {
  width: 0.27rem;
  margin-top: 0.03rem;
  margin-right: 0.1rem;
  margin-left: 0.05rem;
}
.ranktip {
  font-size: 0.24rem;
}
.ranktype {
  margin-left: 1.1rem;
  margin-top: 0.07rem;
  font-size: 0.19rem;
  font-weight: bold;
  color: #1A87EC;
}

.changeimg {
  width: 0.17rem;
  margin-top: 0.08rem;
  margin-left: 0.05rem;
}
.timeitems {
  width: 100%;
  font-size: 0.16rem;
  justify-content: center;
  margin-top: 0.2rem;
}
.timeitem {
  width: 50%;
  padding-bottom: 0.1rem;
  position: relative;
  font-weight: bold;
  font-size: 0.16rem;
  color: #878686;
  text-align: center;
}
.colorblack{
  color: #2c3e50;
}
.timeline {
  width: 0.2rem;
  height: 0.028rem;
  background: #1386f0;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -150%);
}
.topimg{
  width: 0.4rem;
  height: 0.4rem;
  position: absolute;
  top:-0.06rem;
  left: 0.7rem;
  transform: rotate(70deg);
  -ms-transform: rotate(90deg);
}
.rankitems {
  width: 100%;
  flex-wrap: wrap;
}
.rankitem {
  position:relative;
  height: 0.7rem;
  width: 3.4rem;
  margin-top: 0.07rem;
  justify-content:flex-start;
}
.rankitem:hover{
  border-radius: 0.16rem;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
}
.pphoto{
  width: 0.55rem;
  height: 0.55rem;
  border-radius: 50%;
}
.pinfo{
  margin-left: 0.1rem;
}
.levelimg {
  width: 0.25rem !important;
  margin-right: 0.06rem;
}
.pname{
  color: #2c3e50;
  font-size: 0.16rem;
  font-weight: 600;
}
.top1{
  width: 0.45rem;
  text-align: center;
  font-size: 0.7rem;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(#FED530, #FEAE30);
}
.top2{
  width: 0.45rem;
  text-align: center;
  font-size: 0.6rem;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(#E2E2E2, #939495);
}
.top3{
  width: 0.45rem;
  text-align: center;
  font-size: 0.5rem;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(#CF9F70, #B28150);
}
.topother{
  width: 0.45rem;
  text-align: center;
  font-size: 0.35rem;
  -webkit-background-clip: text;
  color: transparent;
  background-image: linear-gradient(#E8E8E8, #C6C6C6);
}
.options {
  width: 100%;
  padding: 0.16rem 0.16rem;
  background: #ffffff;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  margin-top: 0.1rem;
}
.label-con {
  width: 100%;
  padding: 0.2rem 0.16rem;
  background: #ffffff;
  margin-top: 0.3rem;
}
.oimg {
  width: 0.35rem;
  height: 0.35rem;
  margin-right: 0.15rem;
  border-radius: 50%;
}
.otip {
  font-size: 0.24rem;
}
.subtitle {
  padding: 0.03rem 0.05rem;
  font-size: 0.16rem;
  margin-right: 0.1rem;
  font-weight: bold;
}

.optionitem {
  margin-top: 0.15rem;
  margin-left: 0.5rem;
  display: flex;
}
.sitems {
  width: 100%;
  flex-wrap: wrap;
}
@media screen and (max-width: 1300px) {
  .maincontent {
    width: 8rem !important;
  }
  .sproitem {
    width: 2.5rem !important;
    margin-right: 0.16rem !important;
  }
  .sprohead {
    width: 2.5rem !important;
  }
  .sprohead img{
    width: 2.5rem !important;
  }
}
</style>
