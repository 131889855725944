<template>
  <div class="right">
    <div class="mtitle">我的课程</div>
    <div class="nodata" v-if="list.length == 0">暂无数据</div>
    <div class="sitem flexbetween" v-for="item in list" :key="'course'+item.courseId" @click="goDetail(item)">
      <div class="leftimg curpoint flexcenter">
        <img :src="item.coursePhoto">
        <div class="sprotitle">{{ item.courseName }}</div>
      </div>
      <div class="cinfo curpoint">
        <div class="aname">{{ item.courseTitle }}</div>
        <div class="stip flexs">
          <span class="stips" v-for="(sitem,sindex) in item.courseLabels" :key="'seee'+sindex">{{sitem}}</span>
        </div>
        <div class="bmnum flexs">
          <div style="width: 2rem">学习人数： {{ item.studyCount }}</div>
          <div style="width: 2rem">章节：{{ item.totalChapter }}</div>
        </div>
        <div class="flexs" style="margin-top: 0.1rem">
          <div class="sprice" :class="{colorgreen:item.isPay!=1,colorred:item.isPay==1}">{{item.isPay==1?'付费':'免费'}}</div>
          <div class="ybm flexcenter" >继续学习</div>
        </div>
      </div>
    </div>
    <div class="flexcenter" style="width: 100%;margin:0.35rem 0">
      <el-pagination
        background
        layout="prev, pager, next"
        @current-change="(e)=>{pageNum=e;getList()}"
        :total="total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import { getRequest } from "@/utils/common.js";

export default {
  name: "mycourse",
  data(){
    return {
      pageNum:1,
      pageSize:10,
      list:[],
      total:0,
    }
  },
  mounted() {
    this.getList()
  },
  methods:{
    goDetail(obj){
      let url = '/courseDetail?courseId='+obj.courseId
      if(obj.courseType == 1 || obj.courseType==2){
        url = '/combatDetail?courseId='+obj.courseId+'&type='+obj.courseType
      }
     this.$router.push(url)
    },
    async getList(){
      this.$scrollTop()
      let {data} = await getRequest(`abcode/user/queryUserCourse/${this.pageNum}/${this.pageSize}`,{})
      this.list=(data && data.items?data.items.records:[]).map(item=>{
        return {...item,coursePhoto:item.coursePhoto
            ? item.coursePhoto.replace("/opt/nginx/ymadmin/", this.imgurl)
            : "",
          courseLabels:item.courseLabel?item.courseLabel.split(','):[]
        }
      });
      this.total=data && data.total?data.total:0
    }
  }
};
</script>
<style scoped>
.nodata{
  font-size: 0.18rem;
  width: 100%;
  text-align: center;
  margin-top:0.6rem
}
.sprice{
  font-size: 0.16rem;
  font-weight: bold;
  margin-right: 0.15rem;
}
.stips{
  background: #F4F4F4;
  padding: 0.03rem 0.05rem;
  border-radius: 0.10rem;
  font-size: 0.12rem;
  margin-right: 0.1rem;
  color: #666;
  margin-top: 0.1rem;
}
.stip{
 width: 100%;
  border-radius: 0.04rem;
  /*margin-top: 0.1rem;*/
  flex-wrap: wrap;

}
.avatar-con img{
  width: 0.88rem;
  height: 0.88rem;
}
.cinfo{
  width: 6.5rem;
}
.aname{
  line-height: 0.23rem;
  font-size: 0.25rem;
  text-align: left;
}
.bmnum{
  color: #999;
  margin-top: 0.1rem;
  text-align: left;
  width:100%;
  color: #666;
  font-size: 0.12rem;
}

.ybm{
  width: 0.8rem;
  height:0.32rem;
  border-radius: 0.15rem;
  background-image: linear-gradient(#08ac62, #4bbc89e8);
  margin-left: 0.2rem;
  color: #fff;
  font-size: 0.13rem;
}


.right{
  width: 8.9rem;
  min-height: 4.56rem;
  background: #FFFFFF;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  padding: 0.2rem 0.2rem 0 0.2rem;
}
.mtitle{
  font-size: 0.16rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 0.1rem;
}
.sitem{
  width: 100%;
  padding:0.25rem 0;
  border-bottom: 0.01rem solid #dcdcdc;
}
.leftimg{
  width: 2.25rem;
  height: 1.58rem;
  position: relative;
  align-items: center;
}
.leftimg img{
  width: 2.25rem;
  height: 1.58rem;
  border-radius: 0.16rem;
}
.sprotitle {
  width: 2rem;
  font-size: 0.22rem;
  color: #ffffff;
  line-height: 0.31rem;
  text-align: center;
  font-weight: bold;
  position: absolute;
  left: 50%;
  top:50%;
  transform: translate(-50%,-50%);
}
</style>
