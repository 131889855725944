<template>
  <div class="container">
    <div class="headezw"></div>
    <div class="video-con flexbetween">
      <div class="left-con">
        <div class="input_video">
          <ali-player v-if="playauth"  :cover="curChapter.coverURL" @ready="getReady" :height="'6rem'"
                      :vid="curChapter.aliVideoId" :playauth="playauth" ref="players" @ended="playEnd"></ali-player>
        </div>
        <div class="video-bottom flexbetween">
          <div>
            <div class="zztitle">第{{curChapter.chapterNo}}集： {{ curChapter.chapterName }}</div>
            <div class="zztip flexs"  >
              <div  class="jianjie flexs curpoint"  @click="showInfo=!showInfo">
                <i
                  class="iconfont icon-kechengtixi"
                  style="font-size: 0.17rem; "
                ></i>
                <span style="margin:0 0.05rem">简介</span>
                <i
                  class="iconfont"
                  :class="{'icon-xiala':!showInfo,'icon-shangla':showInfo}"
                  style="font-size: 0.13rem; "
                ></i>
              </div>

              <span>讲师：{{ teacher.teacherName }}</span>
              <span style="margin-left:0.2rem">类别：{{courseInfo.isPay==1?'付费':'免费'}}</span>
              <span style="margin-left:0.2rem">学习人数：{{ courseInfo.studyCount }}</span></div>
          </div>
          <div class="flexs">
            <div class="hbtn flexcenter curpoint" @click="showComment=true;textarea=''">
              <i
                class="iconfont icon-pinglun1"
                style="font-size: 0.17rem; color: #fff;margin-right: 0.1rem"
              ></i>评论
            </div>
            <a class="hbtn flexcenter curpoint" style="color:#fff" href="curChapter.docUrl" v-if="curChapter.docUrl" download="course">
              <i
                class="iconfont icon-xiazai1"
                style="font-size: 0.17rem; color: #fff;margin-right: 0.1rem"
              ></i>课件下载
            </a>
            <div class="hbtn flexcenter curpoint" v-if="false">
              <i
                class="iconfont icon-jia"
                style="font-size: 0.17rem; color: #fff;margin-right: 0.1rem"
              ></i>加入学习
            </div>
          </div>
        </div>
      </div>
      <div class="right-con" >
        <div class="mulu" @click="goDetail"> {{ courseInfo.courseName }}</div>
        <div class="scroll-con">
          <div class="mulu-item" v-for="(item,nindex) in chapterList" :key="'menu'+item.chapterId"
               @click="menuClick(item,nindex)">
            <div class="flexs cdetail" >
              <img src="../assets/playon-green.gif" v-if="item.chapterId==curChapter.chapterId" style="width: 0.14rem">
              <i v-else
                  class="iconfont icon-bofangqi-bofangxiaodianshi"
                  style="font-size: 0.14rem; "
              ></i>
              <div :class="{colorgreen:item.chapterId==curChapter.chapterId}" style="margin-left: 0.1rem">第{{item.chapterNo}}集 ：{{item.chapterName}}  </div>
            </div>

          </div>
        </div>

      </div>
      <div class="courseinfo" v-if="showInfo">
        {{curChapter.plotDesc}}
      </div>
    </div>
    <div class="maincontent">

      <div class="snavs flexcenter">
        <div class="snav-item curpoint" @click="curTab=item.id" :class="{colorblue:item.id == curTab}"
             v-for="item in tabs" :key="'tab'+item.id" :style="{marginRight:item.id==3?'0':'0.55rem'}">
          {{ item.label }}
          <div class="navline" v-if="item.id == curTab"></div>
        </div>
      </div>
      <div v-if="curTab == 1">
        <div class="center1">
          <div class="center1-title">课程简介</div>
                   <div class="center1-tip">
                     <div v-html="courseInfo.courseDesc"></div>
                   </div>
        </div>
      </div>
      <div v-else-if="curTab == 2" style="margin-top:0.2rem">
        <div class="center2 flexs" v-if="courseInfo.stageCount">
          <div v-for="item in ((courseInfo.stageCount * 2) -1)" :key="'stage'+item" class="flexs">
            <div class="flexcenter"
                 :class="{'center2-item':((item+1)/2) != stageNum,'center2-itemed':((item+1)/2) == stageNum}"
                 v-if="item%2==1" @click="stageNum=((item+1)/2);getChapter();getTeacher()">阶段{{(item+1)/2}}</div>
            <img src="../assets/detail/pic_jiantou.png" v-else class="arrowimg" />
          </div>

        </div>

        <div class="center4 " v-if="chapterList.length>0">

          <div class="chapter-tem flexbetween" style="margin-top: 0.25rem;align-items: self-start" v-for="(item,nindex) in chapterList"
               :style="{
              backgroundSize:
                item.videoTime && item.videoTime>0?(((item.progressNum || 0) / item.videoTime) * 100) + '% 100%':'0% 100%',
            }"
               :key="'chapters'+item.chapterId">
            <i
                class="iconfont icon-bofangqi-bofangxiaodianshi"
                style="font-size: 0.19rem; color: #1386F0;margin-top:0.04rem"
            ></i>
            <div>
              <div class="item-center flexs">
                <span class="chapter-title colorblue">第{{item.chapterNo}}集： {{item.chapterName}}</span>
                <span
                    class="chapter-title colorblue curpoint"
                    style="text-decoration: underline"
                    @click="curChapterId==item.chapterId?curChapterId='':curChapterId=item.chapterId"
                >{{curChapterId==item.chapterId?'隐藏':'显示'}}剧情介绍</span
                >
                <div class="slearn flexcenter" :style="{background:(item.progressNum || 0) > 0?'#01aa60':'#1386f0'}"
                     @click="goStudy(item,nindex)">
                  {{ (item.progressNum || 0) > 0 ? "继续学习" : "开始学习" }}
                </div>
              </div>
              <div  class="center4-tip animate__animated" v-if="curChapterId==item.chapterId"
                    :class="{shrinkAll:item.chapterDesc && curChapterId!=item.chapterId,expandAll:item.chapterDesc && curChapterId==item.chapterId}"
              >
                <div v-html="item.plotDesc"></div>
              </div>
            </div>
            <div class="flexs" style="width: 2.5rem">
              <div class="chap-time">{{ timeFormatter(item.progressNum || 0) }}/{{ timeFormatter(item.videoTime || 0)}}</div>
              <div class="learnstatus colorblue curpoint" v-if="!item.progressNum">未学习</div>
              <div class="learnstatus colorgreen" v-else-if="(timeFormatter(item.progressNum || 0) != timeFormatter(item.videoTime || 0)) && item.progressNum>0">未学完</div>
              <div class="learnstatus colorange" v-else>已学完</div>
            </div>
          </div>


        </div>
      </div>
      <div v-else>
        <div class="input-con">
          <div class="flexs">
            <div class="intitle">评论</div>
            <i class="iconfont  icon-shoucang" :style="{color:item<=star?'#FE5148':'#E6E6E6'}"
               style="font-size: 0.16rem;cursor: pointer" @click="star=item" v-for="item in 5" :key="'start'+item"></i>

          </div>
          <el-input
            type="textarea"
            :rows="4"  class="textarea"
            placeholder="请输入评价..."
            v-model="textarea">
          </el-input>
          <div class="fb-con">
            <div class="fbbtn flexcenter curpoint" @click="goComment">发表</div>
          </div>

        </div>
        <div class="sitems">
          <div class="sitem" v-for="item in list" :key="'comment'+item.courseCommentId">
            <img v-if="item.photo" :src="item.photo" />
            <img v-else src="../assets/favicons.png" />
            <div class="sitem-right">
              <div class="flexbetween">
                <div class="flexs">
                  <div class="user">{{ item.userNick }}</div>
                  <i
                    class="iconfont icon-shoucang"
                    :style="{
                      color: sitem <= item.starCount ? '#FE5148' : '#E6E6E6',
                    }"
                    style="font-size: 0.16rem; cursor: pointer"
                    v-for="sitem in 5"
                    :key="'starts' + sitem"
                  ></i>
                </div>
                <div class="sitem-time">{{ item.createTime }}</div>
              </div>
              <div class="sitem-tip">{{ item.comment }}</div>
              <div style="width: 100%;margin-top:0.15rem" v-if="item.courseCommentReplyList.length>0">
                <div
                    class="sitem" style="padding: 0.1rem 0;border-bottom: none"
                    v-for="sitem in item.courseCommentReplyList"
                    :key="'replay' + sitem.commentReplyId"
                >
                  <img style="width: 0.5rem;height: 0.5rem" src="../assets/favicons.png" />
                  <div class="sitem-right" style="width:9.8rem">
                    <div class="flexbetween">
                      <div class="flexs">
                        <div class="user">{{ sitem.userName }}</div>
                      </div>
                      <div class="sitem-time">{{ sitem.replyTime }}</div>
                    </div>
                    <div class="sitem-tip" style="margin-top:0.1rem" v-html="sitem.replyContent"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flexcenter" style="width: 100%;margin:0.35rem 0">
          <el-pagination
              background
              layout="prev, pager, next"
              :current-page.sync="pageNum"
              @current-change="getList"
              :total="total">
          </el-pagination>
        </div>
      </div>

    </div>

    <qrcodec v-if="showCode"  :isUseCoupon="courseInfo.isUseCoupon" :courseId="courseId" @close="showCode = false;getDetail();"></qrcodec>
    <el-dialog
      :append-to-body="true"
      title="评论"
      :visible.sync="showComment"
      width="30%"
      center>
      <el-input
        type="textarea"
        :rows="4"
        placeholder="请输入评论内容"
        v-model="textarea">
      </el-input>
      <span slot="footer" class="dialog-footer">
    <el-button @click="showComment = false">取 消</el-button>
    <el-button type="primary" @click="goComment">发 表</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import { postRequest,getRequest,postFormRequest } from "@/utils/common.js";
import qrcodec from "@/components/qrcodeC/index";
import { getToken } from "@/utils/auth";
import VueAliplayer from "@/components/VueAliplayer.vue";


export default {
  name: "videoLearning",
  data(){
    return {
      playauth:'',
      showInfo:false,
      tabs:[
        {id:1,label:'课程简介'},
        {id:2,label:'课程大纲'},
        {id:3,label:'课程评论'}
      ],
      canNext:false,
      curIndex:'',
      curChapterId:'',
      chapterId:'',
      curTab:'',
      pageSize: 10,
      pageNum: 1,
      total:3,
      textarea:'',
      star:5,
      courseId:'',
      list:[],
      courseInfo:{},
      teacher:{},
      stageNum:'',
      showComment:false,
      chapterList:[],
      showCode:false,
      code_url:'',
      timers:null,
      curChapter:{},
      type:''
    }
  },
  components:{
    qrcodec,
    'ali-player':VueAliplayer},
  mounted() {
    this.courseId=this.$route.query.courseId || '';
    this.stageNum=this.$route.query.stageNum || '';
    this.chapterId=this.$route.query.chapterId || '';
    this.type=this.$route.query.type || '';
    this.$scrollTop()
    this.getDetail()
    this.createRecord()
    this.getChapter(1)
    this.getTeacher()
  },
  watch:{
    curTab(val){
      if(val == 3){
        this.pageNum=1
        this.getList()
      }else if(val == 2){
        this.getChapter()
        this.getTeacher()
      }
    },


  },
  async beforeDestroy(){
    if(this.$refs.players){
      let getCurrentTime=this.$refs.players.getCurrentTime()
      let {data} = await postFormRequest(`abcode/course/saveCourseChapterProgress/${this.courseId}/${this.curChapter.chapterId}/${getCurrentTime}`,{
        courseId:this.courseId,
        chapterId:this.chapterId,
        progressNum:getCurrentTime
      })
      console.log(data)
    }
  },
  methods:{
    goDetail(){
      let turl = this.type==1?'/combatDetail?type=1&courseId='+this.courseId:'/courseDetail?courseId='+this.courseId
      this.$router.push(turl)
    },
    menuClick(item,nindex){
      this.setProgress()
      this.curIndex=nindex;
      this.canNext=false;
      this.courseInfo.studyCount=this.courseInfo.studyCount+1;
      this.curChapter=item;
      this.getPlayAuth()
    },
    goStudy(item,nindex){
      if(!getToken()){
        this.$store.commit('setShowLogin',true)
        this.$message.warning('开始之前请先进行登录操作')
        return
      }
      this.setProgress()
      this.chapterId=item.chapterId
      this.curIndex=nindex;
      this.canNext=false;
      this.courseInfo.studyCount=this.courseInfo.studyCount+1;
      this.curChapter=item;
      this.getPlayAuth()
    },
    async createRecord(){
      let {data} = await postRequest('abcode/course/unneed/addStudyCourseNum/'+this.courseId,{})
      console.log(data)
    },
    getReady(e){
      e.play()
      let {progressNum,videoTime} = this.curChapter
      let startProgress = progressNum || 0
      if(this.timeFormatter(progressNum || 0) == this.timeFormatter(videoTime || 0)){
        startProgress=0
      }
      e.seek(startProgress)
    },
    showAllTime(stime){
      let tmptime = Math.ceil(stime || 0)
      let tminute = Math.ceil(tmptime/60);
      let surtime = Math.ceil(tmptime-(tminute*60)>0?tmptime-(tminute*60):0).toFixed(0)
      return `${tminute>9?tminute:'0'+tminute}:${surtime>9?surtime:'0'+surtime}`
    },
    async goPay(){
      if(this.courseInfo.isPay==1){
        let {code} = await getRequest('abcode/course/queryUserIsPayCourseAndPass/'+this.courseId,{})
        if(code != 20000){
          this.showCode = true;
        }
      }


    },

    async getChapter(flag){
      let {data} = await getRequest(`abcode/course/unneed/queryCourseChapterList/${this.courseId}/${this.stageNum}`,{})
      this.chapterList=data && data.items?data.items:[];
      if(!flag)return
      this.chapterList.forEach((item,index)=>{
        if(item.chapterId==this.chapterId){
          this.curChapter=item
          this.curIndex =index
        }
      })

      this.getPlayAuth()
      // setTimeout(()=>{
      //   this.getPlayAuth('1')
      // },200)
      this.curChapterId=this.chapterList.length>0?this.chapterList[0].chapterId:''
    },
    async getPlayAuth(flag){
      this.playauth=''
      if(!this.curChapter.aliVideoId)return
      let { code,message,data } = await getRequest(`abcode/course/getPlayAuth/${this.courseId}/${this.curChapter.aliVideoId}`,{})
      if(!flag && code != 20000){
        this.$message.error(message || '未购买')
      }
      this.curChapter.coverURL=data && data.coverURL?data.coverURL:'';
      this.playauth=data && data.playAuth?data.playAuth:''
    },
    async playEnd(){
      let ttime = this.curChapter.videoTime
      let {data} = await postFormRequest(`abcode/course/saveCourseChapterProgress/${this.courseId}/${this.curChapter.chapterId}/${ttime}`,{
        courseId:this.courseId,
        chapterId:this.chapterId,
        progressNum:ttime
      })
      console.log(data)
      this.canNext=true
      if(this.chapterList[this.curIndex +1]){
        setTimeout(()=>{
          if(this.canNext){
            this.curIndex=this.curIndex+1
            this.curChapter=this.chapterList[this.curIndex]
            this.courseInfo.studyCount=this.courseInfo.studyCount+1;
            // this.setProgress()
            this.getPlayAuth()
          }
        },5000)

      }
    },
    async setProgress(){
      if(this.$refs.players){
        let getCurrentTime=parseInt(this.$refs.players.getCurrentTime() || 0)
        this.$refs.players.dispose()
        let {data} = await postFormRequest(`abcode/course/saveCourseChapterProgress/${this.courseId}/${this.curChapter.chapterId}/${getCurrentTime}`,{
          courseId:this.courseId,
          chapterId:this.chapterId,
          progressNum:getCurrentTime
        })
        console.log(data)
      }
    },
    //获取讲师信息
    async getTeacher(){
      if(!this.courseInfo.firstTeacherId)return
      let {data} = await getRequest(`abcode/course/unneed/queryCourseTeacherList/${this.courseId}/${this.stageNum}/${this.courseInfo.firstTeacherId}`,{})
      let tmpobj=data && data.items?data.items:{}
      tmpobj.teacherPhoto=tmpobj.teacherPhoto? tmpobj.teacherPhoto.replace("/opt/nginx/ymadmin/", this.imgurl) : ""
      this.teacher=tmpobj
    },
    //获取详情
    async getDetail(){
      let {data} = await getRequest(`abcode/unneed/course/queryCourseInfo/${this.courseId}`,{})

      let info = data && data.items?data.items:{};
      this.courseInfo=info;
      this.curTab=1
      this.courseInfo.coursePhoto=info.coursePhoto?info.coursePhoto.replace('/opt/nginx/ymadmin/',this.imgurl):'';
      this.getTeacher()


    },
    async getList(){
      let {data} = await getRequest(`abcode/course/unneed/queryCourseCommentList/${this.courseId}/${this.pageNum}/${this.pageSize}`,{})
      this.list=(data && data.items?data.items.records:[]).map(item=>{
        return {...item,photo:item.photo?item.photo.replace('/opt/nginx/ymadmin/',this.imgurl):''}
      })
      this.total = data && data.items?data.items.total:0

    },
    async goComment(){
      if(!this.textarea){
        this.$message.warning('请输入课程评论内容')
        return
      }
      this.loading=true;
      let {code,message} = await postRequest('abcode/course/commentCourse',{
        comment:this.textarea,
        courseId:this.courseId,
        starCount: this.star,
      })
      if(code == 20000){
        this.$message.success(message || '评论成功')
        this.pageNum=1
        this.textarea=''
        this.showComment=false
        this.getList()
      }
      if(code == 20003 || code == 20002){
        this.$store.commit('setShowLogin',true)
      }
    }
  }
};
</script>

<style scoped>
.input-con{
  width: 12rem;
  margin: auto;
  margin-top:0.5rem;
}
.courseinfo{
  font-size: 0.16rem;
  color: #FFFFFF;
  line-height: 0.25rem;
  text-indent:0.25rem;
  font-weight: bold;
  margin-top:0.2rem
}
.jianjie{
  color: #fff;margin-right: 0.2rem
}
.jianjie:hover{
  color:#01aa60
}
.input_video{
  width: 14.63rem;
  margin: 0 auto;
  padding-top: 0.3rem;
  height: 6rem;
}

.video-con{
  width: 18.6rem;
  background: #4D4D4D;
  padding: 0.3rem;
  flex-wrap: wrap;
}

.right-con{
  width:3.5rem;
  color: #FFFFFF;
}
.scroll-con{
  height: 6.5rem;
  overflow-y: scroll;
}
.mulu{
  font-size: 0.18rem;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 0.15rem;
}
.mulu:hover{
  color: #01aa60
}
.mulu-item{
  margin-top:0.16rem;
  padding-bottom: 0.21rem;
  border-bottom: 0.01rem solid #DCDCDC;
  cursor: pointer;
}

.cdetail{
  margin-left: 0.3rem;
  margin-top:0.09rem;
  color: #FFFFFF;
}
.cdetail:hover{
  color:  #01aa60;
}
.left-con{
  width: 14.63rem;
}
.zztitle{
  font-size: 0.29rem;
  color: #FFFFFF;
  font-weight: bold;
}
.zztip{
  margin-top: 0.18rem;
  margin-right: 0.4rem;
}
.hbtn{
  width: 1.2rem;
  height: 0.38rem;
  background: rgba(255,255,255,0.2);
  border-radius: 0.19rem;
  font-size: 0.16rem;
  margin-left: 0.16rem;
}
.video-bottom{
  color: #FFFFFF;
  padding-top: 0.3rem;
}
.textarea{
  width: 100%;
  height: 0.92rem;
  margin-top: 0.1rem;
  font-size: 0.16rem;
}
.container{
  width: 19.2rem;
  padding-bottom: 0.9rem;
}
@media screen and (max-width: 1500px) {
  .container {
    width: 100% !important;
  }
  .video-con{
    width: calc(100% - 0.6rem) !important;
  }
  .left-con{
    width: 80% !important;
  }
  .input_video{
    width: 100% !important;
  }
  .right-con{
    width: 18% !important;
  }
  .sitems{
    width:calc(100% - 1.2rem)!important;
    margin: 0.3rem !important;
  }
}
.chap-time{
  color: #010101;
  font-size: 0.14rem;
}
.colorgreen{
  color: #1BCD27;
}
.colorange{
  color: #FE971D;
}
.learnstatus{
  font-size: 0.14rem;
  margin-left: 0.2rem;
  width: 0.6rem;
}
.slearn{
  width: 0.8rem;
  height: 0.28rem;
  background: #1386F0;
  border-radius: 0.14rem;
  color: #FFFFFF;
  font-size: 0.14rem;
  cursor: pointer;
}
.item-center{
  width: 8rem;
}
.chapter-title{
  font-size: 0.14rem;
  margin-right: 0.3rem;
}
.chapter-tem{
  width: calc(100% - 0.36rem);
  padding: 0.1rem 0.18rem;
  background: url("../assets/detail/bluebac.png") no-repeat left;
  background-size: 50% 100%;
  border-radius: 0.04rem;
}

.center4-tip{
  font-size: 0.14rem;
  color: #808080;
  margin-top: 0.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 8rem;
}
.center4{
  width: 11.5rem;
  background: #FFFFFF;
  box-shadow: 0px 0.02rem 0.13rem 0px rgba(0, 0, 0, 0.14);
  border-radius: 0.16rem;
  margin:auto;
  margin-top: 0.24rem;
  padding:  0.2rem 0.25rem;
}

.arrowimg{
  width: 0.22rem;
  margin-right: 0.33rem;
  height: 0.2rem;
}
.center2-item{
  margin-right: 0.33rem;
  width: 1.26rem;
  height: 0.59rem;
  background: #FFB534;
  border-radius: 0.1rem;
  background: url("../assets/detail/pic_wxzh_jieduan.png") no-repeat center;
  background-size: 100% 100%;
  color: #5B5B5B;
  font-size: 0.24rem;
  cursor: pointer;
}
.center2-itemed{
  margin-right: 0.33rem;
  width: 1.26rem;
  height: 0.59rem;
  background: #FFB534;
  border-radius: 0.1rem;
  background: url("../assets/detail/pic_xuanzhong_jieduan.png") no-repeat center;
  background-size: 100% 100%;
  color: #FFFFFF;
  font-size: 0.24rem;
}
.center2{
  width: 12rem;
  padding: 0.3rem 0 0.2rem 0;
  margin: auto;
  background: #FBFBFB;
}
.center1-tip{
  margin-top: 0.25rem;
  line-height: 0.19rem;
  font-size: 0.14rem;
  color: #808080;
  text-indent:0.2rem;
}
.center1-title{
  font-size: 0.24rem;
}
.center1{
  width: 11.28rem;
  background: #FBFBFB;
  border-radius: 0.1rem 0.16rem 0.1rem 0.1rem;
  margin: auto;
  margin-top: 0.3rem;
  padding: 0.3rem 0.38rem;
}
.snavs{
  width: 100%;
  margin-top: 0.35rem;
}
.snav-item{
  color: #4D4D4D;
  font-size: 0.2rem;
  position: relative;
  padding-bottom: 0.08rem;
}
.navline{
  width: 0.36rem;
  height: 0.03rem;
  background: #1386F0;
  position: absolute;
  left:50%;
  bottom: 0;
  transform: translate(-50%,-50%);
  border-radius: 0.02rem;
}

.maincontent{
  width: 100%;
  padding-top: 0.2rem;
}

.fbbtn{
  width: 1rem;
  height: 0.37rem;
  background: #1386F0;
  border-radius: 0.18rem;
  color: #FFFFFF;
  font-size: 0.18rem;

}
.fb-con{
  margin-top:0.4rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.intitle{
  color: #060606;
  font-size: 0.18rem;
  margin-right: 0.1rem;
}
.sitems{
  width: 11.4rem;
  padding: 0 0.3rem;
  background: #FFFFFF;
  box-shadow: 0px 0.03rem 0.13rem 0px rgba(0, 0, 0, 0.17);
  border-radius: 0.16rem;
  margin:0.6rem 3.575rem 0.3rem 3.575rem
}
.sitem{
  padding: 0.2rem 0;
  width: 100%;
  border-bottom: 0.01rem solid #E5E5E5;
  display: flex;
  justify-content: space-between;
}
.sitem-time{
  font-size: 0.16rem;
  color: #666;
}
.user{
  font-size: 0.16rem;
  font-weight: bold;
  margin-right: 0.2rem;
}
.sitem-tip{
  font-size: 0.16rem;
  color: #666;
  line-height: 0.26rem;
  margin-top: 0.23rem;
}
.sitem-right{
  width: 10.6rem;
}
.sitem img{
  width: 0.65rem;
  height: 0.65rem;
  border-radius: 50%;
}
</style>
