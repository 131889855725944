<template>
  <div style="width: 100%">
    <div class="headezw"></div>
    <div class="topbanner">
      <img style="width: 100%" src="../assets/pic_huodong.png">
    </div>
    <div class="maincontent flexbetween">

      <div class="navitems">
        <div class="nav-item flexs">
          <div class="nav-item curpoint" :class="{colorblue:curIndex==item.index}" v-for="item in navs" :key="'nav_'+item.index" @click="curIndex=item.index">
            {{ item.name }}
          <div class="sline" v-if="curIndex==item.index"></div>
          </div>
        </div>
      </div>
      <div class="sitems flexs" style="margin-top:0.2rem">
        <div class="sproitem curpoint" v-for="(item,index) in list" :key="'activity'+item.activityId" :style="{marginRight:index%3==2?'0':'0.225rem'}">

          <div class="sprohead" @click="$router.push('/activityDetail?activityId='+item.activityId)">
            <img :src="item.activityCover" />
            <div class="recommand flexcenter">{{item.activityType==1?'&nbsp;线下活动':'&nbsp;线上活动'}}</div>
          </div>
          <div class="sprocontent">

            <div class="ctitle" slot="reference" @click="$router.push('/activityDetail?activityId='+item.activityId)">
              <img src="../assets/hot.gif"  style="width: 0.35rem" v-if="judgeStatus(item)">
              {{ item.activityTitle }}
            </div>
            <div class="flexbetween" style="margin-top: 0.1rem" @click="$router.push('/activityDetail?activityId='+item.activityId)">
              <div class="leftprice flexcenter">{{ item.startTime }} 至 {{ item.endTime }}</div>

            </div>
            <div class="flexbetween" style="margin-top: 0.15rem" @click="$router.push('/activityDetail?activityId='+item.activityId)">
              <div class="slabel">{{ item.joinCount }}人报名  共{{item.activityCount}}个名额</div>
              <div class="rightbtn flexcenter curpoint" style="border:none">{{judgeStatus(item)?'活动中':'已结束'}}</div>
              <div class="rightbtns flexcenter curpoint" v-if="judgeStatus(item)" @click.stop="submit(item)">立即报名</div>
            </div>
          </div>
        </div>
      </div>
      <div class="flexcenter" style="width: 100%;margin:0.35rem 0">
        <el-pagination
          background
          layout="prev, pager, next"
          :page-size="pageSize"
          :current-page.sync="pageNum"
          @current-change="getList"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>

</template>

<script>
import { getRequest } from "@/utils/common.js";
import { getToken } from "@/utils/auth";


export default {
  name: "activitys",
  data(){
    return {
      pageSize:9,
      pageNum:1,
      courseType:1,
      list:[],
      total:0,
      curIndex:0,
      navs:[
        {name:'最新活动',index:2},
        // {name:'购买优惠',index:2},
        {name:'线上活动',index:0},
        {name:'线下活动',index:1}
      ]
    }
  },
  components:{},
  watch:{
    curIndex(){
      this.getList()
    }
  },
  mounted() {
    this.curIndex=2

  },
  methods:{
    judgeStatus(obj){
      return (new Date(obj.endTime).getTime()-new Date().getTime() > 0) && (obj.joinCount < obj.activityCount)
    },
    showStatus(ndate){
      return new Date(ndate).getTime()-new Date().getTime()>0
    },
    async submit(obj){
      if(!getToken()){
        this.$store.commit('setShowLogin',true)
        return
      }
      let {data} = await getRequest(`abcode/unneed/activity/getActivityInfo/${obj.activityId}`,{})
      let joinId=data && data.items?data.items.joinId:''
      if(!joinId){
        let { code,message }= await getRequest(`abcode/unneed/activity/activityJoin/${obj.activityId}`,{})
       if(code == 20000){
         this.$message.success(message || '报名成功')
       }
      }else{
        this.$message.info('您已经报名了')
        this.$router.push('/activityDetail?activityId='+obj.activityId)
      }

    },
    async getList(){
      this.$scrollTop()
      let {data} = await getRequest(`abcode/unneed/activity/queryActivityList/${this.pageNum}/${this.pageSize}/${this.curIndex}`,{})
      this.list=(data && data.rows?data.rows:[]).map(item=>{
        return {...item,activityCover:item.activityCover?item.activityCover.replace('/opt/nginx/ymadmin/',this.imgurl):''}
      });
      this.total=data.total || 0
    }
  }
};
</script>

<style scoped>
.topbanner{
position: relative;
}
.navitems{
  width: 100%;
  font-size: 0.16rem;
}
.nav-item{
  padding-bottom: 0.1rem;
  position: relative;
  font-weight: bold;
  margin-right: 0.3rem;
  font-size: 0.16rem;
}
.sline{
  width: 0.24rem;
  height: 0.03rem;
  background: #1386F0;
  position: absolute;
  bottom: 0;
  left:50%;
  transform: translate(-50%,-50%);
}

.tcon img{
  width: 2.84rem;
  height: 0.46rem;

}
.sproitem{
  width: 3.85rem;
  height: 3.15rem;
  background: #FFFFFF;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  margin-bottom: 0.15rem;
}
.sproitem:hover{
   box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.3);
 }
.rightbtn{
  width: 0.84rem;
  height: 0.27rem;
  background: #FFFFFF;
  border: 0.01rem solid #1386F0;
  border-radius: 0.13rem;
  color: #1386F0;
  font-size: 0.16rem;
}
.rightbtns{
  width: 0.84rem;
  height: 0.31rem;
  background-image: linear-gradient(#1386F0, #21A9EF);
  /*border: 0.01rem solid #1386F0;*/
  border-radius: 0.13rem;
  color: #FFFFFF;
  font-size: 0.16rem;
}
.leftprice{
  width: 100%;
  height: 0.25rem;
  background: linear-gradient(270deg, #F64944 0%, #F68845 100%);
  border-radius: 0.06rem;
  color: #FFFFFF;
  font-size: 0.14rem;
}
.ctitle{
  font-size: 0.18rem;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 0.5rem;
}

.slabel{
  color: #999;
  font-size: 0.14rem;
  padding-right: 0.15rem;
  margin-right: 0.15rem;
}
.sprocontent{
  width: calc(100% - 0.26rem);
  height: 1.41rem;
  padding: 0.13rem;
}

.recommand{
  width: 0.85rem;
  height: 0.25rem;
  color: #FFFFFF;
  font-size: 0.13rem;
  position: absolute;
  right: 0;
  top:0rem;
  background: url("../assets/shizhan/mark.png") no-repeat center;
  background-size: 100% 100%;
  border-radius: 0rem 0.16rem 0 0;
}
.sprohead{
  width: 3.85rem;
  height: 1.58rem;
  position: relative;
}
.sprohead img{
  width: 3.85rem;
  height: 1.58rem;
  border-radius: 0.16rem 0.16rem 0 0;
}

.maincontent{
  width: 12rem;
  margin:auto;
  margin-top:0.26rem;
  margin-bottom:0.2rem;
  flex-wrap: wrap;
}

.sitems{
  width: 100%;
  flex-wrap: wrap;

}

</style>
