<template>
  <div class="container" v-loading="loading">
    <div class="headezw"></div>
    <div class="maincontent">
      <div class="cdetail flexbetween">
        <div class="con-left" :style="{background:'url('+courseInfo.coursePhoto+') no-repeat center'}">
<!--          <img :src="courseInfo.coursePhoto" />-->
          <div class="con-left-title">{{ courseInfo.courseName }}</div>
        </div>
        <div class="con-right">
          <div class="con-right-title">{{ courseInfo.courseTitle }}</div>
          <div class="flexs">
            <span
              class="rlabel"
              v-for="(item, sindex) in courseInfo.courseLabels"
              :key="'slabel' + sindex"
              >{{ item }}
            </span>
          </div>
          <div class="flexs">
            <span class="sfenge"
              >预计工时{{ courseInfo.courseTotalTime }}</span
            >
            <span class="sfenge" :style="{borderRight:courseInfo.isInterview == 1?'':'none'}">累计{{ courseInfo.studyCount }}人实战</span>
            <span
              class="sfenge colorblue"
              style="border: none"
              v-if="courseInfo.isInterview == 1"
              >需要面试</span
            >
          </div>
          <div class="flexs" style="margin-top: 0.38rem">
            <div class="price-con flexs" v-if="courseInfo.isPay==1">
              <span style="font-size: 0.18rem; padding-bottom: 0.03rem"
                >￥</span>
              <span style="font-size: 0.3rem">{{
                courseInfo.discountPrice
              }}</span>
              <span style="margin-left:0.1rem;font-size: 0.2rem;color: #999;text-decoration: line-through">￥{{courseInfo.coursePrice}}</span>
            </div>
            <div class="price-con flexs colorgreen" v-else>
              <span style="font-size: 0.18rem; padding-bottom: 0.03rem"
              >免费</span>
            </div>
            <div class="ljsz flexcenter" @click="joinin" v-if="type == 1 ">立即{{judgeStatus(item) ? "报名" : "实战" }}</div>
            <div class="ljsz flexcenter" @click="joinin" v-else>立即提升</div>
            <div class="qms flexcenter curpoint" @click="goInterView" v-if="courseInfo.isInterview == 1">
              去面试
            </div>
            <span v-if="type == 1 && judgeStatus()" class="sftip" style="border: none">开课时间：{{ courseInfo.onLineTime }}</span>
          </div>
        </div>
      </div>
      <div class="snavs flexcenter">
        <div
            class="snav-item curpoint"
            :class="{ colorblue: curTab == 1 }"
            style="margin-right: 0.55rem"
            @click="curTab = 1"
        >
          课程简介
          <div class="navline" v-if="curTab == 1"></div>
        </div>
        <div
          class="snav-item curpoint"
          :class="{ colorblue: curTab == 2 }"
          style="margin-right: 0.55rem"
          @click="curTab = 2"
        >
          课程大纲
          <div class="navline" v-if="curTab == 2"></div>
        </div>
        <div
          class="snav-item curpoint"
          :class="{ colorblue: curTab == 3 }"
          @click="curTab = 3"
        >
          课程评论
          <div class="navline" v-if="curTab == 3"></div>
        </div>
      </div>
      <div v-if="curTab == 1">
        <div class="center1">
          <div class="center1-title">课程简介</div>
          <div class="center1-tip">
            <div v-html="courseInfo.courseDesc"></div>
          </div>
        </div>
      </div>
      <div v-else-if="curTab == 2" style="margin-top:0.2rem">

        <div class="center2 flexs" v-if="courseInfo.stageCount">
          <div
              v-for="item in courseInfo.stageCount * 2 - 1"
              :key="'stage' + item"
              class="flexs"
          >
            <div
                class="flexcenter"
                :class="{
                'center2-item': (item + 1) / 2 != stageNum,
                'center2-itemed': (item + 1) / 2 == stageNum,
              }"
                v-if="item % 2 == 1"
                @click="stageNum = (item + 1) / 2"
            >
              阶段{{ (item + 1) / 2 }}
            </div>
            <img
                src="../assets/detail/pic_jiantou.png"
                v-else
                class="arrowimg"
            />
          </div>
        </div>

        <div class="center4" v-if="chapterList.length > 0">
          <div
              class="chapter-tem flexbetween"
              style="margin-top: 0.25rem;align-items: self-start"
              :style="{
              backgroundSize:
                item.videoTime && item.videoTime>0?(((item.progressNum || 0) / item.videoTime) * 100) + '% 100%':'0% 100%',
            }"
              v-for="item in chapterList"
              :key="'chapter' + item.chapterId"
          >
            <i
                class="iconfont icon-bofangqi-bofangxiaodianshi"
                style="font-size: 0.19rem; color: #1386f0;margin-top:0.04rem"
            ></i>
            <div>
              <div class="item-center flexs">
                <span class="chapter-title"
                >第{{ item.chapterNo }}集： {{ item.chapterName }}</span
                >
                <span
                    class="chapter-title colorblue curpoint"
                    style="text-decoration: underline"
                    @click="curChapter==item.chapterId?curChapter='':curChapter=item.chapterId"
                >{{curChapter==item.chapterId?'隐藏':'显示'}}剧情介绍</span
                >
                <div class="slearn flexcenter" :style="{background:(item.progressNum || 0) > 0?'#01aa60':'#1386f0'}"
                    @click="goStudy(item)">
                  {{ (item.progressNum || 0) > 0 ? "继续学习" : "开始学习" }}
                </div>
              </div>
              <div  class="center4-tip animate__animated" v-if="curChapter==item.chapterId"
                    :class="{shrinkAll:item.chapterDesc && curChapter!=item.chapterId,expandAll:item.chapterDesc && curChapter==item.chapterId}">
                <div v-html="item.plotDesc"></div>
              </div>

            </div>

            <div class="flexs" style="width: 2.5rem">
              <div class="chap-time">
                {{ timeFormatter(item.progressNum || 0) }}/{{ timeFormatter(item.videoTime || 0) }}
              </div>
              <div class="learnstatus colorblue curpoint" v-if="!item.progressNum">未学习</div>
              <div class="learnstatus colorgreen" v-else-if="(timeFormatter(item.progressNum || 0) != timeFormatter(item.videoTime || 0)) && item.progressNum>0">未学完</div>
              <div class="learnstatus colorange" v-else>已学完</div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="input-con">
          <div class="flexs">
            <div class="intitle">评论</div>
            <i
              class="iconfont icon-shoucang"
              :style="{ color: item <= star ? '#FE5148' : '#E6E6E6' }"
              style="font-size: 0.16rem; cursor: pointer"
              @click="star = item"
              v-for="item in 5"
              :key="'start' + item"
            ></i>
          </div>
          <el-input
            type="textarea"
            :rows="4"
            class="textarea"
            placeholder="请输入评价..."
            v-model="textarea"
          >
          </el-input>
          <div class="fb-con">
            <div class="fbbtn flexcenter curpoint" @click="goComment">发表</div>
          </div>
        </div>
        <div class="sitems">
          <div
            class="sitem"
            v-for="item in list"
            :key="'comment' + item.courseCommentId"
          >
            <img v-if="item.photo" :src="item.photo" />
            <img v-else src="../assets/favicons.png" />
            <div class="sitem-right">
              <div class="flexbetween">
                <div class="flexs">
                  <div class="user">{{ item.userNick }}</div>
                  <i class="iconfont  icon-shoucang" :style="{color:sitem<=item.starCount?'#FE5148':'#E6E6E6'}"
                     style="font-size: 0.16rem;cursor: pointer" @click="item.starCount=sitem" v-for="sitem in 5" :key="'starts'+sitem"></i>
                </div>
                <div class="sitem-time">{{ item.createTime }}</div>
              </div>
              <div class="sitem-tip">{{ item.comment }}</div>
              <div style="width: 100%;margin-top:0.15rem" v-if="item.courseCommentReplyList.length>0">
                <div
                    class="sitem" style="padding: 0.1rem 0;border-bottom: none"
                    v-for="sitem in item.courseCommentReplyList"
                    :key="'replay' + sitem.commentReplyId"
                >
                  <img style="width: 0.5rem;height: 0.5rem" src="../assets/favicons.png" />
                  <div class="sitem-right" style="width:9.8rem">
                    <div class="flexbetween">
                      <div class="flexs">
                        <div class="user">{{ sitem.userName }}</div>
                      </div>
                      <div class="sitem-time">{{ sitem.replyTime }}</div>
                    </div>
                    <div class="sitem-tip" style="margin-top:0.1rem" v-html="sitem.replyContent"></div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div class="flexcenter" style="width: 100%;margin:0.35rem 0">
          <el-pagination
              background
              layout="prev, pager, next"
              :current-page.sync="pageNum"
              @current-change="getList"
              :total="total">
          </el-pagination>
        </div>
      </div>
    </div>

    <qrcodec
      v-if="showCode"
      :courseId="courseId"
      :isUseCoupon="courseInfo.isUseCoupon"
      @close="
        showCode = false;
        getDetail();
      "
    ></qrcodec>
    <el-dialog
        title="扫描二维码咨询详情"
        :visible.sync="dialogVisible"
        width="30%"
        :append-to-body="true"
        center>
      <div class="sewm"></div>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>
import qrcodec from "@/components/qrcodeC/index";
import { getRequest, postRequest } from "@/utils/common.js";

import { getToken } from "@/utils/auth";

export default {
  name: "combatDetail",
  data() {
    return {
      dialogVisible:false,
      loading: false,
      courseInfo: {},
      stageNum: "",
      star:5,
      chapterList: [],
      curTab: 1,
      pageSize: 10,
      pageNum: 1,
      total: 3,
      courseId: "",
      textarea: "",
      list: [],
      code_url: "",
      orderNo: "",
      showCode: false,
      curChapter:'8',
      type:0
    };
  },
  mounted() {
    this.courseId = this.$route.query.courseId || "";
    this.getDetail();
    this.type=this.$route.query.type
  },
  components: { qrcodec },
  watch: {
    stageNum() {
      this.getChapter();
    },
    curTab(val) {
      if (val == 3) {
        this.pageNUm = 1;
        this.getList();
      } else if(val == 2){
        this.getChapter();
      }
    },
  },
  methods: {
    judgeStatus(){
      return new Date(this.courseInfo.onLineTime).getTime()-new Date().getTime() > 0
    },
    goInterView(){
      if(this.courseInfo.examId){
        this.$router.push('/answer?type=1&sid='+this.courseInfo.examId)
      }else{
        this.dialogVisible=true
      }
    },
    async goStudy(item){
      if(!getToken()){
        this.$store.commit('setShowLogin',true)
        this.$message.warning('开始之前请先进行登录操作')
        return
      }
      if (this.courseInfo.isPay == 1) {
        let { code } = await getRequest(
            "abcode/course/queryUserIsPayCourseAndPass/" + this.courseId,
            {}
        );
        if (code != 20000) {
          this.showCode = true;
        }else{
          this.$router.push(
              '/videoLearning?type=1&courseId=' + this.courseId + '&chapterId=' + item.chapterId + '&stageNum=' + this.stageNum
          )
        }
      }else{
        this.$router.push(
            '/videoLearning?type=1&courseId=' + this.courseId + '&chapterId=' + item.chapterId + '&stageNum=' + this.stageNum
        )
      }

    },
    // timeFormatter(stime){
    //   let tmptime = Math.ceil(stime || 0)
    //   let tminute = Math.ceil(tmptime/60);
    //   let surtime = Math.ceil(tmptime-(tminute*60)>0?tmptime-(tminute*60):0).toFixed(0)
    //   return `${tminute>9?tminute:'0'+tminute}:${surtime>9?surtime:'0'+surtime}`
    // },
    async joinin() {
      if(!getToken()){
        this.$store.commit('setShowLogin',true)
        this.$message.warning('开始之前请先进行登录操作')
        return
      }

      if (this.courseInfo.isPay == 1) {
        let { code } = await getRequest(
          "abcode/course/queryUserIsPayCourseAndPass/" + this.courseId,
          {}
        );
        if (code != 20000) {
          this.showCode = true;
        }else{
          if(this.type == 1 & this.judgeStatus()){
            this.$message.info('请加入课程简介中的会员群，关注开课消息~')
            this.curTab=1
          }else{
            this.$message.info('请在课程大纲中选择章节进行学习')
            this.curTab=2
          }
        }
      }else{
        if(this.type == 1 & this.judgeStatus()){
          this.$message.info('请加入课程简介中的会员群，关注开课消息~')
          this.curTab=1
        }else{
          this.$message.info('请在课程大纲中选择章节进行学习')
          this.curTab=2
        }
      }
    },

    async goComment() {
      if(!this.textarea){
        this.$message.warning('请输入课程评论内容')
        return
      }
      this.loading = true;
      let { code, message } = await postRequest("abcode/course/commentCourse", {
        comment: this.textarea,
        courseId: this.courseId,
        starCount:this.star
      });
      this.loading = false;
      if (code == 20000) {
        this.$message.success(message || "评论成功");
        this.pageNUm = 1;
        this.textarea=''
        this.star=5
        this.getList();
      }
      if(code == 20003 || code == 20002){
        this.$store.commit('setShowLogin',true)
      }
    },
    async getChapter() {
      let { data } = await getRequest(
        `abcode/course/unneed/queryCourseChapterList/${this.courseId}/${this.stageNum}`,
        {}
      );
      this.chapterList = data && data.items ? data.items : [];
      this.curChapter=this.chapterList.length>0?this.chapterList[0].chapterId:''
    },
    async getList() {
      let { data } = await getRequest(
        `abcode/course/unneed/queryCourseCommentList/${this.courseId}/${this.pageNum}/${this.pageSize}`,
        {}
      );
      this.list = (data && data.items ? data.items.records : []).map(item=>{
        return {...item,photo:item.photo?item.photo.replace('/opt/nginx/ymadmin/',this.imgurl):''}
      });
      this.total =data && data.items ?data.items.total : 0;
    },
    //获取详情
    async getDetail() {
      this.loading = true;
      this.$scrollTop();
      let { data } = await getRequest(
        `abcode/unneed/course/queryCourseInfo/${this.courseId}`,
        {}
      );
      let info = data && data.items ? data.items : {};
      this.courseInfo = info;
      this.curTab = 1;
      this.courseInfo.coursePhoto = info.coursePhoto
        ? info.coursePhoto.replace("/opt/nginx/ymadmin/", this.imgurl)
        : "";
      this.courseInfo.courseLabels = info.courseLabel
        ? info.courseLabel.split(",")
        : [];
      this.stageNum = 1;
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.input-con {
  width: 12rem;
  margin: auto;
  margin-top: 0.5rem;
}
.textarea {
  width: 100%;
  height: 0.92rem;
  margin-top: 0.1rem;
  font-size: 0.16rem;
}
.con-left {
  width: 2.6rem;
  height: 1.97rem;
  background: linear-gradient(0deg, #139ff0 0%, #59afff 100%);
  border-radius: 0.16rem;
  position: relative;
}
.ljsz {
  margin-left: 0.2rem;
  width: 0.96rem;
  height: 0.31rem;
  background-image: linear-gradient(#1386F0, #21A9EF);
  border-radius: 0.14rem;
  font-size: 0.16rem;
  color: #ffffff;
  cursor: pointer;
}
@media screen and (max-width: 1500px) {

  .sitems{
    width:calc(100% - 1.2rem)!important;
    margin: 0.3rem !important;
  }
}
.qms {
  width: 0.8rem;
  height: 0.29rem;
  background: #ffffff;
  border: 0.01rem solid #1386f0;
  border-radius: 0.14rem;
  font-size: 0.16rem;
  color: #1386f0;
  margin-left: 0.1rem;
}
.price-con {
  color: #ee312c;
  align-items: flex-end;
  font-weight: bold;
}
.sfenge {
  color: #999;
  font-size: 0.14rem;
  padding-right: 0.2rem;
  border-right: 0.01rem solid #999;
  margin-right: 0.2rem;
  margin-top: 0.2rem;
}
.sftip {
  color: #999;
  font-size: 0.12rem;
  margin-left: 0.2rem;
}
.rlabel {
  padding: 0.03rem 0.05rem;
  border-radius: 0.03rem;
  background: #f5f5f5;
  color: #666;
  font-size: 0.14rem;
  margin-top: 0.18rem;
  margin-right: 0.1rem;
}
.con-right {
  width: 8.6rem;
}
.con-right-title {
  font-size: 0.24rem;
  font-weight: bold;
}
.con-left-title {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 2.2rem;
  font-size: 0.21rem;
  line-height: 0.27rem;
  color: #ffffff;
  font-weight: bold;
}
.con-left img {
  width: 2.6rem;
  height: 1.97rem;
}
.container {
  width: 100%;
  padding-bottom: 0.9rem;
}
.chap-time {
  color: #010101;
  font-size: 0.14rem;
}
.colorgreen {
  color: #1bcd27;
}
.colorange {
  color: #fe971d;
}
.learnstatus {
  font-size: 0.14rem;
  margin-left: 0.2rem;
  width: 0.6rem;
}
.slearn {
  width: 0.8rem;
  height: 0.28rem;
  background: #1386f0;
  border-radius: 0.14rem;
  color: #ffffff;
  font-size: 0.14rem;
  cursor: pointer;
}
.item-center {
  width: 8rem;
}
.chapter-title {
  font-size: 0.14rem;
  margin-right: 0.3rem;
}
.chapter-tem {
  width: calc(100% - 0.36rem);
  padding: 0.1rem 0.18rem;
  background: url("../assets/detail/bluebac.png") no-repeat left;
  background-size: 50% 100%;
  border-radius: 0.04rem;
}

.center4-tip {
  font-size: 0.14rem;
  color: #808080;
  margin-top: 0.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 8rem;
}
.center4 {
  width: 11.5rem;
  background: #ffffff;
  box-shadow: 0px 0.02rem 0.13rem 0px rgba(0, 0, 0, 0.14);
  border-radius: 0.16rem;
  margin: auto;
  margin-top: 0.24rem;
  padding: 0.2rem 0.25rem;
}

.arrowimg {
  width: 0.22rem;
  height: 0.2rem;
  margin-right: 0.33rem;
}
.center2-item {
  width: 1.26rem;
  margin-right: 0.33rem;
  height: 0.59rem;
  background: #ffb534;
  border-radius: 0.1rem;
  background: url("../assets/detail/pic_wxzh_jieduan.png") no-repeat center;
  background-size: 100% 100%;
  color: #5b5b5b;
  font-size: 0.24rem;
  cursor: pointer;
}
.center2-itemed {
  margin-right: 0.33rem;
  width: 1.26rem;
  height: 0.59rem;
  background: #ffb534;
  border-radius: 0.1rem;
  background: url("../assets/detail/pic_xuanzhong_jieduan.png") no-repeat center;
  background-size: 100% 100%;
  color: #ffffff;
  font-size: 0.24rem;
}
.center2 {
  width: 12rem;
  padding: 0.3rem 0 0.2rem 0;
  margin: auto;
  background: #fbfbfb;
}
.center1-tip {
  margin-top: 0.25rem;
  line-height: 0.19rem;
  font-size: 0.14rem;
  color: #808080;
  text-indent: 0.2rem;
}
.center1-title {
  font-size: 0.24rem;
}
.center1 {
  width: 11.28rem;
  background: #fbfbfb;
  border-radius: 0.1rem 0.16rem 0.1rem 0.1rem;
  margin: auto;
  margin-top: 0.3rem;
  padding: 0.3rem 0.38rem;
}
.snavs {
  width: 100%;
  margin-top: 0.35rem;
}
.snav-item {
  color: #4d4d4d;
  font-size: 0.2rem;
  position: relative;
  padding-bottom: 0.08rem;
}
.navline {
  width: 0.36rem;
  height: 0.03rem;
  background: #1386f0;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -50%);
  border-radius: 0.02rem;
}

.maincontent {
  width: 100%;
  padding-top: 0.5rem;
}
.cdetail {
  width: 11.6rem;
  height: 1.98rem;
  background: #ffffff;
  box-shadow: 0px 0.06rem 0.13rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.16rem;
  margin: auto;
  padding: 0.2rem;
}

.fbbtn {
  width: 1rem;
  height: 0.37rem;
  background: #1386f0;
  border-radius: 0.18rem;
  color: #ffffff;
  font-size: 0.18rem;
}
.fb-con {
  margin-top: 0.4rem;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.intitle {
  color: #060606;
  font-size: 0.18rem;
  margin-right: 0.1rem;
}
.sitems {
  width: 11.4rem;
  padding: 0 0.3rem;
  background: #ffffff;
  box-shadow: 0px 0.03rem 0.13rem 0px rgba(0, 0, 0, 0.17);
  border-radius: 0.16rem;
  margin: 0.6rem 3.575rem 0.3rem 3.575rem;
}
.sitem {
  padding: 0.2rem 0;
  width: 100%;
  border-bottom: 0.01rem solid #e5e5e5;
  display: flex;
  justify-content: space-between;
}
.sitem-time {
  font-size: 0.16rem;
  color: #666;
}
.user {
  font-size: 0.16rem;
  font-weight: bold;
  margin-right: 0.2rem;
}
.sitem-tip {
  font-size: 0.16rem;
  color: #666;
  line-height: 0.26rem;
  margin-top: 0.23rem;
}
.sitem-right {
  width: 10.6rem;
}
.sitem img {
  width: 0.65rem;
  height: 0.65rem;
  border-radius: 50%;
}
</style>
